import React, { useEffect } from 'react';
import '../../App.css';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Waypoint from 'waypoints/lib/noframework.waypoints';
import SmoothScroll from 'smooth-scroll';
import WOW from 'wowjs';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import { gsap } from 'gsap';
import { Link } from 'react-router-dom';

const AcceptableUsePolicy = () => {
  useEffect(() => {
    // Initialize any necessary jQuery or other plugins here
  }, []);

  return (
    <>
      <section className="th-blog-wrapper blog-details space-top space-extra2-bottom" style={{ backgroundColor: "black", marginTop: "100px" }}>
        <div className="container">
          <div className="row gx-40">
            <div className="col-xxl-12 col-lg-7">
              <div className="th-blog blog-single">
                <div className="blog-content">
                  <h2 className="blog-title">Acceptable Use Policy</h2>
                  <p>This acceptable use policy covers the products, services, and technologies (collectively referred to as the “Products”) provided by AgonGames under any ongoing agreement. This policy aims to protect AgonGames, our customers, and the broader Internet community from unethical, irresponsible, and illegal activities.</p>
                  <p>Customers of AgonGames who engage in activities prohibited by this acceptable use policy may be subject to service suspension, account termination, and, in severe cases, may be reported to the relevant authorities as legally required.</p>
                  <p>This policy is effective as of 01/12/2024.</p>

                  <h3>Fair Use</h3>
                  <p>We provide our services with the expectation that usage aligns with “business as usual” based on our offer schedule. If usage is deemed excessive, additional fees may apply, or capacity may be restricted.</p>
                  <p>AgonGames opposes all forms of abuse, discrimination, rights infringement, or any action that harms or disadvantages any group, individual, or resource. We expect our customers and, where applicable, their users (“end-users”) to similarly use our Products responsibly.</p>

                  <h3>Customer Accountability</h3>
                  <p>Our customers are responsible for their own actions and for those of anyone using our Products with the customer’s permission. This responsibility also extends to anyone using our Products without authorization if the customer has not implemented reasonable security measures.</p>
                  <p>By using our Products, customers agree to enforce this policy among all end-users. Any complaints about customer or end-user behavior will be forwarded to the nominated contact for the relevant account.</p>
                  <p>If a customer, their end-user, or anyone using our Products through the customer violates this acceptable use policy, we reserve the right to suspend or terminate any Product associated with the offending account, take appropriate remedial or preventative action, and, to the extent permitted by law, no credits will be available for service interruptions resulting from policy violations.</p>

                  <h3>Prohibited Activity</h3>
                  <h4>Copyright Infringement and Unauthorized Material</h4>
                  <p>Our Products must not be used to transmit, distribute, or store material that violates any applicable laws, including but not limited to:</p>
                  <ul>
                    <li>Material protected by copyright, trademark, trade secret, or other intellectual property rights without proper authorization; and</li>
                    <li>Material that is obscene, defamatory, poses illegal threats, or violates export control laws.</li>
                  </ul>

                  <h4>SPAM and Unauthorized Messaging Activity</h4>
                  <p>Our Products may not be used for sending unsolicited bulk or commercial messages that violate applicable laws (“spam”). This includes but is not limited to:</p>
                  <ul>
                    <li>Sending spam or soliciting customers from spam sent through other service providers.</li>
                    <li>Collecting responses to spam from other service providers.</li>
                  </ul>
                  <p>Our Products may not be used to run unconfirmed mailing lists or messaging lists. All mailing lists hosted by or using our Products must be “confirmed opt-in” to ensure verification of the address or telephone number owner’s permission.</p>
                  <p>The use of purchased email lists, phone number lists, or databases intended for spam or unconfirmed messaging lists on our Products is prohibited.</p>

                  <h4>Unethical, Exploitative, and Malicious Activity</h4>
                  <p>Our Products must not be used to advertise, transmit, or make available software, programs, or services that violate this acceptable use policy, including but not limited to facilitating spam, network sniffing, packet spoofing, flooding, mail-bombing, and denial-of-service attacks.</p>
                  <p>Our Products must not be used for accessing unauthorized accounts or electronic resources (“hacking”, “cracking”, etc.) or for introducing malicious code into AgonGames Products and systems.</p>
                  <p>Harassment, including denial-of-service attacks, hate speech, advocacy of racial or ethnic intolerance, or any activity that threatens or abuses another individual or group, is strictly prohibited.</p>
                  <p>Other prohibited activities include but are not limited to:</p>
                  <ul>
                    <li>Obtaining services from AgonGames with the intent to avoid payment;</li>
                    <li>Using AgonGames’ services to access other providers’ services with the intent to avoid payment;</li>
                    <li>Unauthorized access, alteration, or destruction of information about AgonGames customers or end-users;</li>
                    <li>Interfering with the use of AgonGames services and network by other customers;</li>
                    <li>Publishing or transmitting content or links that incite violence, depict violent acts, depict child exploitation, or threaten anyone’s health and safety;</li>
                    <li>Engaging in any act or omission in violation of consumer protection laws; and</li>
                    <li>Violating the privacy rights of others.</li>
                  </ul>
                  <p>Our Products may not be used by any person or entity involved in or suspected of involvement in activities related to illegal gambling, terrorism, narcotics trafficking, arms trafficking, or the proliferation, development, or use of weapons of mass destruction.</p>

                  <h3>Unauthorized Use of AgonGames Property</h3>
                  <p>Impersonating AgonGames, falsely representing a significant business relationship with AgonGames, or claiming ownership of any AgonGames property (including our Products and brand) for fraudulent gain of service, custom, patronage, or user trust is strictly prohibited.</p>

                  <h3>About This Policy</h3>
                  <p>This policy provides a non-exclusive list of activities and intentions considered unacceptable and incompatible with the AgonGames brand.</p>
                  <p>AgonGames reserves the right to amend this policy at any time by posting the revised version on our website. The revised version will become effective from the earlier of:</p>
                  <ul>
                    <li>The date the customer uses our Products after the revised policy is published; or</li>
                    <li>30 days after the publication of the revised policy on our website.</li>
                  </ul>

                  <h3>Contact Us</h3>
                  <p>For any questions regarding this acceptable use policy, please contact us at: <a href="mailto:contact@agongames.com">contact@agongames.com</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AcceptableUsePolicy;
