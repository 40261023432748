import React , { useEffect, useState, useContext } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../Utils/Contexts/authContext';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Waypoint from 'waypoints/lib/noframework.waypoints';
import SmoothScroll from 'smooth-scroll';
import WOW from 'wowjs';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import { gsap } from 'gsap'; 
import HeroBg1 from '../../Assets/img/hero/hero-bg1-1.png';
import HeroTitleBgShape1 from '../../Assets/img/hero/hero-title-bg-shape1.svg'

const HeroSection = () => {

    const accessToken = localStorage.getItem('accessToken');
    const playNowLink = accessToken
      ? '/progames'
      : '/games';

    useEffect(() => {
        // Initialize jQuery smooth scrolling for anchor links
        $('.scroll-to').on('click', function(event) {
          event.preventDefault(); // Prevent default anchor behavior
          const target = $(this.getAttribute('href')); // Get the target element
          if (target.length) {
            $('html, body').stop().animate({
              scrollTop: target.offset().top // Scroll to the target section
            }, 1000); // Duration in milliseconds
          }
        });
      }, []);

    useEffect(() => {
        // Apply background images from data-bg-src attribute
        if ($("[data-bg-src]").length > 0) {
          $("[data-bg-src]").each(function () {
            var src = $(this).attr("data-bg-src");
            $(this).css("background-image", "url(" + src + ")");
            $(this).removeAttr("data-bg-src").addClass("background-image");
          });
        }
    
        // Apply background colors from data-bg-color attribute
        if ($('[data-bg-color]').length > 0) {
          $('[data-bg-color]').each(function () {
            var color = $(this).attr('data-bg-color');
            $(this).css('background-color', color);
            $(this).removeAttr('data-bg-color');
          });
        }
    
        // Apply border color from data-border attribute
        $('[data-border]').each(function() {
          var borderColor = $(this).data('border');
          $(this).css('--th-border-color', borderColor);
        });
    
        // Apply mask images from data-mask-src attribute
        if ($('[data-mask-src]').length > 0) {
          $('[data-mask-src]').each(function () {
            var mask = $(this).attr('data-mask-src');
            $(this).css({
              'mask-image': 'url(' + mask + ')',
              '-webkit-mask-image': 'url(' + mask + ')'
            });
            $(this).addClass('bg-mask');
            $(this).removeAttr('data-mask-src');
          });
        };
    
      }, []); // Run once when the component mounts

    

    return (
      <>
        <div className="th-hero-wrapper hero-1" id="hero" data-bg-src={HeroBg1} style={{marginBottom:"0", marginTop:"80px"}}>
          <div className="container">
              <div className="hero-style1 text-center">
                  <span className="sub-title custom-anim-top wow animated" data-wow-duration="1.2s" data-wow-delay="0.1s"># Ultimate Tournaments   Hub</span>
                  <h1 className="hero-title">
                      <span className="title1 custom-anim-top wow animated" data-wow-duration="1.1s" data-wow-delay="0.3s" data-bg-src={HeroTitleBgShape1}>COMPETE, CONNECT </span>
                      <span className="title2 custom-anim-top wow animated" data-wow-duration="1.1s" data-wow-delay="0.4s">EARN REWARDS </span> 
                  </h1>
                  <div className="btn-group custom-anim-top wow animated" data-wow-duration="1.2s" data-wow-delay="0.7s">
                    {/* <a href='#about-sec' className="th-btn scroll-to">EXPLORE MORE <i className="fa-solid fa-arrow-right ms-2"></i></a> */}
                    <Link to={playNowLink} className="th-btn style2">PLAY NOW <i className="fa-solid fa-arrow-right ms-2"></i></Link>
                  </div>
              </div>
          </div>
        </div>
      </>
    )
  }
  
  export default HeroSection
