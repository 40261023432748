import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../Utils/Contexts/authContext';
import { useNavigate } from 'react-router-dom';
import '../../App.css';
import useScreenSize from '../../Utils/Contexts/useScreenSize';
import 'jquery-ui/ui/widgets/slider'; // Example widget
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Cursor from '../../Components/Common/Cursor';
import Preloader from '../../Components/Common/Preloader';
import SideMenu2 from '../../Components/Common/SideMenu2';
import MainHeader1 from '../../Components/Common/MainHeader1';
import StatsTimer from '../../Components/LandingPageComp/statsTimer';
// import ColorSwitcher from '../../Components/Common/ColorSwitcher';
import HeroSection from '../../Components/LandingPageComp/heroSection';
import GameArea from '../../Components/LandingPageComp/gameArea1';
import HowItWorks from '../../Components/LandingPageComp/howItWorks';
import AboutUs from '../../Components/LandingPageComp/aboutUs';
import JoinUs from '../../Components/LandingPageComp/joinUs';
import GamersShop from '../../Components/LandingPageComp/gamersShop';
import Footer1 from '../../Components/Common/Footer1';
import ScrollToTop from '../../Components/Common/ScrollToTop';
import TournamentsList1 from '../../Components/LandingPageComp/tournamentsList1';
import TournamentPopUp from '../../Components/LandingPageComp/tournamentPopUp';
import ProTournamentsList2 from '../../Components/ProtectedComponents/proTournamentsList2';
import HowItWorksMobile from '../../Components/MobileViewComp/howItWorksMobile';
import TournamentListMobile from '../../Components/MobileViewComp/tournamentListMobile';
import BottomNavbar from '../../Components/Common/BottomNavbar';
import SimpleHeader2 from '../../Components/Common/SimpleHeader2';

const LandingPage = () => {
    const { accessToken, logout } = useContext(AuthContext);
    const navigate = useNavigate(); // To navigate to the login page if not logged in
    const isMobile = useScreenSize();

    // useEffect(() => {
    //     setTimeout(() => {
    //         window.scrollTo(0, 0);
    //     }, 100);
    // }, []);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        if (accessToken) {
            // If user is authenticated, redirect to home
            navigate('/home');
        }
        // If no token, stay on the landing page (no redirection needed)
    }, [accessToken, navigate]); // Add navigate as a dependency for useEffect

    return (
        <div>
            {/* {accessToken ? ( */}
            <>
                <Cursor />
                <Preloader />
                {/* <ColorSwitcher/> */}
                <ScrollToTop />
                <MainHeader1 toggleMenu={toggleMenu} />
                {/* <SimpleHeader2 toggleMenu={toggleMenu}/> */}
                <SideMenu2 isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
                {/* <SideMenu2 isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} /> */}
                <HeroSection />
                <div>
                    <TournamentPopUp />
                </div>
                <StatsTimer />
                <GameArea />
                {/* <div id="how-it-works">{isMobile ? <HowItWorksMobile /> : <HowItWorks />}</div> */}
                <HowItWorks />
                <div id="tournament">
                    {isMobile ? (
                        <TournamentListMobile
                            selectedGameId={''}
                            gameHandles={{}}
                            landing={true}
                            pageType={'public'}
                            isScrollAvailable={false}
                        />
                    ) : (
                        <ProTournamentsList2
                            selectedGameId={''}
                            gameHandles={{}}
                            landing={true}
                            pageType={'public'}
                            isScrollAvailable={false}
                        />
                    )}
                </div>
                <AboutUs />
                <JoinUs />
                <GamersShop />
                {/* <BottomNavbar/> */}
                <Footer1 />
            </>
            {/* ) : (
    <p>You are not logged in. Redirecting to login page...</p>
    )} */}
        </div>
    );
};

export default LandingPage;
