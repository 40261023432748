import React, { useState, useEffect, useContext } from 'react';
import '../../App.css';
import $ from 'jquery'; // Import jQuery
import 'jquery-ui/ui/widgets/slider'; // Example widget
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Cursor from '../../Components/Common/Cursor';
import Preloader from '../../Components/Common/Preloader';
import SideMenu2 from '../../Components/Common/SideMenu2';
import ColorSwitcher from '../../Components/Common/ColorSwitcher';
import Footer2 from '../../Components/Common/Footer2';
import ScrollToTop from '../../Components/Common/ScrollToTop';
import BlogDetailsArea from '../../Components/BlogDetailsPage/blogDetails_Api.js';
import SimpleHeader3 from '../../Components/Common/SimpleHeader3';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Utils/Contexts/authContext';
import MainHeader1 from '../../Components/Common/MainHeader1';
import BottomNavbar from '../../Components/Common/BottomNavbar';
import { useParams } from "react-router-dom"; // Hook to get questId from URL

const BlogDetailsPage = () => {
  const { accessToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const { blogId } = useParams(); // Retrieve questId from URL parameters

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // useEffect(() => {
  //   if (accessToken) {
  //     // If token exists, navigate to /progames
  //     navigate('/progames');
  //   }
  //   // If no token, stay on /gamespage (no need to do anything)
  // }, [accessToken, navigate]);

  return (
    <>
      <Cursor />
      <Preloader />

      {/* Conditional rendering for headers */}
      {!accessToken ? (
        <MainHeader1 toggleMenu={toggleMenu} />
      ) : (
        <SimpleHeader3 />
      )}

      <SideMenu2 isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />

      <BlogDetailsArea blogId={blogId} />

      {/* Conditional rendering for footers */}
      {!accessToken ? (
        <Footer2 />
      ) : (
        <BottomNavbar />
      )}

      <ScrollToTop />
    </>
  );
};

export default BlogDetailsPage;
