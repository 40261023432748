import React, { useEffect, useState, useContext, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Authenticate, initOTPless, verifyOTP } from '../../Utils/Otpless/initOtpless';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Utils/Contexts/authContext';
import classNames from 'classnames';
import './LoginForm.css';
import '../../CustomCss/customStyles.css';
import '../../App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { BASE_URL } from '../../Utils/ApiServices/apiFunctions';

const LoginForm = () => {
    const { login } = useContext(AuthContext);
    const { accessToken } = useContext(AuthContext);

    const navigate = useNavigate();

    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState(['', '', '', '']); // Initialize OTP as an array of four empty strings
    const [otpToken, setOtpToken] = useState('');
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [activeSection, setActiveSection] = useState('PHONE');
    const [username, setUsername] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [storedUsername, setStoredUsername] = useState('');
    const usernameRef = useRef('');
    const [otpMessage, setOtpMessage] = useState('');
    const [isVerifying, setIsVerifying] = useState(false);
    const [refCode, setRefCode] = useState('');
    const [userData, setUserData] = useState({ referral_code: '' });
    const [view, setView] = useState('login'); // 'login' or 'verify'
    const [errorMessage, setErrorMessage] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState(false); // Success message state
    const [isNotificationVisible, setIsNotificationVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Add loading state
    const [isSectionDisabled, setIsSectionDisabled] = useState(false);
    const [timer, setTimer] = useState(30); // Set initial countdown to 30 seconds
    const [isTimerActive, setIsTimerActive] = useState(true); // Controls timer status
    const [canResendOTP, setCanResendOTP] = useState(false); // Controls RESEND OTP button
    const [showReferralCodeInput, setShowReferralCodeInput] = useState(false); // State for checkbox
    const inputRefs = useRef([]);
    const firstOtpInputRef = useRef(null); // Ref for the first OTP input
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        let countdown;
        if (isTimerActive) {
            countdown = setInterval(() => {
                setTimer((prev) => {
                    if (prev === 1) {
                        clearInterval(countdown);
                        setIsTimerActive(false); // Stop timer
                        setCanResendOTP(true); // Enable "Resend OTP" button
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        return () => clearInterval(countdown); // Cleanup timer on unmount
    }, [isTimerActive]);

    useEffect(() => {
        initOTPless((otplessUser) => handleUserData(otplessUser, refCode));
    }, [refCode]); // Dependency on refCode to ensure it’s up-to-date

    useEffect(() => {
        if (view === 'verify' && firstOtpInputRef.current) {
            firstOtpInputRef.current.focus();
        }
    }, [view]);

    useEffect(() => {
        const existingCodes = localStorage.getItem('referralCode');
        if (existingCodes) {
            setShowReferralCodeInput(true);
            setRefCode(existingCodes);
            console.log('Applied existing referral codes:', existingCodes);
        }
    }, []); // Run once on component mount

    const handleCheckboxChange = () => {
        console.log('checkbox clicked');
        setShowReferralCodeInput(!showReferralCodeInput);

        if (showReferralCodeInput) {
            setErrorMessage('');
            setRefCode(''); // Clear refCode when unchecking
        } else {
            // When checking, restore any existing referral code
            const existingCodes = localStorage.getItem('referralCode');
            if (existingCodes) {
                setRefCode(existingCodes);
            }
        }
    };

    const isEmail = (value) => value.includes('@');
    const isPhoneNumber = (value) => /^[0-9]{10,15}$/.test(value);

    const handleProceed = () => {
        console.log('Input value:', inputValue);
        console.log('Error message:', errorMessage);
        console.log('HandleProceed called');

        // Validate that inputValue is not empty
        if (!inputValue.trim()) {
            console.log('Setting error message: Please enter your email or phone number.');
            console.log('Error Message : ', errorMessage);
            setErrorMessage('Please enter your email or phone number.');
            return;
        }

        // If the referral code checkbox is checked, validate that the referral code is not empty
        if (showReferralCodeInput && !refCode.trim()) {
            console.log('Setting error message: Please enter a referral code or uncheck the box.');
            setErrorMessage('Please enter a referral code or uncheck the box.');
            return;
        }

    // Reset any existing error message
    setErrorMessage("");
    

    if (isEmail(inputValue)) {
      handleAuthenticate({ channel: 'EMAIL', email: inputValue });
    } else if (isPhoneNumber(inputValue)) {
      handleAuthenticate({ channel: 'PHONE', phone: inputValue });
    } else {
      console.error('Invalid email or phone number format');
      // setErrorMessage("Please enter your email or phone number.");
    }
  };

    const handleUserData = async (otplessUser, refCodeParam) => {
        console.log('Received OTPless user object:', otplessUser);

        const token = otplessUser.token;
        const emailMap = otplessUser.identities.find((item) => item.identityType === 'EMAIL');
        const mobileMap = otplessUser.identities.find((item) => item.identityType === 'MOBILE');
        const oauthMap = otplessUser.identities.find((item) => item.channel === 'OAUTH');
        const email = emailMap?.identityValue;
        const mobile = mobileMap?.identityValue;
        const name = emailMap?.name || mobileMap?.name || oauthMap?.name;

        setAdditionalInfo({ email, mobile, name });

        // Retry mechanism to ensure usernameRef is populated
        let attempts = 0;
        while (!usernameRef.current && attempts < 5) {
            await new Promise((resolve) => setTimeout(resolve, 100));
            attempts++;
        }

        const currentUsername = oauthMap ? oauthMap.identityValue : usernameRef.current;
        if (!currentUsername) {
            console.error('Username is empty. Cannot proceed with login.');
            setIsLoading(false);
            return;
        }

        let payload = {
            username: currentUsername,
            password: token,
            login_type: 'OtpLess',
            referral_code: refCodeParam || '',
            additional_info: JSON.stringify({ email, mobile, name }),
        };

        console.log('Payload for login : ', payload);
        console.log('This is the referral code : ', refCode);

        try {
            const response = await fetch(`${BASE_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(payload),
            });

            const result = await response.json();
            if (result?.access_token) {
                login(result.access_token, result.refresh_token);
                // if(localStorage.getItem("new_user")===undefined){
                localStorage.setItem('new_user', result.new_user);
                // }
                setShowSuccessMessage(true); // Show success message
                navigate('/progames', { state: { successMessage: 'LOGIN SUCCESSFUL' } });

                // setTimeout(() => {
                //   navigate('/home', { state: { successMessage: "LOGIN SUCCESSFUL" } });
                // }, 7000); // Adjust delay if needed
                setIsVerifying(false);
            } else {
                console.error('Login failed:', result);
            }
        } catch (error) {
            console.error('Error during login:', error);
        } finally {
            setIsVerifying(false);
        }
    };

    const handleAuthenticate = async (data) => {
        usernameRef.current = inputValue; // Immediately set usernameRef
        setStoredUsername(inputValue); // Ensure state is updated

        console.log('handling Authentication', data);
        try {
            const res = await Authenticate(data);
            console.log(res);
            if (res.success) {
                setIsSectionDisabled(true); // Use state to disable the section
                setOtpMessage('OTP SENT');

                setIsNotificationVisible(true); // Show notification
                setTimeout(() => setIsNotificationVisible(false), 3000);

                setView('verify'); // Switch to verify view after OTP is sent

                // Reset timer and disable RESEND OTP button
                setTimer(30);
                setIsTimerActive(true);
                setCanResendOTP(false);
            } else {
                console.log('Failed to Authenticate');
                throw new Error('Failed to authenticate'); // Handle failure
            }
        } catch (error) {
            console.error('Authentication error:', error.message || error);
            setErrorMessage('Failed to send OTP. Please try again.');
        }
    };

    // Handles each individual OTP input box
    const handleOtpChange = (index, value) => {
        if (!/^\d*$/.test(value)) return; // Only allow numeric input

        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        // Automatically move to the next input box
        if (value && index < otp.length - 1) {
            inputRefs.current[index + 1]?.focus();
        }
    };

  const handleVerifyOTP = async () => {
    const otpValue = otp.join(''); // Concatenate the OTP array into a single string
    if (otpValue.length === 4) {
      console.log("Setting isVerifying to true");
      setIsVerifying(true);
      try {
        const res = await verifyOTP({
          otp: otpValue,
          channel: isEmail(inputValue) ? 'EMAIL' : 'PHONE',
          [isEmail(inputValue) ? 'email' : 'phone']: inputValue
        });
        if (res.success) {
          setOtp('Verified');
          // setShowSuccessMessage(true); // Show success message
          // setTimeout(() => navigate(redirectPath, { state: { successMessage: "LOGIN SUCCESSFUL" } }), 3000); // Redirect after delay
        } else {
          throw new Error("OTP verification failed"); // Trigger catch block
        }
      } catch (error) {
        console.error("OTP verification error:", error.message || error);
        setErrorMessage("Failed to verify OTP. Please check your OTP and try again.");
        setOtp(['', '', '', '']);
        setIsVerifying(false);
      }
      finally {
        console.log("Setting isVerifying to false"); 
        // setIsVerifying(false); // Reset after verification attempt
      }
    } else {
      setErrorMessage("Please enter a complete 4-digit OTP.");
    }
  };
  

  // const resendOTP = () => {
  //   if (canResendOTP) {
  //     handleAuthenticate(); // Resend OTP request
  //     setOtpMessage("OTP resent!");
  //     setIsNotificationVisible(true);
  //     setTimeout(() => setIsNotificationVisible(false), 3000);
  //   }
  // };

    const oauth = (provider) => {
        setIsLoading(true);
        const OTPlessSignin = new window.OTPless(handleUserData);
        OTPlessSignin.initiate({ channel: 'OAUTH', channelType: provider });
    };

    return (
        <div className="login-page">
            {/* {showSuccessMessage && <LoginPopUp />} */}
            <div className={`notification ${isNotificationVisible ? 'show' : 'hide'}`}>OTP SENT SUCESSFULLY</div>

            <div className="card-container">
                <CSSTransition in={view === 'login'} timeout={500} classNames="slide" unmountOnExit>
                    <div className="login-container">
                        <h2 style={{ fontSize: '35px' }}>Let’s Game</h2>
                        <div className="form-group" style={{ opacity: isSectionDisabled ? 0.5 : 1 }}>
                            <p style={{ color: 'white', marginBottom: '0px', fontSize: '13px' }}>
                                Email or Phone number
                            </p>
                            <input
                                id="email-phone-input"
                                className={classNames('email-phone-box', {
                                    'error-input': { errorMessage } && !inputValue.trim(),
                                })}
                                placeholder="Enter email or phone number"
                                value={inputValue}
                                // onChange={(e) => setInputValue(e.target.value)}
                                onChange={(e) => {
                                    console.log('Typing:', e.target.value); // Log the current input
                                    setInputValue(e.target.value); // Ensure this updates the state
                                }}
                                disabled={isSectionDisabled}
                                required
                                style={{
                                    backgroundColor: '#0F1C23',
                                    border: '1px solid #45F882',
                                    borderRadius: '10px',
                                }}
                            />

                            <div
                                className="checkbox-container"
                                style={{ marginTop: '10px' }}
                                onClick={handleCheckboxChange}
                            >
                                <input
                                    type="checkbox"
                                    id="referral-code-checkbox"
                                    checked={showReferralCodeInput}
                                    // onClick={handleCheckboxChange}
                                />

                                <label
                                    // htmlFor="re ferral-code-checkbox"
                                    className="refcode-checkbox"
                                    style={{
                                        color: 'white',
                                        marginBottom: '0px',
                                        marginTop: '5px',
                                        fontSize: '13px',
                                        background: 'rgba(0, 0, 0, 0.4)',
                                    }}
                                >
                                    Have a referral code?
                                </label>
                            </div>

                            {/* Conditionally render the referral code input */}
                            {showReferralCodeInput && (
                                <input
                                    id="refcode-input"
                                    className={classNames('refcode-box', {
                                        'error-input': { errorMessage } && showReferralCodeInput && !refCode.trim(),
                                    })}
                                    placeholder="Enter referral code"
                                    value={refCode}
                                    onChange={(e) => {
                                        setRefCode(e.target.value);
                                        console.log('Referral code entered:', e.target.value);
                                    }}
                                    disabled={isSectionDisabled}
                                    style={{
                                        backgroundColor: '#0F1C23',
                                        border: '1px solid #45F882',
                                        borderRadius: '10px',
                                        marginTop: '0px',
                                    }}
                                />
                            )}

                            <button
                                className="th-btn style1 proceed-btn"
                                // onClick={handleProceed}
                                onClick={() => {
                                    handleProceed();
                                }}
                                disabled={isSectionDisabled}
                            >
                                Get OTP
                            </button>
                            {{ errorMessage } && <p className="error-message">{errorMessage}</p>}
                            {otpMessage && <p className="otp-message">{otpMessage}</p>}
                        </div>

                        <div className="divider">
                            <span>or Sign in with</span>
                        </div>

                        <div className="social-logins">
                            <a href="#" className="social-btn google" onClick={() => oauth('GOOGLE')}>
                                <i class="fab fa-google"></i>
                            </a>
                            <a href="#" className="social-btn facebook" onClick={() => oauth('FACEBOOK')}>
                                <i className="fab fa-facebook"></i>
                            </a>
                            <a href="#" className="social-btn discord" onClick={() => oauth('DISCORD')}>
                                <i className="fab fa-discord"></i>
                            </a>
                        </div>
                    </div>
                </CSSTransition>

                <CSSTransition in={view === 'verify'} timeout={500} classNames="slide" unmountOnExit>
                    <div className="login-container form-group">
                        <h2>Verify OTP</h2>
                        <div id="otp-section">
                            <div className="otp-inputs">
                                {Array.isArray(otp) &&
                                    otp.map((digit, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            maxLength="1"
                                            value={digit}
                                            onChange={(e) => handleOtpChange(index, e.target.value)}
                                            ref={
                                                index === 0 ? firstOtpInputRef : (el) => (inputRefs.current[index] = el)
                                            } // Use ref for the first OTP input
                                            className={classNames('otp-box', {
                                                'error-input': { errorMessage } && otp.some((d) => !d),
                                            })} // Add 'error-input' if there's an error
                                            required
                                        />
                                    ))}
                            </div>
                            <button
                                className="th-btn style1 verify-btn"
                                onClick={handleVerifyOTP}
                                disabled={isVerifying}
                            >
                                {isVerifying ? 'VERIFYING...' : 'VERIFY OTP'}
                            </button>
                            {{ errorMessage } && <p className="error-message">{errorMessage}</p>}

                            <div className="timer-section">
                                {/* <p className="timer">Time remaining: </p> */}
                                <p
                                    className={`resend-otp ${!canResendOTP ? 'disabled' : ''}`}
                                    onClick={canResendOTP ? handleProceed : null}
                                    style={{ color: '#aaa' }}
                                >
                                    {canResendOTP ? 'Resend OTP' : `Resend OTP (${timer}s)`}
                                </p>
                            </div>
                        </div>

                        <div className="divider">
                            <span>or Sign in with</span>
                        </div>

                        <div className="social-logins">
                            <a href="#" className="social-btn instagram" onClick={() => oauth('GOOGLE')}>
                                <i className="fab fa-google"></i>
                            </a>
                            <a href="#" className="social-btn facebook" onClick={() => oauth('FACEBOOK')}>
                                <i className="fab fa-facebook"></i>
                            </a>
                            <a href="#" className="social-btn discord" onClick={() => oauth('DISCORD')}>
                                <i className="fab fa-discord"></i>
                            </a>
                        </div>
                    </div>
                </CSSTransition>
            </div>
        </div>
    );
};

export default LoginForm;
