// import React from 'react';
// import '../../App.css';
// import $ from 'jquery'; // Import jQuery
// import Swiper from 'swiper';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';
// import 'magnific-popup/dist/magnific-popup.css';
// import 'magnific-popup';
// import 'swiper/css'; // Import base CSS
// import 'swiper/css/navigation'; // Import navigation CSS if used
// import 'swiper/css/pagination'; // Import pagination CSS if used
// import { Link } from 'react-router-dom';
// import FeatureCardBg from '../../Assets/img/feature/feature-card-bg.png';
// import Feature1_1 from '../../Assets/img/feature/feature1-1.png';
// import Feature1_2 from '../../Assets/img/feature/feature1-2.png';
// import Feature1_3 from '../../Assets/img/feature/feature1-3.png';

// const ProGameCard1 = ({ 
//     gameId,
//     featureCardBg, 
//     featureImage, 
//     gameTitle, 
//     gameDescription, 
//     gameDetailsLink, 
//     playNowLink,
//     iconUrl
//   }) => {
//     return (
      
//         <div className="feature-game-card">
//           <div className="feature-game-card-img">
//             <Link to={{
//                 pathname: gameDetailsLink,
//                 state: { gameId: gameId }
//               }} onClick={(e) => e.stopPropagation()}>
//               <div className="feature-card-img-shape icon-masking">
//                 {/* <span className="mask-icon" data-mask-src={featureCardBg}></span>
//                 <img src={featureCardBg} alt="background" /> */}
//               </div>
//               <img src={featureImage} alt="game" />
//             </Link>
//             {/* <Link to={playNowLink} className="th-btn" onClick={(e) => e.stopPropagation()}>
//               PLAY NOW <i className="fa-solid fa-arrow-right ms-2"></i>
//             </Link> */}
//           </div>
//           <div className="feature-game-card-details">
//             <h3 className="box-title">
//             {gameTitle}
//             </h3>
//             {/* <p className="text">{gameDescription}</p> */}
//           </div>
//         </div>
     
//     );
//   };
  
//   export default ProGameCard1;



import React from 'react';
import '../../App.css';
import { useNavigate } from 'react-router-dom';

const ProGameCard1 = ({ 
    gameId,
    featureCardBg, 
    featureImage, 
    gameTitle, 
    gameHandle,
    handleGameClick,
    // handlePlayNowClick,
    handleConnectGameAccount,
    gameDescription, 
    iconUrl
  }) => {
    const navigate = useNavigate();

    return (
      <div className="feature-game-card"    style={{ backgroundColor: 'black', cursor: 'pointer',    display:'flex',
          flexDirection:'column',
          justifyContent: 'center',
          alignItems: 'center',
       }}
       >
       {/* onClick={() => handlePlayNowClick(gameId)} */}
        {/* <div className="feature-game-card-img"> */}
          {/* <div className="feature-card-img-shape icon-masking">
            {/* Image shape logic */}
          {/* </div>  */}
          {/* <img src={featureImage} alt="game" /> */}
        {/* </div> */}
        <div
                    // className="game-card-img"
                    class="product-img custom-game-image"
                    style={{ position: 'relative', cursor: 'pointer' }}
                    onClick={() => handleGameClick(gameId)}
                >
                    {/* <Link to={gameDetailsLink}> */}
                    <img src={featureImage} alt="game-image" />
                    <div className="overlay gradient-border"></div>
                    {/* </Link> */}

                    {/* Blur mask with "CONNECT GAMER ACCOUNT" only on the game image */}
                    {!gameHandle && (
                        <div
                            className="blur-mask"
                            style={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                color: '#fff',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                zIndex: 2,
                            }}
                        >
                            <button
                                className="connect-game-account"
                                style={{
                                    backgroundColor: 'transparent',
                                    fontSize: '12px',
                                    color: '#45F882',
                                    border: '1px solid #45F882',
                                    cursor: 'pointer',
                                    borderRadius: '35px',
                                }}
                                onClick={(e) => 
                                {  e.stopPropagation(); // Prevent parent click
                                  handleConnectGameAccount(gameId); 
                                }}
                            >
                                CONNECT GAME ACCOUNT
                            </button>
                        </div>
                    )}
                </div>
        {/* <div className="feature-game-card-details">
          <h3 className="box-title">
            {gameTitle}
          </h3>
        </div> */}
      </div>
    );
  };

export default ProGameCard1;