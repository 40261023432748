// import React , { useEffect, useState , useContext } from 'react';
// import { useParams } from 'react-router-dom';
// import { AuthContext } from '../../Utils/Contexts/authContext';
// import '../../App.css';
// import '../../CustomCss/customStyles.css';
// import { Link } from 'react-router-dom';
// import $ from 'jquery'; // Import jQuery
// import 'bootstrap/dist/css/bootstrap.min.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';
// import 'magnific-popup/dist/magnific-popup.css';
// import 'magnific-popup';
// import Waypoint from 'waypoints/lib/noframework.waypoints';
// import SmoothScroll from 'smooth-scroll';
// import WOW from 'wowjs';
// import { gsap } from 'gsap';
// import GameVS1 from '../../Assets/img/tournament/game-vs1.svg'
// import TournamentCard2Bg from '../../Assets/img/bg/tournament-card2-bg.png'
// import TournamentSec2Bg from '../../Assets/img/bg/tournament-sec2-bg.png'
// import Isotope from 'isotope-layout';
// import imagesLoaded from 'imagesloaded';
// import Card2 from '../Common/Card2';
// import Card3 from '../Common/Card3';
// import Tournamnet1_1 from '../../Assets/img/tournament/1-1.png'
// import Tournamnet1_2 from '../../Assets/img/tournament/1-2.png'
// import Tournamnet1_3 from '../../Assets/img/tournament/1-3.png'
// import Tournamnet1_4 from '../../Assets/img/tournament/1-4.png'
// import Tournamnet1_5 from '../../Assets/img/tournament/1-5.png'
// import Tournamnet1_6 from '../../Assets/img/tournament/1-6.png'
// import Tournamnet1_7 from '../../Assets/img/tournament/1-7.png'
// import Tournamnet1_8 from '../../Assets/img/tournament/1-8.png'
// import Tournamnet1_9 from '../../Assets/img/tournament/1-9.png'
// import Tournamnet1_10 from '../../Assets/img/tournament/1-10.png'
// import Tournamnet1_11 from '../../Assets/img/tournament/1-11.png'
// import Tournamnet1_12 from '../../Assets/img/tournament/1-12.png'
// import ProTournamentCard7 from '../ProtectedComponents/proTournamentCard7';
// import { fetchWithAuth } from '../../Utils/ApiServices/apiFunctions';

// window.jQuery = $;
// require('isotope-layout/dist/isotope.pkgd.js');
// imagesLoaded.makeJQueryPlugin($);

// const defaultGameTab = "75c0c41e-5d98-4d21-8e85-9c96ed240da1";

// const UserTournamentsList = () => {
//   let gameTab = localStorage.getItem("selectedGameTab") ?? defaultGameTab;
//   console.log("gameTab :", gameTab)
//   const [gameId , setGameId] = useState(gameTab);
//   const { accessToken } = useContext(AuthContext);
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [tournaments, setTournaments] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1); // Current page number
//   const [totalPages, setTotalPages] = useState(1);   // Total pages from API response
//   const [pageSize, setPageSize] = useState(10);      // Number of tournaments per page
//   const [totalRecords, setTotalRecords] = useState(10);
//   const [selectedFilter, setSelectedFilter] = useState('upcoming'); // Default filter is 'upcoming'
//   const [activeFilter, setActiveFilter] = useState('upcoming'); // Track the active filter button

//   console.log({
//     status: selectedFilter,
//     pageSize,
//     currentPage,
//     gameId
//   });

//   useEffect(() => {
//     const fetchTournaments = async () => {
//       setIsLoading(true);
//       try {
//         const response = await fetch(
//           `${BASE_URL}/user/tournaments?status=${selectedFilter}&game_id=${gameTab}&page_size=${pageSize}&page=${currentPage}`, {
//           method: 'GET',
//           headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${accessToken}`,
//           }
//         });

//         if (!response.ok) {
//           throw new Error(`Failed to fetch tournaments: ${response.status} ${response.statusText}`);
//         }

//         const data = await response.json();
//         console.log("User tournaments response : ", data);
//         // const filteredTournaments = data.tournaments.filter(tournament => tournament.game_id === parseInt(gameId));
//         // setTournaments(filteredTournaments); // Handle cases where tournaments array might be empty
//         if (Array.isArray(data.tournaments)) {
//           console.log('Fetched tournaments data:', data.tournaments);
//           setTournaments(data.tournaments); // Set the array of tournaments
//           setTotalPages(data.total_pages);
//         } else {
//           setTournaments([]); // If not an array, set it to an empty array
//           throw new Error('Tournaments data is not in the expected format.');
//         }

//       } catch (e) {
//         setError(e.message);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchTournaments();
//   }, [gameTab, selectedFilter, currentPage, pageSize, accessToken]);

//   const handleFilterChange = (filter) => {
//     console.log("This is my filter code");
//     setSelectedFilter(filter);
//     setActiveFilter(filter);
//     setCurrentPage(1); // Reset to page 1 when the filter changes
//   };

//   // useEffect(() => {
//   //   // Initialize Isotope for the filtering functionality
//   //   console.log("This is the filter code from the template");
//   //   $(".filter-active").imagesLoaded(() => {
//   //     const $filter = ".filter-active";
//   //     const $filterItem = ".filter-item";
//   //     const $filterMenu = ".filter-menu-active";

//   //     if ($($filter).length > 0) {
//   //       const $grid = $($filter).isotope({
//   //         itemSelector: $filterItem,
//   //         filter: "*", // Default to show all matches
//   //         masonry: {
//   //           // Optional: Adjust for masonry layout if needed
//   //         },
//   //       });

//   //       // Filter items on button click
//   //       $($filterMenu).on("click", "button", function () {
//   //         const filterValue = $(this).attr("data-filter");
//   //         $grid.isotope({ filter: filterValue });
//   //       });

//   //       // Manage active class on filter buttons
//   //       $($filterMenu).on("click", "button", function (event) {
//   //         event.preventDefault();
//   //         $(this).addClass("active");
//   //         $(this).siblings(".active").removeClass("active");
//   //       });
//   //     }
//   //   });
//   // }, [tournaments]); // Re-run effect when tournaments change

//   if (isLoading) {
//     return <div>Loading.....</div>;
//   }

//   if (error) {
//     return <div>Something went wrong! Please try again later..</div>;
//   }

//   // if (tournaments.length === 0 && !isLoading) {
//   //   return <div>No tournaments available at the moment.</div>; // Handle empty tournaments list
//   // }

//   const tournamentImages = [Tournamnet1_1, Tournamnet1_2, Tournamnet1_3, Tournamnet1_4, Tournamnet1_5, Tournamnet1_6, Tournamnet1_7, Tournamnet1_8, Tournamnet1_9, Tournamnet1_10, Tournamnet1_11, Tournamnet1_12];

//   return (
//     <>
//       <section className="tournament-sec-v2 space-top space-extra2-bottom" data-bg-src={TournamentSec2Bg}>
//         <div className="container">
//           <div className="row justify-content-between">
//             <div className="col-lg-12">
//               <div className="title-area text-center">
//                 <span className="sub-title style2"># Game Streaming Battle </span>
//                 <h2 className="sec-title">Our Gaming Tournaments <span className="text-theme">!</span></h2>
//               </div>
//             </div>
//             <div className="col-lg-12">
//               <div className="tournament-filter-btn2 filter-menu filter-menu-active">
//                 <button data-filter=".cat1"
//                 className={`tab-btn th-btn style-border3 ${activeFilter === 'upcoming' ? 'active' : ''}`}
//                 type="button"
//                 onClick={() => handleFilterChange('upcoming')}>
//                   <span className="btn-border">UPCOMING <i className="fa-solid fa-arrow-right ms-2"></i></span>
//                 </button>

//                 <button data-filter=".cat1"
//                 className={`tab-btn th-btn style-border3 ${activeFilter === 'live' ? 'active' : ''}`}
//                 type="button"
//                 onClick={() => handleFilterChange('live')}>
//                   <span className="btn-border">LIVE <i className="fa-solid fa-arrow-right ms-2"></i></span>
//                 </button>

//                 <button data-filter=".cat2"
//                 className={`tab-btn th-btn style-border3 ${activeFilter === 'previous' ? 'active' : ''}`}
//                 type="button"
//                 onClick={() => handleFilterChange('previous')}>
//                   <span className="btn-border">PREVIOUS <i className="fa-solid fa-arrow-right ms-2"></i></span>
//                 </button>

//                 {/* <button data-filter="*"
//                 className={`tab-btn th-btn style-border3 ${activeFilter === 'all' ? 'active' : ''}`}
//                 type="button"
//                 onClick={() => handleFilterChange('all')}>
//                   <span className="btn-border">ALL <i className="fa-solid fa-arrow-right ms-2"></i></span>
//                 </button> */}

//               </div>
//             </div>
//           </div>

//           <div className="row gy-40 filter-active">
//             {tournaments.length === 0? <div>No tournaments available at the moment.</div>: <></>}
//             {tournaments.map((tournament, index) => (
//               <ProTournamentCard7
//               key={tournament.tournament_id}
//               gameId={gameId}
//               tournamentId={tournament.tournament_id}
//               // tournamentImg1={GameVS1} // Placeholder image
//               // tournamentImg2={GameVS1} // Placeholder image
//               tournamentImage={tournamentImages[index % tournamentImages.length]}
//               gameVSImg={GameVS1}
//               cardBg={TournamentCard2Bg}
//               subtitle1={tournament.mode}
//               title1={tournament.name}
//               subtitle2={tournament.duration}
//               title2={tournament.prize_pool}
//               time={new Date(tournament.start_time * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' })}
//               date={new Date(tournament.start_time * 1000).toLocaleDateString()}
//               status={tournament.status}
//               currentPlayers={tournament.current_players}
//               maxPlayers={tournament.max_players}
//               detailsLink={`/proTournament-details/${gameId}/${tournament.tournament_id}`}
//               ctaText={tournament.cta_text}
//               ctaEnabled={tournament.cta_enabled}
//               entryFee={tournament.entry_fee}
//               />
//             ))}
//           </div>

//           {pageSize >= 10 && (
//             <div className="pt-60 text-center">
//               <div className="th-pagination pagination-btn">
//                 <ul>
//                   <li>
//                     <button
//                       className="page-previous"
//                       onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
//                       disabled={currentPage === 1}
//                     >
//                       <span className="btn-border"><i className="far fa-arrow-left"></i></span>
//                     </button>
//                   </li>
//                   <li>
//                     <span>{currentPage}</span>
//                   </li>
//                   <li>
//                     <button
//                       className="page-next"
//                       onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
//                       disabled={currentPage === totalPages}
//                     >
//                       <span className="btn-border"><i className="far fa-arrow-right"></i></span>
//                     </button>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           )}
//         </div>
//       </section>
//     </>
//   );
// };

// export default UserTournamentsList;

import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../Utils/Contexts/authContext';
import '../../App.css';
import '../../CustomCss/customStyles.css';
import { Link } from 'react-router-dom';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Waypoint from 'waypoints/lib/noframework.waypoints';
import SmoothScroll from 'smooth-scroll';
import WOW from 'wowjs';
import { gsap } from 'gsap';
import GameVS1 from '../../Assets/img/tournament/game-vs1.svg';
import TournamentCard2Bg from '../../Assets/img/bg/tournament-card2-bg.png';
import TournamentSec2Bg from '../../Assets/img/bg/tournament-sec2-bg.png';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import Card2 from '../Common/Card2';
import Card3 from '../Common/Card3';
import ProTournamentCard7 from '../ProtectedComponents/proTournamentCard7';
import { fetchWithAuth } from '../../Utils/ApiServices/apiFunctions';
import { BASE_URL } from '../../Utils/ApiServices/apiFunctions';
import useScreenSize from '../../Utils/Contexts/useScreenSize';
import TournamentCardMobile from '../MobileViewComp/tournamentCardMobile';

window.jQuery = $;
require('isotope-layout/dist/isotope.pkgd.js');
imagesLoaded.makeJQueryPlugin($);

const defaultGameTab = '75c0c41e-5d98-4d21-8e85-9c96ed240da1';

const UserTournamentsList = ({ selectedGameId }) => {
    // let gameTab = localStorage.getItem("selectedGameTab") ?? '';
    let gameTab = selectedGameId;
    console.log('gameTab :', gameTab);
    const [gameId, setGameId] = useState(gameTab);
    const { accessToken } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [tournaments, setTournaments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // Current page number
    const [totalPages, setTotalPages] = useState(1); // Total pages from API response
    const [pageSize, setPageSize] = useState(10); // Number of tournaments per page
    const [totalRecords, setTotalRecords] = useState(0); // Initialize as 0
    const [selectedFilter, setSelectedFilter] = useState('all'); // Default filter is 'upcoming'
    const [activeFilter, setActiveFilter] = useState('all'); // Track the active filter button
    const isMobile = useScreenSize();
    console.log({
        status: selectedFilter,
        pageSize,
        currentPage,
        gameId,
    });

    // Function to map game names to folder names
    const mapGameToFolder = (gameName) => {
        switch (gameName) {
            case 'Clash Royale':
                return 'Clash Royale';
            case 'Clash of Clans':
                return 'Clash of clans';
            case 'Chess':
                return 'Chess';
            default:
                return 'default'; // Fallback folder
        }
    };

    // Updated getImage function to handle different game folders
    const getImage = (gameName, iconName) => {
        const folder = mapGameToFolder(gameName); // Get the folder based on the game name
        try {
            return require(`../../Assets/img/tournament/${folder}/${iconName}`);
        } catch (err) {
            console.error(`Image ${iconName} not found in ${folder}, using default.`);
            return require('../../Assets/img/tournament/Chess/1_1.png'); // Default fallback image
        }
    };

    useEffect(() => {
        const fetchTournaments = async () => {
            setIsLoading(true);
            try {
                const response = await fetchWithAuth(
                    `${BASE_URL}/user/tournaments?status=${selectedFilter}&game_id=${gameTab}&page_size=${pageSize}&page=${currentPage}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${accessToken}`,
                        },
                    },
                );

                if (!response.ok) {
                    throw new Error(`Failed to fetch tournaments: ${response.status} ${response.statusText}`);
                }

                const data = await response.json();
                console.log('User tournaments response : ', data);

                if (Array.isArray(data.tournaments)) {
                    console.log('Fetched tournaments data:', data.tournaments);
                    setTournaments(data.tournaments); // Set the array of tournaments
                    setTotalPages(data.total_pages);
                    setTotalRecords(data.total_records); // Set the total records count
                } else {
                    setTournaments([]); // If not an array, set it to an empty array
                    setTotalRecords(0); // Ensure total records is 0 when there are no tournaments
                    throw new Error('Tournaments data is not in the expected format.');
                }
            } catch (e) {
                setError(e.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTournaments();
    }, [gameTab, selectedFilter, currentPage, pageSize, accessToken]);

    const handleFilterChange = (filter) => {
        console.log('This is my filter code');
        setSelectedFilter(filter);
        setActiveFilter(filter);
        setCurrentPage(1); // Reset to page 1 when the filter changes
    };

    if (isLoading) {
        return <div>Loading.....</div>;
    }

    if (error) {
        return (
            <div
                style={{
                    color: '#45F882',
                    textAlign: 'center',
                    border: '2px solid #45F882',
                    padding: '5px',
                    width: '100%',
                    margin: '10px',
                }}
            >
                Something went wrong! Please try again later..
            </div>
        );
    }

    return (
        <>
            <section className="tournament-sec-v2 space-top space-extra2-bottom" data-bg-src={TournamentSec2Bg}>
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-12">
                            <div className="title-area text-center">
                                <span className="sub-title style2"># Agon Battles </span>
                                <h2 className="sec-title">
                                    Our Tournaments <span className="text-theme">!</span>
                                </h2>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="tournament-filter-btn2 filter-menu filter-menu-active">
                                <button
                                    data-filter=".cat1"
                                    className={`tab-btn th-btn style-border3 ${activeFilter === 'all' ? 'active' : ''}`}
                                    type="button"
                                    onClick={() => handleFilterChange('all')}
                                >
                                    <span className="btn-border">
                                        All <i className="fa-solid fa-arrow-right ms-2"></i>
                                    </span>
                                </button>
                                <button
                                    data-filter=".cat1"
                                    className={`tab-btn th-btn style-border3 ${
                                        activeFilter === 'upcoming' ? 'active' : ''
                                    }`}
                                    type="button"
                                    onClick={() => handleFilterChange('upcoming')}
                                >
                                    <span className="btn-border">
                                        UPCOMING <i className="fa-solid fa-arrow-right ms-2"></i>
                                    </span>
                                </button>

                                <button
                                    data-filter=".cat1"
                                    className={`tab-btn th-btn style-border3 ${
                                        activeFilter === 'live' ? 'active' : ''
                                    }`}
                                    type="button"
                                    onClick={() => handleFilterChange('live')}
                                >
                                    <span className="btn-border">
                                        LIVE <i className="fa-solid fa-arrow-right ms-2"></i>
                                    </span>
                                </button>

                                <button
                                    data-filter=".cat2"
                                    className={`tab-btn th-btn style-border3 ${
                                        activeFilter === 'previous' ? 'active' : ''
                                    }`}
                                    type="button"
                                    onClick={() => handleFilterChange('previous')}
                                >
                                    <span className="btn-border">
                                        PREVIOUS <i className="fa-solid fa-arrow-right ms-2"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="row gy-40 filter-active"  >
                        {tournaments.map((tournament, index) => (
                             isMobile ? (
                                <TournamentCardMobile
                                    key={tournament.tournament_id}
                                    gameId={gameId}
                                    tournamentId={tournament.tournament_id}
                                    tournamentImage={getImage(tournament.name, tournament.image_icon)}
                                    gameVSImg={GameVS1}
                                    cardBg={TournamentCard2Bg}
                                    subtitle1={tournament.mode}
                                    title1={tournament.name}
                                    subtitle2={tournament.duration}
                                    title2={tournament.prize_pool}
                                    endTime={tournament.end_time}
                                    startTime={tournament.start_time}
                                    status={tournament.status}
                                    currentPlayers={tournament.current_players}
                                    maxPlayers={tournament.max_players}
                                    detailsLink={`/proTournament-details/${gameId}/${tournament.tournament_id}`}
                                    ctaText={tournament.cta_text}
                                    ctaEnabled={tournament.cta_enabled}
                                    entryFee={tournament.entry_fee}
                                    prizePoolType={tournament.prize_pool_type}
                                />
                            ) : (
                                <ProTournamentCard7
                                    key={tournament.tournament_id}
                                    gameId={gameId}
                                    tournamentId={tournament.tournament_id}
                                    tournamentImage={getImage(tournament.name, tournament.image_icon)}
                                    gameVSImg={GameVS1}
                                    cardBg={TournamentCard2Bg}
                                    subtitle1={tournament.mode}
                                    title1={tournament.name}
                                    subtitle2={tournament.duration}
                                    title2={tournament.prize_pool}
                                    endTime={tournament.end_time}
                                    startTime={tournament.start_time}
                                    status={tournament.status}
                                    currentPlayers={tournament.current_players}
                                    maxPlayers={tournament.max_players}
                                    detailsLink={`/proTournament-details/${gameId}/${tournament.tournament_id}`}
                                    ctaText={tournament.cta_text}
                                    ctaEnabled={tournament.cta_enabled}
                                    entryFee={tournament.entry_fee}
                                />
                            )
                            // <ProTournamentCard7
                            //     key={tournament.tournament_id}
                            //     gameId={gameId}
                            //     tournamentId={tournament.tournament_id}
                            //     tournamentImage={getImage(tournament.name, tournament.image_icon)}
                            //     gameVSImg={GameVS1}
                            //     cardBg={TournamentCard2Bg}
                            //     subtitle1={tournament.mode}
                            //     title1={tournament.name}
                            //     subtitle2={tournament.duration}
                            //     title2={tournament.prize_pool}
                            //     endTime={tournament.end_time}
                            //     startTime={tournament.start_time}
                            //     // time={new Date(tournament.start_time * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' })}
                            //     // date={new Date(tournament.start_time * 1000).toLocaleDateString()}
                            //     status={tournament.status}
                            //     currentPlayers={tournament.current_players}
                            //     maxPlayers={tournament.max_players}
                            //     detailsLink={`/proTournament-details/${gameId}/${tournament.tournament_id}`}
                            //     ctaText={tournament.cta_text}
                            //     ctaEnabled={tournament.cta_enabled}
                            //     entryFee={tournament.entry_fee}
                            // />
                        ))}
                    </div>

                    {(tournaments.length > 9 || totalRecords > pageSize) && (
                        <div className="pt-60 text-center">
                            <div className="th-pagination pagination-btn">
                                <ul>
                                    <li>
                                        <button
                                            className="page-previous"
                                            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                            disabled={currentPage === 1}
                                        >
                                            <span className="btn-border">
                                                <i className="far fa-arrow-left"></i>
                                            </span>
                                        </button>
                                    </li>
                                    <li>
                                        <span>{currentPage}</span>
                                    </li>
                                    <li>
                                        <button
                                            className="page-next"
                                            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                            disabled={currentPage === totalPages}
                                        >
                                            <span className="btn-border">
                                                <i className="far fa-arrow-right"></i>
                                            </span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </>
    );
};

export default UserTournamentsList;
