import React, { useEffect, useState } from 'react';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Swiper from 'swiper';
import 'swiper/css'; // Import base CSS
import 'swiper/css/navigation'; // Import navigation CSS if used
import 'swiper/css/pagination'; // Import pagination CSS if used
import { useAppContext } from '../../Utils/Contexts/appContext';

const GamersShop = () => {
    const { productDetails, userProfile } = useAppContext();

    useEffect(() => {
        // Initialize Swiper slider for GamerShop
        const shopSwiper = new Swiper('.shop-slider', {
            slidesPerView: 4, // Use 4 slides per view for GamerShop
            spaceBetween: 24,
            loop: true,
            speed: 1000,
            autoplay: { delay: 6000, disableOnInteraction: false },
            navigation: {
                nextEl: '.shop-slider-next',
                prevEl: '.shop-slider-prev',
            },
            pagination: {
                el: '.shop-slider-pagination',
                clickable: true,
                renderBullet: function (index, className) {
                    return `<span class="${className}" aria-label="Go to Slide ${index + 1}"></span>`;
                },
            },
            breakpoints: {
                576: { slidesPerView: 1 },
                768: { slidesPerView: 2 },
                992: { slidesPerView: 3 },
                1200: { slidesPerView: 4 }, // Ensure slidesPerView is 4 for larger screens
            },
        });
    }, []);

    return (
        <>
            <section className="space" style={{ background: 'var(--title-color)' }}>
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-auto">
                            <div
                                className="title-area custom-anim-left wow animated text-md-start text-center"
                                data-wow-duration="1.5s"
                                data-wow-delay="0.2s"
                            >
                                <span className="sub-title"># Gamer Shop</span>
                                <h2 className="sec-title">
                                    Rewards Across Popular Brands <span className="text-theme">!</span>
                                </h2>
                            </div>
                        </div>
                        <div className="col-md-auto d-none d-md-block">
                            <div className="sec-btn">
                                <div className="icon-box">
                                    <button data-slider-prev="#productSlider1" className="slider-arrow style2 default">
                                        <i className="fa fa-arrow-left"></i>
                                    </button>
                                    <button data-slider-next="#productSlider1" className="slider-arrow style2 default">
                                        <i className="fa fa-arrow-right"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="swiper shop-slider has-shadow"
                        id="productSlider1"
                        data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"2"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"3"},"1200":{"slidesPerView":"4"}}}'
                    >
                        <div className="swiper-wrapper">
                            {[...productDetails]
                                .sort((a, b) => a.valueDenominations - b.valueDenominations)
                                .map((product) => (
                                    <div className="swiper-slide">
                                        <div
                                            className="th-product product-grid"
                                            id={`product-${product.id}-${product.valueDenominations}`}
                                            onClick={() => (window.location.href = userProfile ? '/proShop' : '/shop')}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <div className="product-img">
                                                <img src={product.imageUrl} alt={product.name} />
                                                <div className="overlay gradient-border"></div>
                                            </div>
                                            <div className="product-content">
                                                <h3 className="product-title">
                                                    <span>{product.name}</span>
                                                </h3>
                                                <span className="price">₹{product.valueDenominations}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className="d-block d-md-none mt-40 text-center">
                        <div className="icon-box">
                            <button
                                data-slider-prev="#productSlider1"
                                className="slider-arrow style2 default shop-slider-prev"
                            >
                                <i className="far fa-arrow-left"></i>
                            </button>
                            <button
                                data-slider-next="#productSlider1"
                                className="slider-arrow style2 default shop-slider-next"
                            >
                                <i className="far fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default GamersShop;
