import React, { useEffect, useState } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Waypoint from 'waypoints/lib/noframework.waypoints';
import SmoothScroll from 'smooth-scroll';
import WOW from 'wowjs';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import { gsap } from 'gsap';
import BameLogo from '../../Assets/img/logo.svg';
import MobileMenu from '../LandingPageComp/mobileMenu';

const SimpleHeader1 = ({ toggleMenu }) => {
    const [isSticky, setIsSticky] = useState(false);
    const [isCategoryOpen, setIsCategoryOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // State for mobile menu

    // const scrollToSection = (event) => {
    //     event.preventDefault(); // Prevent default anchor behavior
    //     const section = document.getElementById('how-it-works');
    //     if (section) {
    //       section.scrollIntoView({ behavior: 'smooth' });
    //     }
    // };

    const scrollToSection1 = (event) => {
        event.preventDefault(); // Prevent default anchor behavior
        const section = document.getElementById('games');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToSection2 = (event) => {
        event.preventDefault(); // Prevent default anchor behavior
        const section = document.getElementById('tournament');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 500) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Toggle category menu visibility
    const toggleCategoryMenu = (e) => {
        e.preventDefault();
        setIsCategoryOpen(!isCategoryOpen);
    };

    // Toggle mobile menu visibility
    const toggleMobileMenu = () => {
        console.log('Button is clicked');
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    // useEffect(() => {
    //     // Define the thmobilemenu plugin within the component
    //     (function ($) {
    //       $.fn.thmobilemenu = function (options) {
    //         var opt = $.extend(
    //           {
    //             menuToggleBtn: ".th-menu-toggle",
    //             bodyToggleClass: "th-body-visible",
    //             subMenuClass: "th-submenu",
    //             subMenuParent: "menu-item-has-children",
    //             thSubMenuParent: "th-item-has-children",
    //             subMenuParentToggle: "th-active",
    //             meanExpandClass: "th-mean-expand",
    //             appendElement: '<span class="th-mean-expand"></span>',
    //             subMenuToggleClass: "th-open",
    //             toggleSpeed: 400,
    //           },
    //           options
    //         );

    //         return this.each(function () {
    //           var menu = $(this); // Select menu

    //           // Menu Show & Hide
    //           function menuToggle() {
    //             menu.toggleClass(opt.bodyToggleClass);

    //             // Collapse submenu on menu hide or show
    //             var subMenu = "." + opt.subMenuClass;
    //             $(subMenu).each(function () {
    //               if ($(this).hasClass(opt.subMenuToggleClass)) {
    //                 $(this).removeClass(opt.subMenuToggleClass);
    //                 $(this).css("display", "none");
    //                 $(this).parent().removeClass(opt.subMenuParentToggle);
    //               }
    //             });
    //           }

    //           // Class Set Up for every submenu
    //           menu.find("." + opt.subMenuParent).each(function () {
    //             var submenu = $(this).find("ul");
    //             submenu.addClass(opt.subMenuClass);
    //             submenu.css("display", "none");
    //             $(this).addClass(opt.subMenuParent);
    //             $(this).addClass(opt.thSubMenuParent); // Add th-item-has-children class
    //             $(this).children("a").append(opt.appendElement);
    //           });

    //           // Toggle Submenu
    //           function toggleDropDown($element) {
    //             var submenu = $element.children("ul");
    //             if (submenu.length > 0) {
    //               $element.toggleClass(opt.subMenuParentToggle);
    //               submenu.slideToggle(opt.toggleSpeed);
    //               submenu.toggleClass(opt.subMenuToggleClass);
    //             }
    //           }

    //           // Submenu toggle Button
    //           var itemHasChildren = "." + opt.thSubMenuParent + " > a";
    //           $(itemHasChildren).each(function () {
    //             $(this).on("click", function (e) {
    //               e.preventDefault();
    //               toggleDropDown($(this).parent());
    //             });
    //           });

    //           // Menu Show & Hide On Toggle Btn click
    //           $(opt.menuToggleBtn).each(function () {
    //             $(this).on("click", function () {
    //               menuToggle();
    //             });
    //           });

    //           // Hide Menu On outside click
    //           menu.on("click", function (e) {
    //             e.stopPropagation();
    //             menuToggle();
    //           });

    //           // Stop Hide full menu on menu click
    //           menu.find("div").on("click", function (e) {
    //             e.stopPropagation();
    //           });
    //         });
    //       };
    //     })($);

    //     // Initialize the plugin on the menu wrapper
    //     $(".th-menu-wrapper").thmobilemenu({
    //       menuToggleBtn: ".th-menu-toggle",
    //       bodyToggleClass: "th-body-visible",
    //       subMenuClass: "th-submenu",
    //       subMenuParent: "menu-item-has-children",
    //       thSubMenuParent: "th-item-has-children",
    //       subMenuParentToggle: "th-active",
    //       meanExpandClass: "th-mean-expand",
    //       appendElement: '<span class="th-mean-expand"></span>',
    //       subMenuToggleClass: "th-open",
    //       toggleSpeed: 400,
    //     });
    //   }, []); // Run once when the component mounts

    return (
        <>
            <header className={`th-header header-layout1 ${isSticky ? 'sticky' : ''}`} style={{ color: 'white' }}>
                {/* Main Menu Area */}
                <div className={`sticky-wrapper ${isSticky ? 'sticky' : ''}`}>
                    <div className="menu-area">
                        <div className="container">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto">
                                    <div className="header-logo">
                                        <Link to="/">
                                            {/* <span data-mask-src={BameLogo} className="logo-mask"></span> */}
                                            <img src={BameLogo} alt="Bame" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <nav
                                        className="main-menu d-none d-lg-inline-block"
                                        style={{ textDecoration: 'none', marginLeft: '0' }}
                                    >
                                        <ul>
                                            {/* <li className="menu-item-has-children" style={{color:"black"}}> */}
                                            <li style={{ color: 'white' }}>
                                                <Link to="/">HOME</Link>
                                            </li>
                                            {/* <li className="menu-item-has-children">
                                                <a href="#how-it-works"  onClick={scrollToSection}>HOW IT WORKS</a></li> */}
                                            {/* <li className="menu-item-has-children"> */}
                                            {/* <li> */}
                                            {/* <Link to='/games'>GAMES</Link></li> */}
                                            {/* <a href="#games"  onClick={scrollToSection1}>GAMES</a></li> */}
                                            {/* <li className="menu-item-has-children"> */}
                                            <li>
                                                {/* <Link to='/tournament'>TOURNAMENTS</Link> */}
                                                <a href="#tournament" onClick={scrollToSection2}>
                                                    TOURNAMENTS
                                                </a>
                                                {/* <ul className="sub-menu">
                                            <li><Link to='/tournament'>Tournament</Link></li>
                                            <li><Link to='/tournament-details'>Tournament Details</Link></li>
                                        </ul> */}
                                            </li>
                                            <li className="menu-item-has-children">
                                                <Link to="/shop">SHOP</Link>
                                                <ul className="sub-menu">
                                                    <li>
                                                        <Link to="/cart">Cart</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/checkout">Checkout</Link>
                                                    </li>
                                                </ul>
                                            </li>
                                            {/* 
                                            <li><Link to='/leaderboard'>LEADERBOARD</Link></li> */}
                                            <li>
                                                <Link to="/partner">PARTNER</Link>
                                            </li>
                                            {/* <li><Link to='/partner'>AGONTRIBE LEADS</Link></li> */}
                                            <li>
                                                <button
                                                    type="button"
                                                    className="simple-icon sideMenuInfo header-button"
                                                    onClick={toggleMenu}
                                                >
                                                    <i className="fa-solid fa-bars"></i>
                                                </button>
                                            </li>
                                            <li className="btn-group">
                                                <Link
                                                    to="/login"
                                                    className="th-btn"
                                                    style={{ padding: '20px', color: 'black' }}
                                                >
                                                    Login
                                                </Link>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div className="header-button d-flex d-lg-none">
                                        <button type="button" className="th-menu-toggle" onClick={toggleMobileMenu}>
                                            <span className="btn-border"></span>
                                            <i className="far fa-bars"></i>
                                        </button>
                                    </div>
                                </div>
                                {/* <div className="col-auto d-none d-xl-block" style={{ marginLeft: "1100px" , marginBottom:"10px" }}>
                                    <div className="header-button">
                                        <button type="button" className="simple-icon sideMenuInfo" onClick={toggleMenu}>
                                        <i className="fa-solid fa-bars"></i>
                                        </button>
                                        <div className="d-xxl-block d-none">
                                        <Link to='/login' className="th-btn">
                                            <i className="fa-brands fa-twitch me-1"></i> Login
                                        </Link>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className={`category-menu ${isCategoryOpen ? 'open-category' : 'close-category'}`}>
                            {/* Your category menu content here */}
                        </div>
                        <div className="logo-bg"></div>
                    </div>
                </div>
            </header>

            {/* Mobile Menu Sliding Effect */}
            <MobileMenu isOpen={isMobileMenuOpen} toggleMenu={toggleMobileMenu} />
        </>
    );
};

export default SimpleHeader1;
