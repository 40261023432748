import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_URL, fetchWithAuth } from '../../Utils/ApiServices/apiFunctions';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken') || null);
    // const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken') || null);
    const navigate = useNavigate();

    const login = (token, refresh_token) => {
        setAccessToken(token);
        localStorage.setItem('accessToken', token);
        localStorage.setItem('refreshToken', refresh_token);

        console.log('AccessToken set in state:', token);
        console.log('AccessToken stored in localStorage:', localStorage.getItem('accessToken'));
    };

    // const logout = () => {
    //   console.log('Logging out from AuthContext...');
    //   setAccessToken(null);
    //   localStorage.removeItem('accessToken');

    //   console.log('AccessToken after logout in state:', accessToken);
    //   console.log('AccessToken removed from localStorage:', localStorage.getItem('accessToken'));

    // };

    const logout = async () => {
        console.log('Logging out from AuthContext...');

        try {
            const response = await fetchWithAuth(`${BASE_URL}/logout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            const result = await response.json();
            console.log('Logout API response:', result);

            if (response.ok) {
                console.log('Hey Successfully logged out:', result.message);

                // Clear the token and localStorage after successful logout
                setAccessToken(null);
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                localStorage.clear()

                console.log('AccessToken after logout in state:', accessToken);
                console.log('AccessToken removed from localStorage:', localStorage.getItem('accessToken'));

                // Rconsole.log('Button is clicked');edirect to login page
                navigate('/');
            } else {
                console.error('Failed to log out:', result.message);
            }
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    // Keep accessToken in sync with localStorage whenever it changes
    useEffect(() => {
        if (accessToken) {
            localStorage.setItem('accessToken', accessToken);
            // navigate to home page (protected landing page)
        } else {
            localStorage.removeItem('accessToken');
        }
        console.log('AccessToken state updated in context:', accessToken);
        console.log('AccessToken stored in localStorage:', localStorage.getItem('accessToken'));
    }, [accessToken]);

    // Debugging: Log changes to accessToken whenever it updates
    // useEffect(() => {
    //   console.log('AccessToken state updated in context:', accessToken);
    // }, [accessToken]);

    //

    useEffect(() => {
        if (accessToken && window.location.pathname === '/login') {
            console.log('window.location.pathname : ', window.location.pathname);

            window.location = '/home';
        }
    }, []);

    return <AuthContext.Provider value={{ accessToken, login, logout }}>{children}</AuthContext.Provider>;
};

export { AuthProvider, AuthContext };
