import React, { useState, useContext , useEffect} from 'react';
import '../../App.css';
import '../../CustomCss/customStyles.css';
import { useNavigate } from 'react-router-dom';
import { BASE_URL, fetchWithAuth } from '../../Utils/ApiServices/apiFunctions';
import { AuthContext } from '../../Utils/Contexts/authContext';
import Timer from '../Common/Timer';
import GameHandlePopup from '../Common/GameHandlePopup';
import RealCash from '../../Assets/img/wallet/real_cash.png';
import AlertPopup from '../Common/AlertPopup';

const TournamentCardMobile = ({
    setParentActiveTab,
    gameId,
    tournamentId,
    tournamentImage,
    gameVSImg,
    subtitle1,
    title1,
    subtitle2, // Duration in hours
    title2, // Prize
    endTime, // Start time in "HH:MM" format
    startTime, // Start date in "MM/DD/YYYY" format
    status,
    detailsLink,
    currentPlayers,
    maxPlayers,
    entryFee,
    userRegistered,
    userRegistrationAllowed,
    ctaText,
    ctaEnabled,
    gamerHandle,
    duration,
    title,
    mode,
    progress,
    matchType,
    prize,
    rank,
    players,
    winRate,
    prizePoolType,
}) => {
    const [showPopup, setShowPopup] = useState({
        status: false,
        gameId: gameId,
    });
    const [ctaTextComp, setCTATextComp] = useState(ctaText);
    const [ctaEnabledcomp, setCTAEnabledComp] = useState(ctaEnabled);
    const [isLoading, setIsLoading] = useState(false);
    const [newGamerHandle, setNewGamerHandle] = useState('');
    const [showGamerHandlePrompt, setShowGamerHandlePrompt] = useState(false);
    const [isRegistered, setIsRegistered] = useState(userRegistered);
    const navigate = useNavigate();
    const { accessToken } = useContext(AuthContext);

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const [popupTitle, setPopupTitle] = useState('');
    const [popupMessage, setPopupMessage] = useState('');
    const [popupType, setPopupType] = useState(''); // success, warning, error

    
    // const openPopup = () => setIsPopupOpen(true);
    const closeAlert = () => {
         setIsPopupOpen(false);
         document.body.style.overflow = 'auto';
        };


    const handleCardClick = () => {
        navigate(detailsLink, { state: { gamerHandle } });
        // navigate(detailsLink);
    };

    const closePopup = () => {
        setShowPopup({ status: false, gameId: gameId });
    };

    // Register for Tournament
    const registerForTournament = async () => {
        try {
            const response = await fetchWithAuth(`${BASE_URL}/tournaments/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({ tournament_id: tournamentId }),
            });

            const data = await response.json();
            if (data.code === 200) {
                // alert('Successfully registered!');
                setPopupTitle('Success');
                setPopupMessage(`${data.message}`);
                setPopupType('success');
                // openPopup();
                setIsPopupOpen('true');
                document.body.style.overflow = 'hidden';
                setIsRegistered(true);
                setShowGamerHandlePrompt(false);
                setCTATextComp(data.cta_text);
                setCTAEnabledComp(data.cta_enabled);
                return true;
            } else {
                console.log('Failed to register')
                alert(`Failed to register: ${data.message}`);
                return false;
            }
        } catch (error) {
            console.error('Error registering for tournament:', error);
            setIsLoading(false);
            alert('Failed to register for the tournament.');
            return false;
        }
    };
    useEffect(() => {
        // Check if the user is registered for this specific tournament on page load
        const registrationStatus = localStorage.getItem(`isRegistered-${tournamentId}`);
        if (registrationStatus === 'true') {
            setIsRegistered(true);
        }
    }, [tournamentId]);

    // Handle "Entry" button click
    const handleEnterClick = async (e) => {
        e.stopPropagation();
        if (!accessToken) {
            navigate(`/login`, { state: { from: window.location.pathname } });
            console.log('navigate to login ');
            return;
        }

        console.log('isRegistered:', isRegistered, [isRegistered]);

        if (isRegistered) {
            navigate(detailsLink, { state: { gamerHandle } });
            return;
        }

        const localGamerHandle = localStorage.getItem(`gamerHandle-${gameId}`);
        console.log('current handle', localGamerHandle);

        if (gamerHandle || (localGamerHandle!==null && localGamerHandle!=='')) {
            // await registerForTournament();

            // setParentActiveTab('Leaderboard');
              // Register for tournament
              const registrationSuccess = await registerForTournament();
              console.log(setParentActiveTab); 
              if (registrationSuccess) {
                if (setParentActiveTab) {
                    setParentActiveTab('Leaderboard');
                  } else {
                    console.log('setParentActiveTab is not passed in this context');
                  }
                };
              

        } else {
            setShowPopup({ status: true, gameId: gameId });
            setShowGamerHandlePrompt(true); // Show the modal if no gamer handle exists
        }

        console.log('current handle', localStorage.getItem(`gamerHandle-${gameId}`));
        // if (!userRegistrationAllowed || isLoading) return;

        // const existingGamerHandle = await fetchGamerHandle();
    };

    // Handle submitting new gamer handle
    const handleSubmitGamerHandle = async (gameHandle) => {};

    return (
        // <div className="col-lg-4">
        //   <aside className="sidebar-area gradient-border-wrapper next-match-section">
        <div
            className="widget-tournament-info gradient-border-wrapper next-match-card"
            onClick={handleCardClick}
            style={{ cursor: 'pointer', marginTop: '60px', paddingRight:'3px', paddingLeft:'3px' }}
            
        >
            {isPopupOpen && (
                <AlertPopup
                    title={popupTitle}
                    message={popupMessage}
                    action="Ok"
                    onClose={closeAlert}
                    type={popupType}
                />
            )}
            <div className="next-match-inner">
                {/* Live Status */}
                {/* Character Image */}
                <img src={tournamentImage} alt={`${title} Character`} className="character-image" />

                <div className="match-status">
                    <span className="status-label">{status}</span>
                </div>

                {/* Match Title */}
                <h3 className="match-title">{title1}</h3>

                {/* Match Details and Timer*/}
                <div className="match-details">
                    <div className="match-details-one">
                        {/* <div className="detail-box">
                  <span className="detail-label">Duration:{duration}</span>
                </div> */}
                        <div className="detail-box gradient-border">
                            {/* <span className="detail-label">
                                <i className="fa fa-clock"></i> DURATION: {subtitle2} HR
                            </span> */}
                            <span className="detail-label">
                                {' '}
                                <i className="fa fa-clock"></i> {subtitle2} HR
                            </span>
                        </div>

                        <div className="detail-box gradient-border">
                        <span className="detail-label">
                        <i class="fa-solid fa-users"></i>  {currentPlayers}/{maxPlayers}
                    </span>
                        </div>
                    </div>
                    <Timer startTime={startTime} endTime={endTime}></Timer>
                    <div className="match-details-two">
                        <div className="detail-box gradient-border">
                            <span className="detail-label">{subtitle1}</span>
                        </div>
                        <div className="detail-box gradient-border">
                            <span className="detail-label">{prizePoolType}</span>
                        </div>
                    </div>
                </div>

                {/* Prize Info */}
                <div className="prize">
                    <span> Prize: {title2} <img
                                              style={{
                                              width:25,
                                             height: 17,
                                             paddingLeft: 5,
                                             paddingBottom: 3,
                                              }}
                                             src={RealCash}
                                            alt="real"
                                            />{' '}</span>
                </div>

                {/* Rank and Players Info */}
                <div className="match-stats">
                    {/* <span className="rank status-label">Rs.{entryFee}</span>
                    <span className="players status-label">
                        {currentPlayers}/{maxPlayers}
                    </span> */}
                    <button
                        className={`custom-button ${ctaEnabledcomp ? '' : 'disabled-btn'}`}
                        // style={{ backgroundColor: '#45F882' }} // Green when enabled
                        // onClick={() => navigate('/login')}
                        onClick={handleEnterClick}
                        disabled={!ctaEnabledcomp}
                    >
                        {ctaTextComp}
                        {/* <img
                        style={{
                           height: 17,
                        //   paddingLeft: 5,
                            paddingBottom: 3,
                         }}
                         src={RealCash}
                                                        alt="real"
                                                    />{' '} */}
                    </button>
                </div>
            </div>
            <GameHandlePopup isOpen={showPopup.status} onClose={closePopup} gameId={showPopup.gameId} />
        </div>
        //   </aside>
        // </div>
    );
};

export default TournamentCardMobile;