import React, { useState, useEffect, useContext } from 'react';
import './ProfileForm.css';
import { AuthContext } from '../../../Utils/Contexts/authContext';
import { fetchWithAuth } from '../../../Utils/ApiServices/apiFunctions';
// import '../../../App.css';
import SimpleHeader3 from '../../Common/SimpleHeader3';
import Footer2 from '../../Common/Footer2';

import { BASE_URL } from '../../../Utils/ApiServices/apiFunctions';
import AlertPopup from '../../Common/AlertPopup';
import BottomNavbar from '../../Common/BottomNavbar';

const ProfileForm = () => {
    const [profilePicture, setProfilePicture] = useState(null);
    const [dob, setDob] = useState('');
    const { accessToken } = useContext(AuthContext);
    const [userData, setUserData] = useState({
        username: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        country: '',
        state: '',
        city: '',
        social_links: {
            facebook: '',
            twitter: '',
            instagram: '',
            youtube: '',
        },
        referral_code: '',
    });
    // const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const [isPopupOpen, setIsPopupOpen] = useState(false);


    const [popupTitle, setPopupTitle] = useState('');
    const [popupMessage, setPopupMessage] = useState('');
    const [popupType, setPopupType] = useState(''); // success, warning, error

    
    // const openPopup = () => setIsPopupOpen(true);
    const closeAlert = () =>{ 
        setIsPopupOpen(false);
        document.body.style.overflow = 'auto';
    };

    const countryId = 101; // Static country ID for India

    // Fetch user profile data when the component mounts
    // useEffect(() => {
    //   fetchUserProfile();
    //   fetchCountries();
    // }, []);

    useEffect(() => {
        fetchUserProfile();
        fetchStates(countryId); // Fetch states for India
    }, []);

    const fetchUserProfile = async () => {
        try {
            const response = await fetchWithAuth(`${BASE_URL}/user/profile`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const data = await response.json();
            console.log('User Profile form data : ', data);

            if (data.code === 200 && data.user_data) {
                const user = data.user_data;
                setUserData({
                    username: user.display_name || '',
                    first_name: user.first_name || '',
                    last_name: user.last_name || '',
                    email: user.email || '',
                    phone: user.phone || '',
                    country: user.country || '',
                    state: user.state || '',
                    city: user.city || '',
                    social_links: user.social_media_handles || {
                        facebook: '',
                        twitter: '',
                        instagram: '',
                        youtube: '',
                    },
                    referral_code: user.referral_code || '',
                });
                setDob(user.dob || '');
                console.log('User Data : ', userData);
                // if (user.country) fetchStates(user.country);
                if (user.state) fetchCities(user.state);
            } else {
                alert('Failed to load profile data');
            }
        } catch (error) {
            console.error('Error fetching profile data:', error);
            alert('An error occurred while fetching profile data');
        }
    };

    // const fetchCountries = async () => {
    //   try {
    //     const response = await fetch(`${BASE_URL}/get_locations`, {
    //       method: 'GET',
    //       headers: {
    //         'Content-Type': 'application/json',
    //         Authorization: `Bearer ${accessToken}`,
    //       }
    //     });
    //     const data = await response.json();
    //     console.log("Countries data: ", data);

    //     if (data.code === 200 && data.countries) {
    //       console.log("Countries data for code 200: ", data.countries);
    //       const countriesData = data.countries.map((country) => ({
    //         id: country.country_id,
    //         name: country.name
    //       }));
    //       setCountries(countriesData);
    //     } else {
    //       // alert('Failed to load countries');
    //     }
    //   } catch (error) {
    //     console.error('Error fetching countries:', error);
    //     alert('An error occurred while fetching countries');
    //   }
    // };

    const fetchStates = async (country_id) => {
        try {
            const response = await fetchWithAuth(`${BASE_URL}/get_locations?country_id=${country_id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const data = await response.json();
            console.log('States data: ', data);

            if (data.code === 200 && data.states) {
                console.log('States data for code 200: ', data.states);
                const statesData = data.states.map((state) => ({
                    id: state.state_id,
                    name: state.name,
                }));
                console.log('y = ', statesData);
                setStates(statesData);
            } else {
                alert('Failed to load states');
            }
        } catch (error) {
            console.error('Error fetching states:', error);
            alert('An error occurred while fetching states');
        }
    };

    const fetchCities = async (state_id) => {
        try {
            const response = await fetchWithAuth(`${BASE_URL}/get_locations?state_id=${state_id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            console.log('Cities data: ', data);

            if (data.code === 200 && data.cities) {
                console.log('Cities data for code 200: ', data.cities);
                const citiesData = data.cities.map((city) => ({
                    id: city.city_id,
                    name: city.name,
                }));
                setCities(citiesData);
            } else {
                alert('Failed to load cities');
            }
        } catch (error) {
            console.error('Error fetching cities:', error);
            alert('An error occurred while fetching cities');
        }
    };

    // const handleCountryChange = (e) => {
    //   const selectedCountry = e.target.value;
    //   setUserData((prevData) => ({
    //     ...prevData,
    //     country: selectedCountry,
    //     state: '', // Reset state and city when country changes
    //     city: '',
    //   }));
    //   fetchStates(selectedCountry);
    // };

    const handleStateChange = (e) => {
        const selectedState = e.target.value;
        setUserData((prevData) => ({
            ...prevData,
            state: selectedState,
            city: '',
        }));
        fetchCities(selectedState);
    };

    const handleCityChange = (e) => {
        const selectedCity = e.target.value;
        setUserData((prevData) => ({
            ...prevData,
            city: selectedCity,
        }));
    };

    const handleRefCodeChange = (e) => {
        const value = e.target.value;
        setUserData((prevData) => ({
            ...prevData,
            referral_code: value,
        }));
    };

    const addReferralCode = async () => {
        if (!accessToken) {
            alert('You must be logged in to add a referral code.');
            return;
        }

        try {
            const response = await fetchWithAuth(`${BASE_URL}/add_referral_code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({ referral_code: userData.referral_code }),
            });

            const result = await response.json();
            console.log('Add Referral Code API result : ', result);
            if (response.ok && result.code === 200) {
                alert('Referral code added successfully');
            } else {
                alert(`Failed to add referral code: ${result.message || 'Unknown error'}`);
            }
        } catch (error) {
            console.error('Error adding referral code:', error);
            alert('An error occurred while adding the referral code');
        }
    };

    const updateEmail = async () => {
        if (!accessToken) {
            alert('You must be logged in to update your profile.');
            return;
        }

        try {
            const response = await fetchWithAuth(`${BASE_URL}/user/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({ email: userData.email }),
            });
            const result = await response.json();
            if (response.ok && result.code === 200) {
                alert('Email updated successfully');
            } else {
                alert(`Failed to update email: ${result.message || 'Unknown error'}`);
            }
        } catch (error) {
            console.error('Error updating email:', error);
            alert('An error occurred while updating email');
        }
    };

    const updatePhone = async () => {
        if (!accessToken) {
            alert('You must be logged in to update your profile.');
            return;
        }

        try {
            const response = await fetchWithAuth(`${BASE_URL}/user/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({ phone: userData.phone }),
            });
            const result = await response.json();
            if (response.ok && result.code === 200) {
                alert('Phone number updated successfully');
            } else {
                alert(`Failed to update phone number: ${result.message || 'Unknown error'}`);
            }
        } catch (error) {
            console.error('Error updating phone number:', error);
            alert('An error occurred while updating phone number');
        }
    };

    // Function to handle file input change
    const handleProfilePictureChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Set the selected file as the profile picture
            setProfilePicture(URL.createObjectURL(file));
        }
    };

    // Function to trigger the hidden file input click
    const handleEditButtonClick = () => {
        document.getElementById('profilePictureInput').click();
    };

    // Function to remove the profile picture
    const handleRemoveProfilePicture = () => {
        setProfilePicture(null);
    };

    // Function to handle changes in DOB
    const handleDobChange = (event) => {
        const selectedDate = event.target.value;
        const currentDate = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

        if (selectedDate > currentDate) {
            alert('Date of Birth cannot be in the future.');
        } else {
            setDob(selectedDate);
        }
    };

    // Function to open the date picker when the calendar icon is clicked
    const handleCalendarIconClick = () => {
        document.getElementById('dobInput').showPicker(); // Using showPicker to open the date picker programmatically
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSocialLinkChange = (e, platform) => {
        const { value } = e.target;
        setUserData((prevData) => ({
            ...prevData,
            social_links: {
                ...prevData.social_links,
                [platform]: value,
            },
        }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (!accessToken) {
            // alert('You must be logged in to update your profile.');
            setPopupTitle('Whoops');
            setPopupMessage('You must be logged in to update your profile.');
            setPopupType('warning');
            // openPopup();
            setIsPopupOpen('true');
            document.body.style.overflow = 'hidden';
            return;
        }

        try {
            const payload = {
                display_name: userData.username,
                first_name: userData.first_name,
                last_name: userData.last_name,
                email: userData.email,
                phone: userData.phone,
                dob,
                // country: userData.country,
                // state: userData.state,
                // city: userData.city,
                social_media_handles: userData.social_links,
            };

            const response = await fetchWithAuth(`${BASE_URL}/user/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(payload),
            });

            const result = await response.json();
            console.log('Form Submit result : ', result);

            // Check the response code
            if (response.ok && result.code === 200) {
                // alert('Profile updated successfully');
                setPopupTitle('Success');
                setPopupMessage('Profile updated successfully');
                setPopupType('success');
                // openPopup();
                setIsPopupOpen('true');
                document.body.style.overflow = 'hidden';
            } else {
                // Show the server-provided error message, if any
                alert(`Failed to update profile: ${result.message || 'Unknown error'}`);
            }
        } catch (error) {
            console.error('Error updating profile:', error);
            // alert('An error occurred while updating profile');
            setPopupTitle('Whoops');
            setPopupMessage('An error occurred while updating profile.');
            setPopupType('warning');
            // openPopup();
            setIsPopupOpen('true');
            document.body.style.overflow = 'hidden';
        }
    };

    return (
        <>
            <SimpleHeader3 />
            {isPopupOpen && (
                <AlertPopup
                    title={popupTitle}
                    message={popupMessage}
                    action="Ok"
                    onClose={closeAlert}
                    type={popupType} 
                    
                />
            )}
            <div style={{ backgroundColor: 'black', marginTop: '150px', marginBottom: '100px' }}>
                <div className="profile-form-container">
                    <div className="profile-header">
                        <div className="profile-picture">
                            {profilePicture ? (
                                <img src={profilePicture} alt="Profile" className="profile-img" />
                            ) : (
                                <i className="fa fa-user-circle"></i>
                            )}
                            <button className="edit-button" onClick={handleEditButtonClick}>
                                <i className="fa fa-pencil-alt"></i>
                            </button>
                            {profilePicture && (
                                <button className="remove-button" onClick={handleRemoveProfilePicture}>
                                    <i className="fa fa-times"></i>
                                </button>
                            )}
                            <input
                                type="file"
                                id="profilePictureInput"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleProfilePictureChange}
                            />
                        </div>
                        <div className="profile-bio">
                            <h2>Who am I</h2>
                            <textarea placeholder="Bio" name="bio" onChange={handleChange}></textarea>
                        </div>
                    </div>

                   

                    <form className="profile-details" onSubmit={handleFormSubmit}>
                        <div className="form-group">
                            <label>Username</label>
                            <input type="text" name="username" value={userData.username} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>Full Name</label>
                            <input
                                type="text"
                                name="first_name"
                                value={userData.first_name}
                                onChange={handleChange}
                                placeholder="First Name"
                            />
                            <input
                                type="text"
                                name="last_name"
                                value={userData.last_name}
                                onChange={handleChange}
                                placeholder="Last Name"
                            />
                        </div>
                        <div className="form-group dob-group">
                            <label>Date Of Birth</label>
                            <div className="dob-container">
                                <input
                                    type="date"
                                    id="dobInput"
                                    value={dob}
                                    onChange={handleDobChange}
                                     className="dob-input"
                                />
                                <button type="button" className="calendar-icon" onClick={handleCalendarIconClick}>
                                    <i className="fa fa-calendar"></i>
                                </button>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input type="email" name="email" value={userData.email} onChange={handleChange} />
                            <button type="button" onClick={updateEmail}>
                                Update
                            </button>
                        </div>
                        <div className="form-group">
                            <label>Mobile Number</label>
                            <input
                                type="text"
                                name="phone"
                                value={userData.phone}
                                onChange={handleChange}
                                placeholder="Mobile Number"
                            />
                            <button type="button" onClick={updatePhone}>
                                Update
                            </button>
                        </div>
                        <div className="form-group">
                            <label>Gender</label>
                            <select>
                                <option>Male</option>
                                <option>Female</option>
                                <option>Other</option>
                            </select>
                        </div>
                        {/* <div className="form-group">
          <label>Country</label>
          <select value={userData.country} onChange={handleCountryChange}>
            <option value="">Select Country</option>
            {countries.map((country) => (
              <option key={country.id} value={country.id}>{country.name}</option>
            ))}
          </select>
        </div> */}
                        <div className="form-group">
                            <label>State</label>
                            <select value={userData.state} onChange={handleStateChange}>
                                <option value="">Select State</option>
                                {states.map((state) => (
                                    <option key={state.id} value={state.id}>
                                        {state.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>City</label>
                            <select value={userData.city} onChange={handleCityChange}>
                                <option value="">Select City</option>
                                {cities.map((city) => (
                                    <option key={city.id} value={city.id}>
                                        {city.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <h3>Social Links</h3>
                        <div className="social-links">
                            <input
                                type="text"
                                placeholder="Enter Facebook Link"
                                value={userData.social_links.facebook}
                                onChange={(e) => handleSocialLinkChange(e, 'facebook')}
                            />
                            <input
                                type="text"
                                placeholder="Enter Twitter Link"
                                value={userData.social_links.twitter}
                                onChange={(e) => handleSocialLinkChange(e, 'twitter')}
                            />
                            <input
                                type="text"
                                placeholder="Enter Instagram Link"
                                value={userData.social_links.instagram}
                                onChange={(e) => handleSocialLinkChange(e, 'instagram')}
                            />
                            <input
                                type="text"
                                placeholder="Enter YouTube Link"
                                value={userData.social_links.youtube}
                                onChange={(e) => handleSocialLinkChange(e, 'youtube')}
                            />
                        </div>

                        <h3>Add Referral Code</h3>
                        <div className="form-group">
                            <label>Referral Code</label>
                            <input
                                type="text"
                                name="referral-code"
                                value={userData.referral_code}
                                onChange={handleRefCodeChange}
                                placeholder="Referral Code"
                            />
                            <button type="button" onClick={addReferralCode}>
                                Submit
                            </button>
                        </div>
                        <button type="submit" className="update-button">
                            Update Profile
                        </button>
                    </form>
                </div>
            </div>
            <Footer2 />
            <BottomNavbar />
        </>
    );
};

export default ProfileForm;