import React, { useState, useEffect } from 'react';
import '../../App.css';
import '../../CustomCss/customStyles.css';
import '../../Assets/sass/style.scss';
import $ from 'jquery'; // Import jQuery
import Swiper from 'swiper';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import 'swiper/css'; // Import base CSS
import 'swiper/css/navigation'; // Import navigation CSS if used
import 'swiper/css/pagination'; // Import pagination CSS if used
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../Utils/ApiServices/apiFunctions';
import FeatureCardBg from '../../Assets/img/feature/feature-card-bg.png';
import Feature1_1 from '../../Assets/img/feature/feature1-1.png';
import Feature1_2 from '../../Assets/img/feature/feature1-2.png';
import Feature1_3 from '../../Assets/img/feature/feature1-3.png';
import ConnectGameAccount from '../GamePageComp/connectGameAccount';

const ProGameCard4 = ({
    gameId,
    featureCardBg,
    featureImage,
    gameTitle,
    gameDescription,
    gameDetailsLink,
    playNowLink,
    iconUrl,
    handleGameClick,
}) => {
    return (
        <div
            class="th-product custom-game-container product-grid"
            style={{ backgroundColor: 'black' }}
        >
            <div
                class="product-img custom-game-image"
                style={{ position: 'relative' }}
            >
                <Link>
                    <img src={featureImage} alt="game-image" />
                </Link>
            </div>
            <div className="game-card-details">
                <h3 className="box-title" style={{ marginTop: '0px' }}>
                    <Link onClick={() => handleGameClick(gameId)}>
                        {gameTitle}
                    </Link>
                </h3>
            </div>
        </div>
    );
};

export default ProGameCard4;
