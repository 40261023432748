import React from 'react'
import MainHeader2 from '../../Components/Common/MainHeader2'
import Rewards from '../../Components/UserProfilePageComp/Rewards/Rewards'
import Cursor from '../../Components/Common/Cursor'
import Preloader from '../../Components/Common/Preloader'
import Footer2 from '../../Components/Common/Footer2'
import ScrollToTop from '../../Components/Common/ScrollToTop'
import ProfileForm from '../../Components/UserProfilePageComp/Form/ProfileForm'
import SimpleHeader3 from '../../Components/Common/SimpleHeader3'
import BottomNavbar from '../../Components/Common/BottomNavbar'

const ProUserProfileFormPage = () => {
  return (
    <>
      <Cursor/>
      <Preloader/>
      <SimpleHeader3/> 
      <ProfileForm/>
      <Footer2/>
      <ScrollToTop/> 
      <BottomNavbar />
    </>
  )
}

export default ProUserProfileFormPage
