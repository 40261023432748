 import React, { useEffect, useState, useContext } from 'react';
import '../../App.css';
import { Link, useParams } from 'react-router-dom';
import { AuthContext } from '../../Utils/Contexts/authContext';
import $ from 'jquery'; // Import jQuery
// import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Waypoint from 'waypoints/lib/noframework.waypoints';
import SmoothScroll from 'smooth-scroll';
import WOW from 'wowjs';
import { gsap } from 'gsap';
import CardType6 from '../Common/CardType6';
import GameVS1 from '../../Assets/img/tournament/game-vs1.svg';
import TournamentCard2Bg from '../../Assets/img/bg/tournament-card2-bg.png';
import TournamentSec2Bg from '../../Assets/img/bg/tournament-sec2-bg.png';
import Tournamnet1_1 from '../../Assets/img/tournament/1-1.png';
import Tournamnet1_2 from '../../Assets/img/tournament/1-2.png';
import Tournamnet1_3 from '../../Assets/img/tournament/1-3.png';
import Tournamnet1_4 from '../../Assets/img/tournament/1-4.png';
import Tournamnet1_5 from '../../Assets/img/tournament/1-5.png';
import Tournamnet1_6 from '../../Assets/img/tournament/1-6.png';
import Tournamnet1_7 from '../../Assets/img/tournament/1-7.png';
import Tournamnet1_8 from '../../Assets/img/tournament/1-8.png';
import Tournamnet1_9 from '../../Assets/img/tournament/1-9.png';
import Tournamnet1_10 from '../../Assets/img/tournament/1-10.png';
import Tournamnet1_11 from '../../Assets/img/tournament/1-11.png';
import Tournamnet1_12 from '../../Assets/img/tournament/1-12.png';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import Card2 from '../Common/Card2';
import Card3 from '../Common/Card3';
import ProTournamentCard7 from '../ProtectedComponents/proTournamentCard7';
import { fetchWithAuth } from '../../Utils/ApiServices/apiFunctions';
import { BASE_URL } from '../../Utils/ApiServices/apiFunctions';

window.jQuery = $;
require('isotope-layout/dist/isotope.pkgd.js');

imagesLoaded.makeJQueryPlugin($);

const ProTournamentsList1 = () => {
    const { gameId } = useParams();
    const { accessToken } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [tournaments, setTournaments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // Current page number
    const [totalPages, setTotalPages] = useState(1); // Total pages from API response
    const [pageSize, setPageSize] = useState(10); // Number of tournaments per page
    const [selectedFilter, setSelectedFilter] = useState('upcoming'); // Default filter is 'upcoming'
    const [activeFilter, setActiveFilter] = useState('upcoming'); // Track the active filter button

    // Function to map game names to folder names
    const mapGameToFolder = (gameName) => {
        switch (gameName) {
            case 'Clash Royale':
                return 'Clash Royale';
            case 'Clash of Clans':
                return 'Clash of clans';
            case 'Chess':
                return 'Chess';
            default:
                return 'default'; // Fallback folder
        }
    };

    // Updated getImage function to handle different game folders
    const getImage = (gameName, iconName) => {
        const folder = mapGameToFolder(gameName); // Get the folder based on the game name
        try {
            return require(`../../Assets/img/tournament/${folder}/${iconName}`);
        } catch (err) {
            console.error(`Image ${iconName} not found in ${folder}, using default.`);
            return require('../../Assets/img/tournament/Chess/1_1.png'); // Default fallback image
        }
    };

    useEffect(() => {
        const fetchTournaments = async () => {
            setIsLoading(true);
            try {
                let url = '';
                if (selectedFilter === 'my-tournaments') {
                    // Fetch user-registered tournaments
                    url = `${BASE_URL}/user/tournaments?status=all&page_size=${pageSize}&page=${currentPage}`;
                } else {
                    // Fetch general tournaments based on selected filter
                    const topTournaments = true;
                    url = `${BASE_URL}/tournaments?landing=${topTournaments}&status=${selectedFilter}&page_size=${pageSize}&page=${currentPage}`;
                }

                const response = await fetchWithAuth(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`Failed to fetch tournaments: ${response.status} ${response.statusText}`);
                }

                const data = await response.json();
                if (Array.isArray(data.tournaments)) {
                    setTournaments(data.tournaments); // Set the array of tournaments
                    setTotalPages(data.total_pages);
                } else {
                    setTournaments([]); // If not an array, set it to an empty array
                    throw new Error('Tournaments data is not in the expected format.');
                }
            } catch (e) {
                setError(e.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTournaments();
    }, [gameId, selectedFilter, currentPage, pageSize, accessToken]);

    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
        setActiveFilter(filter);
        setCurrentPage(1); // Reset to page 1 when the filter changes
    };

    if (isLoading) {
        return <div>Loading.....</div>;
    }

    if (error) {
        return <div>Something went wrong! Please try again later..</div>;
    }

    return (
        <>
            <section className="tournament-sec-v2 space-top space-extra2-bottom" data-bg-src={TournamentSec2Bg}>
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-12">
                            <div className="title-area text-center">
                                <span className="sub-title style2"># Agon Battles </span>
                                <h2 className="sec-title">
                                    Our Tournaments <span className="text-theme">!</span>
                                </h2>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="tournament-filter-btn2 filter-menu filter-menu-active">
                                <button
                                    className={`tab-btn th-btn style-border3 ${
                                        activeFilter === 'upcoming' ? 'active' : ''
                                    }`}
                                    type="button"
                                    onClick={() => handleFilterChange('upcoming')}
                                >
                                    <span className="btn-border">
                                        UPCOMING <i className="fa-solid fa-arrow-right ms-2"></i>
                                    </span>
                                </button>

                                <button
                                    className={`tab-btn th-btn style-border3 ${
                                        activeFilter === 'live' ? 'active' : ''
                                    }`}
                                    type="button"
                                    onClick={() => handleFilterChange('live')}
                                >
                                    <span className="btn-border">
                                        LIVE <i className="fa-solid fa-arrow-right ms-2"></i>
                                    </span>
                                </button>

                                {/* <button className={`tab-btn th-btn style-border3 ${activeFilter === 'previous' ? 'active' : ''}`}  
                  type="button"  
                  onClick={() => handleFilterChange('previous')}>
                  <span className="btn-border">PREVIOUS <i className="fa-solid fa-arrow-right ms-2"></i></span>
                </button> */}

                                <button
                                    className={`tab-btn th-btn style-border3 ${
                                        activeFilter === 'my-tournaments' ? 'active' : ''
                                    }`}
                                    type="button"
                                    onClick={() => handleFilterChange('my-tournaments')}
                                >
                                    <span className="btn-border">
                                        MY TOURNAMENTS<i className="fa-solid fa-arrow-right ms-2"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* loop through the tournaments , check the game , generate random number , place the corresponding image */}

                    <div className="row gy-40 filter-active">
                        {tournaments.length === 0 ? <div>No tournaments available at the moment.</div> : null}
                        {tournaments.map((tournament, index) => (
                            <ProTournamentCard7
                                key={tournament.tournament_id}
                                gameId={tournament.game_id}
                                tournamentId={tournament.tournament_id}
                                tournamentImage={getImage(tournament.name, tournament.image_icon)}
                                gameVSImg={GameVS1}
                                cardBg={TournamentCard2Bg}
                                subtitle1={tournament.mode}
                                title1={tournament.name}
                                subtitle2={tournament.duration}
                                title2={tournament.prize_pool}
                                endTime={tournament.end_time}
                                startTime={tournament.start_time}
                                // time={new Date(tournament.start_time * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' })}
                                // date={new Date(tournament.start_time * 1000).toLocaleDateString()}
                                status={tournament.status}
                                currentPlayers={tournament.current_players}
                                maxPlayers={tournament.max_players}
                                detailsLink={`/proTournament-details/${tournament.game_id}/${tournament.tournament_id}`}
                                ctaText={tournament.cta_text}
                                ctaEnabled={tournament.cta_enabled}
                                entryFee={tournament.entry_fee}
                                userRegistrationAllowed={tournament.user_registration_allowed}
                            />
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
};

export default ProTournamentsList1;
