import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../App.css';
import '../../Assets/sass/scssImport.scss';
import { Link } from 'react-router-dom';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Waypoint from 'waypoints/lib/noframework.waypoints';
import SmoothScroll from 'smooth-scroll';
import WOW from 'wowjs';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import { gsap } from 'gsap';
import Swiper from 'swiper';
import 'swiper/css'; // Import base CSS
import 'swiper/css/navigation'; // Import navigation CSS if used
import 'swiper/css/pagination'; // Import pagination CSS if used
import FeatureCardBg from '../../Assets/img/feature/clash_royale.jpg';
import Feature1_1 from '../../Assets/img/feature/feature1-1.png';
import Feature1_2 from '../../Assets/img/feature/feature1-2.png';
import Feature1_3 from '../../Assets/img/feature/feature1-3.png';
import Card1 from '../Common/Card1';
import { fetchWithAuth } from '../../Utils/ApiServices/apiFunctions';

import { BASE_URL } from '../../Utils/ApiServices/apiFunctions';

const GameArea = ({callbackHandleClick}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [games, setGames] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchGames = async () => {
            setIsLoading(true);
            try {
                const response = await fetchWithAuth(`${BASE_URL}/games`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                // Duplicate the games array - Can be removed when more new games are added
                // const duplicatedGames = [...data.games, ...data.games];
                setGames(data.games);
                // setGames(data.games || []); Uncomment this after removing duplicate games
            } catch (e) {
                setError(e.message);
            } finally {
                setIsLoading(false);
            }
        };
        fetchGames();
    }, []);

    useEffect(() => {
        // Initialize Swiper slider
        const gameSwiper = new Swiper('.game-slider', {
            slidesPerView: '1.5',
            spaceBetween: 24,
            loop: true,
            speed: 1000,
            autoplay: { delay: 6000, disableOnInteraction: false },
            navigation: {
                nextEl: '.game-slider-next',
                prevEl: '.game-slider-prev',
            },
            pagination: {
                el: '.game-slider-pagination',
                clickable: true,
                renderBullet: function (index, className) {
                    return `<span class="${className}" aria-label="Go to Slide ${index + 1}"></span>`;
                },
            },
            breakpoints: {
                576: { slidesPerView: 1 },
                768: { slidesPerView: 2 },
                992: { slidesPerView: 3 },
                1200: { slidesPerView: 3 },
            },
        });

        // Add animations
        $('[data-ani]').each(function () {
            const animationName = $(this).data('ani');
            $(this).addClass(animationName);
        });

        $('[data-ani-delay]').each(function () {
            const delayTime = $(this).data('ani-delay');
            $(this).css('animation-delay', delayTime);
        });

        // External navigation handlers
        $('[data-slider-prev], [data-slider-next]').on('click', function () {
            const sliderSelector = $(this).data('slider-prev') || $(this).data('slider-next');
            const targetSlider = $(sliderSelector);

            if (targetSlider.length) {
                const swiperInstance = targetSlider[0].swiper;
                if (swiperInstance) {
                    if ($(this).data('slider-prev')) {
                        swiperInstance.slidePrev();
                    } else {
                        swiperInstance.slideNext();
                    }
                }
            }
        });

        // return () => {
        //   // Cleanup Swiper instance if necessary
        //   if (swiper) swiper.destroy();
        // };
    }, [games]); // Re-run slider initialization when games change

    // const handleGameClick = (gameId) => {
    //     console.log('Navigating to game ID', gameId);
    //     navigate(`/games`, { state: { gameId } }); // Navigate to GamesPage with the selected game_id
    // };
    const handleGameClick = (gameId) => {
        console.log('Navigating to game ID', gameId);
        callbackHandleClick(gameId)
        // navigate(`/games`, { state: { gameId } }); // Navigate to GamesPage with the selected game_id
    };

    if (isLoading) {
        return <div>Loading.....</div>;
    }

    if (error) {
        return <div>Something went wrong! Please try again later..</div>;
    }

    // Variations for featureImage and gameDescription
    const featureImages = [Feature1_1, Feature1_3, Feature1_2];
    const gameDescriptions = [
        'Successful esports teams exhibit strong communication, strategic.',
        'The rise of mobile gaming has significantly expanded the gaming.',
        'It has also influenced game design reaching a broader demographic.',
    ];

    return (
        <>
            <section
                className="feature-game-area-1 bg-black"
                style={{ backgroundColor: 'black', paddingBottom: '30px',paddingTop:'100px' }}
            >
                <div className="container">
                    <div className="slider-area feature-game-slider1">
                        <div
                            className="swiper game-slider"
                            id="featureGameSlider1"
                            data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"1"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"3"},"1200":{"slidesPerView":"3"}}}'
                        >
                            <span className="sub-title" style={{ marginTop: '30px', textAlign: 'center' }}>
                                # Epic Clash
                            </span>
                            <h2 className="sec-title" style={{ textAlign: 'center', fontWeight: '700' }}>
                                Game On, Level Up, Win Big <span className="text-theme">!</span>
                            </h2>
                            <div className="swiper-wrapper">
                                {games.map((game, index) => (
                                    <div
                                        className="swiper-slide"
                                        key={game.game_id}
                                        onClick={() => handleGameClick(game.game_id)}
                                    >
                                        <Card1
                                            gameId={game.game_id}
                                            // featureCardBg={FeatureCardBg}
                                            featureImage={featureImages[index % featureImages.length]} // Cycle through images
                                            gameTitle={game.name}
                                            gameDescription={gameDescriptions[index % gameDescriptions.length]} // Cycle through descriptions
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className="swiper-pagination slider-pagination"></div>
                        </div>
                        <button
                            data-slider-prev="#featureGameSlider1"
                            className="slider-arrow style3 slider-prev"
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            <i className="fas fa-angle-left"></i>
                        </button>
                        <button
                            data-slider-next="#featureGameSlider1"
                            className="slider-arrow style3 slider-next"
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            <i className="fas fa-angle-right"></i>
                        </button>
                    </div>
                </div>
            </section>
        </>
    );
};

export default GameArea;
