import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import '../../CustomCss/customStyles.css';
import { BASE_URL } from "../../Utils/ApiServices/apiFunctions"; // API Base URL

const Quests = () => {
  const [quests, setQuests] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate(); // React Router hook for navigation

  useEffect(() => {
    const fetchQuestData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${BASE_URL}/quests`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          if (response.status === 404) {
            setError("No quests found.");
          } else {
            const result = await response.json();
            setError(result.message || "Failed to fetch quests.");
          }
        } else {
          const result = await response.json();
          setQuests(result || []);
        }
      } catch (e) {
        setError("An error occurred while fetching quests.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchQuestData();
  }, []);

  const handleCardClick = (questId) => {
    navigate(`/quests/${questId}`); // Navigate to the detail page for the selected quest
  };

  const renderQuestCards = () => {
    if (quests.length === 0) {
      return <div className="central-message">No quests available.</div>;
    }

    return quests.map((quest) => (
      <div
        key={quest.id}
        className="quest-card"
        onClick={() => handleCardClick(quest.id)} // Handle card click
      >
        <img
          src={quest.image || "https://placehold.co/600x400?text=Quest"}
          alt={quest.title}
          className="quest-card-image"
        />
        <div className="quest-card-content">
          <h3>{quest.title}</h3>
          <p>
            <i className="fas fa-clock"></i> {quest.timeLeft || "N/A"} left
          </p>
          <p>
            <i className="fas fa-users"></i> Slots: {quest.slots || "N/A"}
          </p>
          <button className="action-button">View Details</button>
        </div>
      </div>
    ));
  };

  if (isLoading) return <div className="central-message">Loading quests...</div>;
  if (error) return <div className="central-message error-message">{error}</div>;

  return (
    <section className="quests-container">
      <h1 className="page-heading">Available Quests</h1>
      <div className="quest-grid">{renderQuestCards()}</div>
    </section>
  );
};

export default Quests;
