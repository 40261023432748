import React, { useEffect, useState } from 'react';
import '../../App.css';
import '../../Assets/sass/scssImport.scss';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Swiper from 'swiper';
import 'swiper/css'; // Import base CSS
import 'swiper/css/navigation'; // Import navigation CSS if used
import 'swiper/css/pagination'; // Import pagination CSS if used

import { BASE_URL, fetchWithAuth } from '../../Utils/ApiServices/apiFunctions';
import GameHandlePopup from '../Common/GameHandlePopup';

const ProGameArea1 = ({ gameHandles, callbackHandleClick }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [games, setGames] = useState([]);
    const [showPopup, setShowPopup] = useState({ status: false, gameId: '' });

    const closePopup = () => {
        setShowPopup(false);
    };

    useEffect(() => {
        const fetchGames = async () => {
            setIsLoading(true);
            try {
                const response = await fetchWithAuth(`${BASE_URL}/games`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                setGames(data.games);
            } catch (e) {
                setError(e.message);
            } finally {
                setIsLoading(false);
            }
        };
        fetchGames();
    }, []);

    useEffect(() => {
        // Initialize Swiper slider
        const gameSwiper = new Swiper('.game-slider', {
            slidesPerView: '1',
            spaceBetween: 24,
            loop: true,
            speed: 1000,
            autoplay: { delay: 6000, disableOnInteraction: false },
            navigation: {
                nextEl: '.game-slider-next',
                prevEl: '.game-slider-prev',
            },
            pagination: {
                el: '.game-slider-pagination',
                clickable: true,
                renderBullet: function (index, className) {
                    return `<span class="${className}" aria-label="Go to Slide ${index + 1}"></span>`;
                },
            },
            breakpoints: {
                576: { slidesPerView: 1 },
                768: { slidesPerView: 2 },
                992: { slidesPerView: 3 },
                1200: { slidesPerView: 3 },
            },
        });

        // Add animations
        $('[data-ani]').each(function () {
            const animationName = $(this).data('ani');
            $(this).addClass(animationName);
        });

        $('[data-ani-delay]').each(function () {
            const delayTime = $(this).data('ani-delay');
            $(this).css('animation-delay', delayTime);
        });

        // External navigation handlers
        $('[data-slider-prev], [data-slider-next]').on('click', function () {
            const sliderSelector = $(this).data('slider-prev') || $(this).data('slider-next');
            const targetSlider = $(sliderSelector);

            if (targetSlider.length) {
                const swiperInstance = targetSlider[0].swiper;
                if (swiperInstance) {
                    if ($(this).data('slider-prev')) {
                        swiperInstance.slidePrev();
                    } else {
                        swiperInstance.slideNext();
                    }
                }
            }
        });

        // return () => {
        //   // Cleanup Swiper instance if necessary
        //   if (swiper) swiper.destroy();
        // };
    }, [games]);

    const handleGameClick = (gameId) => {
        console.log('EVENT to game ID', gameId);
        callbackHandleClick(gameId);
    };
    if (isLoading) {
        return <div>Loading.....</div>;
    }

    if (error) {
        return <div>Something went wrong! Please try again later..</div>;
    }

    return (
        <>
            <section
                className="feature-game-area-1 bg-black"
                style={{ backgroundColor: 'black', marginTop: '120px', marginBottom: '0px' }}
            >
                <div className="container">
                    <div>
                        <div>
                            <select onChange={(event) => handleGameClick(event.target.value)}>
                                <option value="">Select All Games</option>
                                {games.map((game, index) => (
                                    <option key={game.game_id} value={game.game_id}>
                                        <div>{game.name}</div>
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <GameHandlePopup isOpen={showPopup.status} onClose={closePopup} gameId={showPopup.gameId} />
                </div>
            </section>
        </>
    );
};

export default ProGameArea1;
