import React from 'react';
import LoginForm from '../../Components/LoginPageComp/loginForm';

const LoginPage = () => {

  return (
    <>
      <LoginForm />
    </>
  );
};

export default LoginPage;