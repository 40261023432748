import React , {useEffect} from 'react';
import '../../App.css';
import '../../Assets/sass/scssImport.scss';
// import { Link } from 'react-router-dom';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Waypoint from 'waypoints/lib/noframework.waypoints';
import SmoothScroll from 'smooth-scroll';
import WOW from 'wowjs';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import { gsap } from 'gsap'; 
// import CardType6 from '../Common/CardType6';
import JijiBg from '../../Assets/img/bg/jiji-bg.png';
import CtaBgShape1 from '../../Assets/img/shape/cta-bg-shape1.svg';
import CtaSec1Bg from '../../Assets/img/bg/cta-sec1-bg.png';
import Cta1_1 from '../../Assets/img/normal/cta1-1.png';

const JoinUs = () => {

    useEffect(() => {
        // Apply background images from data-bg-src attribute
        if ($("[data-bg-src]").length > 0) {
          $("[data-bg-src]").each(function () {
            var src = $(this).attr("data-bg-src");
            $(this).css("background-image", "url(" + src + ")");
            $(this).removeAttr("data-bg-src").addClass("background-image");
          });
        }
    
        // Apply background colors from data-bg-color attribute
        if ($('[data-bg-color]').length > 0) {
          $('[data-bg-color]').each(function () {
            var color = $(this).attr('data-bg-color');
            $(this).css('background-color', color);
            $(this).removeAttr('data-bg-color');
          });
        }
    
        // Apply border color from data-border attribute
        $('[data-border]').each(function() {
          var borderColor = $(this).data('border');
          $(this).css('--th-border-color', borderColor);
        });
    
        // Apply mask images from data-mask-src attribute
        if ($('[data-mask-src]').length > 0) {
          $('[data-mask-src]').each(function () {
            var mask = $(this).attr('data-mask-src');
            $(this).css({
              'mask-image': 'url(' + mask + ')',
              '-webkit-mask-image': 'url(' + mask + ')'
            });
            $(this).addClass('bg-mask');
            $(this).removeAttr('data-mask-src');
          });
        };
    
      }, []); // Run once when the component mounts

  return (
    <>
        {/* <section style={{backgroundColor:"black"}}>
            <div className="container th-container4">
                <div className="cta-area-1">
                    <div className="cta-bg-shape-border">
                        <svg width="1464" height="564" viewBox="0 0 1464 564" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1463.5 30V534C1463.5 550.292 1450.29 563.5 1434 563.5H1098H927.426C919.603 563.5 912.099 560.392 906.567 554.86L884.14 532.433C878.42 526.713 870.663 523.5 862.574 523.5H601.426C593.337 523.5 585.58 526.713 579.86 532.433L557.433 554.86C551.901 560.392 544.397 563.5 536.574 563.5H366H30C13.7076 563.5 0.5 550.292 0.5 534V30C0.5 13.7076 13.7076 0.5 30 0.5H366H536.574C544.397 0.5 551.901 3.60802 557.433 9.14034L579.86 31.5668C585.58 37.2866 593.337 40.5 601.426 40.5H862.574C870.663 40.5 878.42 37.2866 884.14 31.5668L906.567 9.14035C912.099 3.60803 919.603 0.5 927.426 0.5H1098H1434C1450.29 0.5 1463.5 13.7076 1463.5 30Z" stroke="url(#paint0_linear_202_547)" />
                            <defs>
                                <linearGradient id="paint0_linear_202_547" x1="0" y1="0" x2="1505.47" y2="412.762" gradientUnits="userSpaceOnUse">
                                    <stop offset="0" stop-color="var(--theme-color)" />
                                    <stop offset="1" stop-color="var(--theme-color2)" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className="cta-wrap-bg bg-repeat" data-bg-src={JijiBg} data-mask-src={CtaBgShape1}>
                        <div className="cta-bg-img">
                            <img src={CtaSec1Bg} alt="img"/>
                        </div>
                        <div className="cta-thumb">
                            <img src={Cta1_1} alt="img"/>
                        </div>
                    </div>
                    <div className="cta-wrap">
                        <div className="row">
                            <div className="col-xl-5">
                                <div className="title-area mb-0 custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                    <span className="sub-title">#JoinAgonGames</span>
                                    <h2 className="sec-title" style={{textAlign:"left"}}>Join AgonGames To Become the <span className="text-theme fw-medium">Next Gaming Champion!</span></h2>
                                    <p className="mt-30 mb-30">Join our vibrant Discord gaming community to connect with like-minded players, compete in exclusive tournaments, earn rewards, and stay updated on the latest gaming trends—all in one place!</p>
                                    <a href="https://www.discord.com/" className="th-btn">JOIN COMMUNITY CTA<i className="fa-solid fa-arrow-right ms-2"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        <div class="container th-container4">
        <div class="cta-area-1">
            <div class="cta-bg-shape-border">
                <svg width="1464" height="564" viewBox="0 0 1464 564" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1463.5 30V534C1463.5 550.292 1450.29 563.5 1434 563.5H1098H927.426C919.603 563.5 912.099 560.392 906.567 554.86L884.14 532.433C878.42 526.713 870.663 523.5 862.574 523.5H601.426C593.337 523.5 585.58 526.713 579.86 532.433L557.433 554.86C551.901 560.392 544.397 563.5 536.574 563.5H366H30C13.7076 563.5 0.5 550.292 0.5 534V30C0.5 13.7076 13.7076 0.5 30 0.5H366H536.574C544.397 0.5 551.901 3.60802 557.433 9.14034L579.86 31.5668C585.58 37.2866 593.337 40.5 601.426 40.5H862.574C870.663 40.5 878.42 37.2866 884.14 31.5668L906.567 9.14035C912.099 3.60803 919.603 0.5 927.426 0.5H1098H1434C1450.29 0.5 1463.5 13.7076 1463.5 30Z" stroke="url(#paint0_linear_202_547)" />
                    <defs>
                        <linearGradient id="paint0_linear_202_547" x1="0" y1="0" x2="1505.47" y2="412.762" gradientUnits="userSpaceOnUse">
                            <stop offset="0" stop-color="var(--theme-color)" />
                            <stop offset="1" stop-color="var(--theme-color2)" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <div class="cta-wrap-bg bg-repeat" data-bg-src={JijiBg} data-mask-src={CtaBgShape1}>
                <div class="cta-bg-img">
                    <img src={CtaSec1Bg} alt="img"/>
                </div>
                <div class="cta-thumb">
                    <img src={Cta1_1} alt="img"/>
                </div>
            </div>
            <div class="cta-wrap">
                <div class="row">
                    <div class="col-xl-5">
                        <div class="title-area mb-0 custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                            <span class="sub-title"># Join AgonGames</span>
                            <h2 class="sec-title">Join AgonGames To Become the <span class="text-theme fw-medium">Next Gaming Champion!</span></h2>
                            <p class="mt-30 mb-30">Join our vibrant Discord gaming community to connect with like-minded players, compete in exclusive tournaments, earn real rewards, and stay updated on the latest gaming trends—all in one place!</p>
                            <a href="https://discord.gg/VagU5uMCnW" class="th-btn" target="_blank" rel="noopener noreferrer">JOIN COMMUNITY <i class="fa-solid fa-arrow-right ms-2"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default JoinUs
