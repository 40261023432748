import React, { useState, useEffect } from 'react';
import '../../CustomCss/customStyles.css';
import './popUp.css';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap styles
import '@fortawesome/fontawesome-free/css/all.min.css'; // FontAwesome icons
import { useNavigate } from 'react-router-dom';

const LoginPopUp = () => {
    const [showPopup, setShowPopup] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Show popup after a 7-second delay
        const popupDisplayed = sessionStorage.getItem('popupDisplayed');
        if (!popupDisplayed) {
        
        const timer = setTimeout(() => {
            if(localStorage.getItem("new_user")==true ){
            setShowPopup(true);
            sessionStorage.setItem('popupDisplayed', 'true'); // Set the flag
            document.body.classList.add('lock-scroll'); 
            }
        }, 7000);

        // Cleanup timer and scroll lock on unmount
        return () => {
            clearTimeout(timer);
            document.body.classList.remove('lock-scroll'); // Remove scroll lock
        };
    }
    }, []);

    

    const closePopup = () => {
        setShowPopup(false);
        document.body.classList.remove('lock-scroll'); // Unlock scroll
    };

    return (
        <>
            {/* Blur main content when popup is active */}
            <div className={showPopup ? 'blur-background main-content' : 'main-content'}>
                <header className="header">
                    {/* Add your header content */}
                    <h1>Agon Games</h1>
                </header>
                <main className="content">
                    {/* Main page content */}
                    <p>Your main landing page content goes here...</p>
                </main>
            </div>

{showPopup && (
    <div className="login-popup-overlay" onClick={closePopup}>
        <div
            className="login-popup"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the popup
        >
            <button className="close-btn" onClick={closePopup}>
                ×
            </button>
            <h4 className="login-header">Welcome to AgonGames</h4>
            <p className="login-pop-up-text">
                Start earning for your in-game skills. Kickstart with <strong>50 Acoins</strong> from us.
            </p>
            <div className="login-hiw-section">
                <div className="login-hiw-card">
                    <div className="hiw-card-icon">
                        <i className="fa fa-swords"></i>
                    </div>
                    <div className="login-hiw-content">
                        <h3 className="login-cardText1">Join a tournament</h3>
                        <p className="login-cardText">
                            Start earning by winning tournaments. First 5 tournaments are free to join.
                        </p>
                    </div>
                </div>
                <div className="login-hiw-card">
                    <div className="hiw-card-icon">
                        <i className="fa fa-dollar-sign"></i>
                    </div>
                    <div className="login-hiw-content">
                        <h3 className="login-cardText1">Great Rewards</h3>
                        <p className="login-cardText">Discover what you have won and how to win more.</p>
                    </div>
                </div>
                <div className="login-hiw-card">
                    <div className="hiw-card-icon">
                        <i className="fa fa-user"></i>
                    </div>
                    <div className="login-hiw-content">
                        <h3 className="login-cardText1">Complete Profile</h3>
                        <p className="login-cardText">
                            Complete your profile so that other gamers can connect with you easily.
                        </p>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button
                    className="start-btn"
                    onClick={() =>
                        navigate(`/progames`, {
                            state: {
                                from: window.location.pathname,
                            },
                        })
                    }
                >
                    Let's game
                    <span className="icon">
                        <i className="fa-solid fa-arrow-right ms-3"></i>
                    </span>
                </button>
            </div>
        </div>
    </div>
)}

        </>
    );
};

export default LoginPopUp;  