// timerUtils.js

// Helper function to format time based on remaining time
export const formatTime = (ms) => {
  const days = Math.floor(ms / (1000 * 60 * 60 * 24));
  const hours = Math.floor((ms % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((ms % (1000 * 60)) / 1000);

  if (ms >= 1000 * 60 * 60 * 24) {
      return `${String(days).padStart(2, '0')}d:${String(hours).padStart(2, '0')}h`;
  }
  if (ms >= 1000 * 60 * 60) {
      return `${String(hours).padStart(2, '0')}h:${String(minutes).padStart(2, '0')}m`;
  }
  return `${String(minutes).padStart(2, '0')}m:${String(seconds).padStart(2, '0')}s`;
};

  
  // Helper function to format time left for a match
// Helper function to format time left for a match
export const formatTimeLeft = (startTime, endTime) => {
  const now = Math.floor(Date.now() / 1000);
  const timeLeft = startTime - now; // Time until start
  const timeUntilEnd = endTime - now; // Time until end

  if (timeLeft > 0) {
    // Upcoming match: STARTING IN
    const days = Math.floor(timeLeft / 86400);
    const hours = Math.floor((timeLeft % 86400) / 3600);
    const minutes = Math.floor((timeLeft % 3600) / 60);
    const seconds = timeLeft % 60;

    if (days > 0) {
      return {
        label: 'STARTING IN',
        time: `${String(days).padStart(2, '0')}d:${String(hours).padStart(2, '0')}h`,
      };
    } else if (hours > 0) {
      return {
        label: 'STARTING IN',
        time: `${String(hours).padStart(2, '0')}h:${String(minutes).padStart(2, '0')}m`,
      };
    } else {
      return {
        label: 'STARTING IN',
        time: `${String(minutes).padStart(2, '0')}m:${String(seconds).padStart(2, '0')}s`,
      };
    }
  } else if (timeUntilEnd > 0) {
    // Live match: ENDING IN
    const days = Math.floor(timeUntilEnd / 86400);
    const hours = Math.floor((timeUntilEnd % 86400) / 3600);
    const minutes = Math.floor((timeUntilEnd % 3600) / 60);
    const seconds = timeUntilEnd % 60;

    if (days > 0) {
      return {
        label: 'ENDING IN',
        time: `${String(days).padStart(2, '0')}d:${String(hours).padStart(2, '0')}h`,
      };
    } else if (hours > 0) {
      return {
        label: 'ENDING IN',
        time: `${String(hours).padStart(2, '0')}h:${String(minutes).padStart(2, '0')}m`,
      };
    } else {
      return {
        label: 'ENDING IN',
        time: `${String(minutes).padStart(2, '0')}m:${String(seconds).padStart(2, '0')}s`,
      };
    }
  } else {
    // Previous match: ENDED AT
    return {
      label: 'ENDED AT',
      time: new Date(endTime * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' }),
    };
  }
};

  
  // // Function to update a timer based on tournament status
  // export const getTimerLabelAndTime = (tournament) => {
  //   const startTimestamp = tournament.start_time * 1000;
  //   const durationInMs = tournament.duration * 60 * 60 * 1000;
  //   const endTimestamp = startTimestamp + durationInMs;
  //   const now = Date.now();
  
  //   if (tournament.status === 'live') {
  //     if (now < endTimestamp) {
  //       return { label: 'ENDING IN', time: formatTime(endTimestamp - now) };
  //     } else {
  //       return { label: 'ENDED AT', time: new Date(endTimestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' }) };
  //     }
  //   } else if (tournament.status === 'previous') {
  //     return { label: 'ENDED AT', time: new Date(endTimestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' }) };
  //   } else if (tournament.status === 'upcoming') {
  //     if (now < startTimestamp) {
  //       return { label: 'STARTING IN', time: formatTime(startTimestamp - now) };
  //     } else {
  //       return { label: '', time: new Date(startTimestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' }) };
  //     }
  //   }
  // };
  



// To get data in format of 10th Nov 2024

export const formatDate = (inputDate) => {
    const date = typeof inputDate === 'string' ? new Date(inputDate) : new Date(inputDate * 1000);
    const day = date.getDate();
    const daySuffix = getDaySuffix(day);
    const formattedDate = `${day}${daySuffix} ${date.toLocaleDateString('en-GB', { month: 'short' })} ${date.getFullYear()}`;
  
    return formattedDate;
  };
  
  const getDaySuffix = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };
  