import React, { useState, useEffect, useContext } from 'react';
import '../../CustomCss/customStyles.css';
import './rewardsPopup.css';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap styles
import { AuthContext } from '../../Utils/Contexts/authContext';
import RealCash from '../../Assets/img/wallet/real_cash.png';
import BonusCash from '../../Assets/img/wallet/bonus_cash.png';
import '@fortawesome/fontawesome-free/css/all.min.css'; // FontAwesome icons
import '../../Assets/fonts/fontawesome/duotone.svg';
import MoneyBox from '../../Assets/img/giftvoucher/Rectangle.gif';
import { BASE_URL, fetchWithAuth } from '../../Utils/ApiServices/apiFunctions';

const TournamentRewardPopup = ({ tournament_id }) => {
    const [showPopup, setShowPopup] = useState(false);
    const { accessToken } = useContext(AuthContext); // Authentication token

    const [rewardData, setRewardData] = useState(null);

    useEffect(() => {
        const fetchRewardData = async () => {
            try {
                const response = await fetchWithAuth(`${BASE_URL}/tournaments?landing=false&status=all&page_size=10&page=1&tournament_id=${tournament_id}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch wallet data');
                }

                const data = await response.json();
                console.log('Fetched Data:', data); // Check the whole response data

                // Assuming the response data structure looks like this:
                // { user_data: { prize_distributed: 0, ...otherData } }

                // Log to ensure we have the correct data
                if (data && data.tournament) {
                    console.log('tournament', data.tournament);
                    setRewardData(data.tournament); // Update rewardData
                } else {
                    console.error('User data is missing or malformed');
                }
            } catch (error) {
                console.error('Error fetching wallet data:', error);
            }
        };

        fetchRewardData();
    }, [accessToken, tournament_id]); // Ensure both `accessToken` and `tournament_id` are included in the dependency array

    useEffect(() => {
        console.log('Reward Data Updated:', rewardData); // Log the updated state
        const popupDisplayed = sessionStorage.getItem('rewardDisplayed');
        if (!popupDisplayed) { 

        const timer = setTimeout(() => {
            // Check if rewardData and prize_distributed are available before showing the popup
            if (rewardData && rewardData.prize_distributed === 1) {
                console.log("Setting showPopup to true");
                setShowPopup(true);
                sessionStorage.setItem('rewardDisplayed', 'true'); // Set the flag

            }
        }, 2000); // 2 seconds delay

        // Clear the timer if the component unmounts
        return () => clearTimeout(timer);
    }
    }, [rewardData]); // Only re-run when rewardData changes

    const closePopup = () => setShowPopup(false);

    return (
        <>
            {showPopup && (
                <div className="popup-overlay" onClick={closePopup}>
                    <div
                        id="QuickView"
                        className="rewards-popup"
                        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the popup
                    >
                        <button className="popup-close-btn" onClick={closePopup}>
                            ×
                        </button>
                        
                        <h4 className="rewards-header ">
                            {rewardData.splash_text}
                            {/* Unstoppable! You Conquered the top spot! Rewards Unlocked! */}
                        </h4>
                        {/* <img className="moneyboxImg" src={MoneyBox} alt="Money Box" />
                        <p className="rewards-pop-up-text">
                            <strong>100 Acash</strong>
                        </p> */}
                        <img className="moneyboxImg" src={MoneyBox} alt="Money Box" />
                        <div className="rewards-details">
                            <p className="rewards-pop-up-text">
                              <img
                                                        style={{
                                                            height: 17,
                                                            paddingLeft: 5,
                                                            paddingBottom: 3,
                                                        }}
                                                        src={RealCash}
                                                        alt="real"
                                                    />  {rewardData.user_reward_winnings_wallet} 
                            </p>
                            <p className="rewards-pop-up-text">
                            <img
                                                        style={{
                                                            height: 18,
                                                        }}
                                                        src={BonusCash}
                                                        alt="bonus"
                                                    />    {rewardData.user_reward_winnings_wallet}

                            </p>
                            </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default TournamentRewardPopup;