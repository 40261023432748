

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../Utils/ApiServices/apiFunctions';


const BlogAreaListing = () => {
  const [blogs, setBlogs] = useState([]); // Default to an empty array
  const [recentPosts, setRecentPosts] = useState([]); // Default to an empty array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

useEffect(() => {
  const fetchBlogs = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/blogs`); // Replace with your API endpoint

      setBlogs(response.data.blogs || []);
      setRecentPosts(response.data.recent_posts || []);
    } catch (err) {
      console.error('Error fetching blogs:', err);
      setError('Failed to load blog data.');
    } finally {
      setLoading(false);
    }
  };

  fetchBlogs();
}, []);


  if (loading) return <div className="text-center mt-5">Loading...</div>;
  if (error) return <div className="text-center text-danger mt-5">{error}</div>;

  return (
    <section className="th-blog-wrapper space-top space-extra2-bottom" style={{ backgroundColor: 'black', marginTop: '100px' }}>
      <div className="container">
        <div className="row gx-40">
          {/* Blog Listing Section */}
          <div className="col-xxl-8 col-lg-7">
            {blogs.length > 0 ? (
              blogs.map((blog) => (
                <div key={blog.id} className="th-blog blog-single has-post-thumbnail">
                  <div className="blog-img">
                    <Link to={`/blog-details/${blog.id}`}>
                      <img src={blog.image_url} alt={blog.title} />
                    </Link>
                  </div>
                  <div className="blog-content">
                    <div className="blog-meta">
                      <a className="author" href="/blogs">
                        <i className="fa-light fa-user"></i> By {blog.author}
                      </a>
                      <a href="/blogs">
                        <i className="fa-light fa-calendar"></i>{' '}
                        {new Date(blog.published_date).toLocaleDateString()}
                      </a>
                      {/* <a href={`/blog-details/${blog.id}`}>
                        <i className="fa-light fa-comment"></i> {blog.comments_count} Comments
                      </a> */}
                    </div>
                    <h2 className="blog-title" style={{ textAlign: 'left' }}>
                      <Link to={`/blog-details/${blog.id}`}>{blog.title}</Link>
                    </h2>
                    <p className="blog-text">{blog.excerpt}</p>
                    <Link to={`/blog-details/${blog.id}`} className="link-btn style2">
                      Read More <i className="fa-regular fa-arrow-right ms-2"></i>
                    </Link>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center text-muted">No blogs available.</div>
            )}
          </div>

          {/* Sidebar Section */}
          <div className="col-xxl-4 col-lg-5">
            <aside className="sidebar-area">
              {/* <div className="widget widget_search">
                <form className="search-form">
                  <input type="text" placeholder="Search here..." />
                  <button type="submit">
                    <i className="far fa-search"></i>
                  </button>
                </form>
              </div> */}

              <div className="widget">
                <h3 className="widget_title">Recent Posts</h3>
                <div className="recent-post-wrap">
                  {recentPosts.length > 0 ? (
                    recentPosts.map((post) => (
                      <div key={post.id} className="recent-post">
                        <div className="media-img">
                          <Link  to={`/blog-details/${post.id}`}>
                            <img src={post.image_url} alt={post.title} />
                          </Link>
                        </div>
                        <div className="media-body">
                          <h4 className="post-title">
                            <Link className="text-inherit" to={`/blog-details/${post.id}`}>
                              {post.title}
                            </Link>
                          </h4>
                          <div className="recent-post-meta">
                            <a href="/blogs">
                              <i className="fa-light fa-calendar"></i>{' '}
                              {new Date(post.published_date).toLocaleDateString()}
                            </a>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-center text-muted">No recent posts available.</div>
                  )}
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogAreaListing;
