import React, { useEffect, useState, useContext } from 'react';
import '../../App.css';
import '../../CustomCss/customStyles.css';
import { fetchWithAuth } from '../../Utils/ApiServices/apiFunctions';
import { AuthContext } from '../../Utils/Contexts/authContext';
import { BASE_URL } from '../../Utils/ApiServices/apiFunctions';

const AgonPartners = () => {
    const [referrals, setReferrals] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const { accessToken } = useContext(AuthContext);

    // Fetch referral data on mount
    useEffect(() => {
        const fetchReferralData = async () => {
            setIsLoading(true);
            try {
                const response = await fetchWithAuth(`${BASE_URL}/referral_activity`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                const result = await response.json();

                if (response.ok) {
                    setReferrals(result.data || []);
                } else if (response.status === 404) {
                    setError('No referral activity found.');
                } else {
                    setError(result.message || 'Failed to fetch referral activity.');
                }
            } catch (e) {
                setError('An error occurred while fetching referral activity.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchReferralData();
    }, [accessToken]);

    // Render referral cards
    const renderReferralCards = () => {
        if (referrals.length === 0) {
            return <div>No referrals found.</div>;
        }

        return referrals.map((referral) => (
            <div key={referral.referred_user_id} className="referral-card">
                <h2>{referral.referred_username}</h2>
                <p>Joined Date: <strong> {referral.referred_joined_at || 'N/A'}</strong> </p>
                <p>Tournaments Joined: <strong>{referral.num_tournaments_joined || 0}</strong></p>
                <p>Winnings: <strong>{(referral.total_winnings || 0).toFixed(2)} ACash</strong></p>
                <p>Bonus: <strong>{(referral.total_bonus || 0).toFixed(2)} ACoins</strong></p>
            </div>
        ));
    };

    // Function to get the current month and year in the desired format
    const getCurrentMonthYear = () => {
        const date = new Date();
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear().toString().slice(-2); // Get the last two digits of the year
        return `${month} '${year}`;
    };

    // Render loading spinner or error message
    if (isLoading) {
        return (
            <div className="central-message">
                <div>Loading referral activity...</div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="central-message">
                <div className="error-message">{error}</div>
            </div>
        );
    }

    return (
        <div className="agon-partners-container">
            <h1 className="page-heading">Referral Activity</h1>
            <h2 className="page-subtitle">Your Referrals Engagement - {getCurrentMonthYear()}</h2>
            <div id="referral-container">{renderReferralCards()}</div>
        </div>
    );
};

export default AgonPartners;
