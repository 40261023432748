import React, { useState, useEffect, useContext } from 'react';
import './tournamentPopups.css';
import Voucher_1 from '../../Assets/img/giftvoucher/amozon.jpg';
import Voucher_2 from '../../Assets/img/giftvoucher/flipkart.jpg';
import Voucher_3 from '../../Assets/img/giftvoucher/google.jpg';
import Voucher_4 from '../../Assets/img/giftvoucher/swiggy.jpg';
import { AuthContext } from '../../Utils/Contexts/authContext';
import { useNavigate } from 'react-router-dom';

const TournamentPopUp = ({ onClose }) => {
    const [showPopup, setShowPopup] = useState(false);
    const { accessToken } = useContext(AuthContext);
    const navigate = useNavigate();

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setShowPopup(true); // Always show popup for debugging
    //     }, 5000);
    //     return () => clearTimeout(timer); // Cleanup timer
    // }, [accessToken]);

    useEffect(() => {
        if (showPopup) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [showPopup]);

    useEffect(() => {
        // Check localStorage for popup display flag
        const popupDisplayed = sessionStorage.getItem('logoutDisplayed');
        if (!popupDisplayed) {
            const timer = setTimeout(() => {
                setShowPopup(true);
                sessionStorage.setItem('logoutDisplayed', 'true'); // Set the flag
            }, 12000);
            return () => clearTimeout(timer); // Cleanup timer
        }
    }, [accessToken]);

    const handleOverlayClick = (e) => {
        if (e.target.className === 'popup-overlay') {
            setShowPopup(false);
            onClose?.();
        }
    };

    return (
        <>
            {showPopup && (
                <div className="tournament-popup-overlay" onClick={handleOverlayClick}>
                    <div className="tournament-popup-container">
                        <button
                            className="popup-close-btn"
                            onClick={() => {
                                setShowPopup(false);
                                onClose?.();
                            }}
                        >
                            ×
                        </button>
                        <h2 className="popup-header">Welcome to AgonGames</h2>
                        <p className="popup-text">
                            Start earning for your in-game skills. Rewards across 100+ popular brands.
                        </p>
                        <div className="popup-hiw-section">
                            <div className="popup-hiw-card">
                                <div className="popup-card-icon">
                                    <i className="fa fa-swords"></i>
                                </div>
                                <div>
                                    <h3 className="cardText1">Join a tournament</h3>
                                    <p className="cardText">
                                        Start earning by winning tournaments. First 5 tournaments are free to join.
                                    </p>
                                </div>
                            </div>
                            <div className="popup-hiw-card">
                                <div className="popup-card-icon">
                                    <i className="fa fa-shop"></i>
                                </div>
                                <div>
                                    <h3 className="cardText1">Gamer's Shop</h3>
                                    <p className="cardText">Select rewards across 100+ popular brands.</p>
                                </div>
                            </div>
                        </div>
                        <div className="popup-slider">
                            <div className="popup-slider-container">
                                <img className="popup-img" src={Voucher_1} alt="Voucher 1" />
                                <img className="popup-img" src={Voucher_2} alt="Voucher 2" />
                                <img className="popup-img" src={Voucher_3} alt="Voucher 3" />
                                <img className="popup-img" src={Voucher_4} alt="Voucher 4" />

                                <img className="popup-img" src={Voucher_1} alt="Voucher 1" />
                                <img className="popup-img" src={Voucher_2} alt="Voucher 2" />
                                <img className="popup-img" src={Voucher_3} alt="Voucher 3" />
                                <img className="popup-img" src={Voucher_4} alt="Voucher 4" />
                            </div>
                        </div>
                        <div className="popup-actions">
                            <button
                                className="tournament-btn"
                                onClick={() =>
                                    navigate(`/login`, {
                                        state: { from: window.location.pathname },
                                    })
                                }
                            >
                                Log In <i className="fa-solid fa-arrow-right ms-3"></i>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default TournamentPopUp;
