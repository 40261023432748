import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../App.css';
import '../../CustomCss/customStyles.css';
import GameVS1 from '../../Assets/img/tournament/game-vs1.svg';
import TournamentCard2Bg from '../../Assets/img/bg/tournament-card2-bg.png';
import TournamentSec2Bg from '../../Assets/img/bg/tournament-sec2-bg.png';
import Timer from './Timer';

const Card7 = ({
  gameId,
  tournamentId,
  // tournamentImg1,
  // tournamentImg2,
  tournamentImage,
  gameVSImg,
  cardBg,
  subtitle1,
  title1,
  subtitle2,
  title2,
  startTime,
  endTime,
  status,
  currentPlayers,
  maxPlayers,
  detailsLink,
  entryFee,
  userRank,
  userRegistered,
  userRegistrationAllowed,
  ctaText,
  ctaEnabled,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tournaments, setTournaments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [totalPages, setTotalPages] = useState(1);   // Total pages from API response
  const [pageSize, setPageSize] = useState(10);      // Number of tournaments per page
  const [selectedFilter, setSelectedFilter] = useState('upcoming'); // Default filter is 'upcoming'
  const [topTournaments , setTopTournaments] = useState('true');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  

  const renderEnterButton = () => {
    // Check if user registration is allowed
    if (ctaEnabled) {
      // Enable the Entry button
      return (
        <button
          className="custom-button"
          style={{ backgroundColor: '#45F882' }} // Green when enabled
          // onClick={() => navigate('/login')}
          onClick={() => navigate(`/login`, { state: { from: window.location.pathname } })}
          
        >
          {ctaText || `Entry - ${entryFee > 0 ? `${entryFee}` : 'Free'}`}
        </button>
      );
    } else {
      // Disable the Entry button
      return (
        <button
          className="custom-button disabled-btn"
          disabled
          style={{ backgroundColor: '#b3b3b3' }} // Grey when disabled
        >
          {ctaText || `Entry - ${entryFee > 0 ? `${entryFee}` : 'Free'}`}
        </button>
      );
    }
  };
  
  const handleCardClick = () => {
    navigate(detailsLink);
  };

  return (
    // 
      <div className="col-12 filter-item cat1" style={{ marginTop: '20px', cursor:"pointer" }}>
        <div className="tournament-card style2" id={`tournament-${tournamentId}`}>
        
          
            <div className="tournament-card-img" onClick={handleCardClick}>
              <img src={tournamentImage} alt="tournament-image" />
            </div>
          
          
            <div className="tournament-card-versus" onClick={handleCardClick}>
              <img src={gameVSImg} alt="tournament-versus" />
            </div>
          
          
            <div className="tournament-card-content" onClick={handleCardClick}>
              {/* <div className="tournament-card-details" style={{ backgroundImage: `url(${cardBg})`, backgroundRepeat: 'no-repeat' }}> */}
              <div className="tournament-card-details">
                
                  <div className="card-title-wrap text-md-end">
                  <h6 className="tournament-card-subtitle">{subtitle1}</h6>
                    <h3 className="tournament-card-title" style={{color:"white"}}>{title1}</h3>
                  </div>
                
                
                  <div className="tournament-card-date-wrap">
                    <Timer startTime={startTime} endTime={endTime}></Timer>
                  </div>
              
              
                  <div className="card-title-wrap">
                    <h6 className="tournament-card-subtitle">DURATION: {subtitle2} HR</h6>
                    <h3 className="tournament-card-title" style={{color:"white"}}>Prize: {title2}/-</h3>
                  </div>
            
              </div>
              <div className="tournament-card-meta">
                <span className="tournament-card-tag gradient-border">{status}</span>
                <span className="tournament-card-score gradient-border">{currentPlayers}/{maxPlayers}</span>
              </div>
            </div>
          
          <div className="tournament-card-img register-container">
            {/* {renderEnterButton()} */}
            <button
              className={`custom-button ${ctaEnabled ? '' : 'disabled-btn'}`}
              // style={{ backgroundColor: '#45F882' }} // Green when enabled
              // onClick={() => navigate('/login')}
              onClick={() => navigate(`/login`, { state: { from: window.location.pathname } })}
              disabled={!ctaEnabled}
            >
              {ctaText}
            </button>
          </div>
        </div>
      </div>
    //
  );
};

export default Card7;















