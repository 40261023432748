import React, { useEffect } from 'react';
import '../../App.css';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Waypoint from 'waypoints/lib/noframework.waypoints';
import SmoothScroll from 'smooth-scroll';
import WOW from 'wowjs';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import { gsap } from 'gsap';
import { Link } from 'react-router-dom';

const CookiePolicy = () => {
  useEffect(() => {
    // Initialize any necessary jQuery or other plugins here
  }, []);

  return (
    <>
      <section className="th-blog-wrapper blog-details space-top space-extra2-bottom" style={{ backgroundColor: "black", marginTop: "100px" }}>
        <div className="container">
          <div className="row gx-40">
            <div className="col-xxl-12 col-lg-7">
              <div className="th-blog blog-single">
                <div className="blog-content">
                  <h2 className="blog-title">Cookie Policy</h2>
                  <p>We use cookies to enhance your experience on our website at <Link to="/">https://www.agongames.com</Link>. This Cookie Policy is part of AgonGames' Privacy Policy and explains the use of cookies between your device and our site.</p>
                  <p>We also provide basic information about third-party services we may use, which may also use cookies as part of their service. This policy does not cover the cookies of those third-party services.</p>
                  <p>If you choose not to accept cookies from us, please instruct your browser to refuse cookies from <Link to="/">https://www.agongames.com</Link>. Please note that this may limit your access to certain content and services on our site.</p>

                  <h3>What is a Cookie?</h3>
                  <p>A cookie is a small data file stored on your device when you visit a website. It typically contains information about the website itself, a unique identifier that enables the site to recognize your browser when you return, additional data relevant to the cookie’s purpose, and the cookie’s lifespan.</p>
                  <p>Cookies are used to enable specific features (e.g., logging in), monitor site usage (e.g., analytics), store your user preferences (e.g., time zone, notification settings), and personalize content (e.g., advertising, language).</p>
                  <p>Cookies set by the website you are visiting are known as first-party cookies and usually only track your activity on that specific site.</p>
                  <p>Cookies set by other websites or companies (i.e., third parties) are called third-party cookies. They can be used to track you across different sites that utilize the same third-party service.</p>

                  <h3>Types of Cookies and How We Use Them</h3>
                  
                  <h4>Essential Cookies</h4>
                  <p>Essential cookies are vital for providing core functionalities on a website, such as user logins, account management, shopping carts, and payment processing.</p>
                  <p>We use essential cookies to enable certain functionalities on our website.</p>

                  <h4>Performance Cookies</h4>
                  <p>Performance cookies track how you interact with a website during your visit. This information is typically aggregated and anonymous, providing insights into visitor usage patterns, helping identify problems users may encounter, and guiding strategic improvements to enhance the website experience. These cookies can be set by the site itself (first-party) or by third-party services. They do not collect personally identifiable information.</p>
                  <p>We use performance cookies on our site.</p>

                  <h4>Functionality Cookies</h4>
                  <p>Functionality cookies collect information about your device and any preferences you set on the website you are visiting (like language and time zone settings). This information helps websites provide you with enhanced and personalized content and services. Functionality cookies can be set by the website you are visiting (first-party) or by third-party services.</p>
                  <p>We use functionality cookies for selected features on our site.</p>

                  <h4>Targeting/Advertising Cookies</h4>
                  <p>Targeting/advertising cookies help determine which promotional content may be most relevant to you and your interests. These cookies can help limit the number of times you see an advertisement, improving campaign effectiveness and the relevance of content presented to you. These cookies may be set by the website you are visiting (first-party) or by third-party services and can track you across other websites that use the same third-party service.</p>
                  <p>We do not use this type of cookie on our site.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CookiePolicy;
