import React, { useEffect } from 'react';
import '../../App.css';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Waypoint from 'waypoints/lib/noframework.waypoints';
import SmoothScroll from 'smooth-scroll';
import WOW from 'wowjs';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import { gsap } from 'gsap';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  useEffect(() => {
    // Initialize any necessary jQuery or other plugins here
  }, []);

  return (
    <>
      <section className="th-blog-wrapper blog-details space-top space-extra2-bottom" style={{ backgroundColor: "black", marginTop: "100px" }}>
        <div className="container">
          <div className="row gx-40">
            <div className="col-xxl-12 col-lg-7">
              <div className="th-blog blog-single">
                <div className="blog-content">
                  <h2 className="blog-title">Privacy Policy</h2>
                  <p>Your privacy is important to us. It is AgonGames' policy to respect your privacy and comply with any applicable law and regulation regarding any personal information we may collect about you, including across our website, <Link to="/">https://www.agongames.com</Link>, and other sites we own and operate.</p>
                  <p>Personal information is any information about you that can be used to identify you. This includes information about you as a person (such as name, address, and date of birth), your devices, payment details, and information about how you use a website or online service.</p>
                  <p>If our Site contains links to third-party sites and services, please be aware that those sites and services have their own privacy policies. After following a link to any third-party content, you should read their posted privacy policy for information about how they collect and use personal information. This Privacy Policy does not apply to any of your activities after you leave our Site.</p>
                  <p>This policy is effective as of 01/12/2024.</p>

                  <h3>Information We Collect</h3>
                  <p>Information we collect falls into one of two categories: “voluntarily provided” information and “automatically collected” information.</p>
                  <ul>
                  <li>Voluntarily provided information is any information you knowingly and actively provide when using or participating in any of our services and promotions.</li>
                  <li>Automatically collected information refers to any information automatically sent by your devices while accessing our products and services.</li>
                  </ul>
                  <h3>Log Data</h3>
                  <p>When you visit our Site, our servers may automatically log the standard data provided by your web browser. This data may include your device’s IP address, browser type and version, the pages you visit, the time and date of your visit, time spent on each page, and other details about your visit.</p>
                  <p>Additionally, if you encounter certain errors while using the Site, we may automatically collect data about the error and the circumstances surrounding it. This data may include technical details about your device, what you were trying to do, and other relevant information. Such information, though not personally identifying by itself, could potentially be combined with other data to identify individuals.</p>

                  <h3>Device Data</h3>
                  <p>When you visit our Site or interact with our services, we may automatically collect data about your device, such as:</p>
                  <ul>
                    <li>Device Type</li>
                    <li>Operating System</li>
                    <li>Unique device identifiers</li>
                    <li>Geo-location data</li>
                  </ul>
                  <p>The data we collect can depend on the settings of your device and software. We recommend checking the policies of your device manufacturer or software provider to learn about the information they make available to us.</p>

                  <h3>Personal Information</h3>
                  <p>We may ask for personal information — for example, when you submit content to us or contact us — which may include the following:</p>
                  <ul>
                    <li>Name</li>
                    <li>Email</li>
                    <li>Social media profiles</li>
                    <li>Date of birth</li>
                    <li>Phone/mobile number</li>
                    <li>Home/mailing address</li>
                    <li>Virtual Identity</li>
                  </ul>

                  <h3>User-Generated Content</h3>
                  <p>User-generated content includes any materials (text, image, and/or video content) voluntarily provided by users for publication on our Site or on our social media channels. All user-generated content is associated with the account or email address used to submit it. Please be aware that any content submitted for publication will be public after posting and may be accessible to third parties not covered under this privacy policy.</p>

                  <h3>Legitimate Reasons for Processing Your Personal Information</h3>
                  <p>We collect and use your personal information only when we have a legitimate reason, and we only collect personal information that is reasonably necessary to provide our services to you.</p>

                  <h3>Collection and Use of Information</h3>
                  <p>We may collect personal information when you:</p>
                  <ul>
                    <li>Register for an account</li>
                    <li>Sign up for updates via email or social media channels</li>
                    <li>Access content using a mobile device or web browser</li>
                    <li>Contact us via email, social media, or similar technologies</li>
                    <li>Mention us on social media</li>
                  </ul>
                  <p>We may use this information to:</p>
                  <ul>
                    <li>Provide you with the core features and services of our platform</li>
                    <li>Customize or personalize your experience on our Site</li>
                    <li>Contact and communicate with you</li>
                    <li>Send you promotional information, including offers from third parties we believe may interest you</li>
                    <li>Enable you to access and use our Site, applications, and associated social media platforms</li>
                    <li>Comply with legal obligations and resolve disputes</li>
                    <li>Attribute any content (e.g., posts, comments) you submit that we publish on our Site</li>
                    <li>Enhance the security and functionality of our Site, apps, and social media platforms</li>
                    <li>Conduct technical assessments, such as improving our app and social media platforms</li>
                  </ul>

                  <h3>Security of Your Personal Information</h3>
                  <p>We implement rigorous cybersecurity protocols and take robust measures to safeguard your personal information from loss, theft, unauthorized access, disclosure, copying, use, or modification. However, please be aware that no method of electronic transmission or storage is 100% secure, and we cannot guarantee absolute data security.</p>

                  <h3>How Long We Keep Your Personal Information</h3>
                  <p>We retain your personal information only as long as needed. If it is no longer required, we will delete it or anonymize it. However, we may retain data for legal, accounting, or reporting obligations, or for archival, research, or statistical purposes.</p>

                  <h3>Children's Privacy</h3>
                  <p>We do not target our products or services at children under the age of 13, nor do we knowingly collect personal information about children under 13.</p>

                  <h3>Disclosure of Personal Information to Third Parties</h3>
                  <p>We may disclose personal information to:</p>
                  <ul>
                    <li>Our parent, subsidiary, or affiliate companies</li>
                    <li>Third-party service providers, including IT, hosting, server providers, ad networks, analytics, and payment systems operators</li>
                    <li>Employees, contractors, or related entities</li>
                    <li>Existing or potential agents or business partners</li>
                    <li>Courts, tribunals, regulatory authorities, and law enforcement, as required by law</li>
                  </ul>

                  <h3>International Transfers of Personal Information</h3>
                  <p>The personal information we collect may be stored or processed outside of your country of residence, including in India or where our partners, affiliates, and third-party providers maintain facilities. If we transfer your data to third parties in other countries, we will do so in compliance with applicable law and protect it as outlined in this policy.</p>

                  <h3>Your Rights and Controlling Your Personal Information</h3>
                  <p>You have the right to request access to, correction, or deletion of your personal information and to restrict or object to its processing. To do so, please contact us using the information below.</p>

                  <h3>Use of Cookies</h3>
                  <p>We use “cookies” to track your preferences and activity on our Site. Please refer to our Cookie Policy for more information.</p>

                  <h3>Business Transfers</h3>
                  <p>If we undergo a business transition, such as a merger or acquisition, your personal information may be among the assets transferred. You acknowledge that such transfers may occur, and the new owners may use your data in accordance with this policy.</p>

                  <h3>Limits of Our Policy</h3>
                  <p>Our Site may link to external sites not operated by us. We are not responsible for the content or privacy practices of these external sites.</p>

                  <h3>Changes to This Policy</h3>
                  <p>We may update this Privacy Policy as needed. Significant changes will be communicated to our users, and the updated policy will be posted here.</p>

                  <h3>Additional Disclosures for General Data Protection Regulation (GDPR) Compliance (EU)</h3>
                  <p>We act as both a Data Controller and/or Data Processor regarding your personal information and will only process your data based on the following legal bases:</p>
                  <ul>
                    <li>Consent: Where you give us permission.</li>
                    <li>Performance of a Contract: When necessary for fulfilling a contract with you.</li>
                    <li>Legitimate Interests: To operate, improve, and communicate our services.</li>
                    <li>Compliance with Law: When legally required.</li>
                  </ul>

                  <h3>Contact Us</h3>
                  <p>For any questions or concerns regarding your privacy, please contact us at <a href="mailto:contact@agongames.com">contact@agongames.com</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
