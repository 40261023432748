
import React , {useEffect} from 'react';
import '../../../App.css';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Waypoint from 'waypoints/lib/noframework.waypoints';
import SmoothScroll from 'smooth-scroll';
import WOW from 'wowjs';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import { gsap } from 'gsap'; 
import '../PartnerWithUs/partnerWithUsForm.css';


const PartnerWithUsForm = () => {
    return (
      <>
       <div class="contact-page-1 space firstContainer">
          <div class="contact-sec-1 secDiv space bg-repeat overflow-hidden" data-bg-src="assets/img/bg/jiji-bg2.png">
              <div class="container">
                  <div class="row align-items-center justify-content-between">
                      <div class="col-xl-6 mainContainer pe-xxl-5">
                          <div class="title-area">
                              <span class="sub-title style2"># Get In Touch</span>
                              <h2 class="sec-title secText text-white">Contact Us & Meet Our Team <span class="text-theme">!</span></h2>
                          </div>
                          <form action="mail.php" method="POST" class="contact-form ajax-contact pb-xl-0 space-bottom">
                              <div class="row">
                                  <div class="form-group  col-md-6">
                                      <input type="text" class="form-control" name="name" id="name" placeholder="Your Name"/>
                                      <i class="fal fa-user"></i>
                                  </div>
                                  <div class="form-group  col-md-6">
                                      <input type="email" class="form-control" name="email" id="email" placeholder="Email Address"/>
                                      <i class="fal fa-envelope"></i>
                                  </div>
                                  <div class="form-group  col-md-6">
                                      <input type="text" class="form-control" name="number" id="number" placeholder="Phone Number"/>
                                      <i class="fal fa-phone"></i>
                                  </div>
                                  <div class="col-md-6">
                                      <div class="form-group dropdownBtnstyle-border2">
                                          <select class="form-select dropdownSelect" aria-label="Default select example">
                                              <option selected>Subject</option>
                                              <option value="1">Esports</option>
                                              <option value="2">Tournament</option>
                                              <option value="3">Sports</option>
                                          </select>
                                          <i class="fal fa-angle-down"></i>
                                      </div>
                                  </div>
                                  <div class="col-12 form-group style-border2">
                                      <textarea placeholder="Write Massage...." class="form-control textSize"></textarea>
                                      <i class="far fa-pencil"></i>
                                  </div>
                                  <div class="form-btn col-12">
                                      <button class="th-btn">Send Message <i class="fa-solid fa-arrow-right ms-2"></i></button>
                                  </div>
                              </div>
                              <p class="form-messages mb-0 mt-3"></p>
                          </form>
                      </div>
                      <div class="col-xl-6 mainContainer ps-xxl-5">
                          <div class="title-area">
                              <span class="sub-title style2"># Information</span>
                              <h2 class="sec-title secText text-white">Contact Information's <span class="text-theme">!</span></h2>
                          </div>
                          <div class="contact-feature contactBox">
                              <div class="contact-feature-icon icon-masking">
                                  <span class="mask-icon" data-mask-src="assets/img/icon/contact-map-icon1.svg"></span>
                                  <img src="assets/img/icon/contact-map-icon1.svg" alt="img"/>
                              </div>
                              <div class="media-body">
                                  <h4 class="box-title">ADDRESS:</h4>
                                  <a href="https://www.google.com/maps" class="contact-feature_link">Vasanta Nagar, Bengaluru, 560052</a>
                              </div>
                          </div>
                          <div class="contact-feature contactBox" >
                              <div class="contact-feature-icon icon-masking">
                                  <span class="mask-icon" data-mask-src="assets/img/icon/contact-phone-icon1.svg"></span>
                                  <img src="assets/img/icon/contact-phone-icon1.svg" alt="img"/>
                              </div>
                              <div class="media-body">
                                  <h4 class="box-title">OFFICE NUMBER:</h4>
                                  <a href="tel:16365981254" class="contact-feature_link">Mobile: 9933943456</a>
                              </div>
                          </div>
                          <div class="contact-feature contactBox">
                              <div class="contact-feature-icon icon-masking">
                                  <span class="mask-icon" data-mask-src="assets/img/icon/contact-envelope-icon1.svg"></span>
                                  <img src="assets/img/icon/contact-envelope-icon1.svg" alt="img"/>
                              </div>
                              <div class="media-body">
                                  <h4 class="box-title">OFFICE EMAIL:</h4>
                                  <a href="mailto:info@bame.com" class="contact-feature_link">Office Mail: info@bame.com</a>
                                  <a href="mailto:info@bameceo.com" class="contact-feature_link">CEO Mail: info@bameceo.com</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div> 
      </>
    )
  }
  
  export default PartnerWithUsForm
  