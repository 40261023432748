import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from './authContext';

const ProtectedRoute = ({ children }) => {
  const { accessToken } = useContext(AuthContext);
  const location = useLocation();

  console.log('AccessToken in ProtectedRoute:', accessToken);
  
  // If not authenticated, redirect to the login page
  if (!accessToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // If authenticated, render the children components (protected pages)
  else {
    return children;
  }

};

export default ProtectedRoute;
