import React , { useEffect, useState } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Waypoint from 'waypoints/lib/noframework.waypoints';
import SmoothScroll from 'smooth-scroll';
import WOW from 'wowjs';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import { gsap } from 'gsap'; 

const ColorSwitcher = () => {
  return (
    <>
      <div className="color-scheme-wrap active">
        <button className="switchIcon"><i className="fa-solid fa-palette"></i></button>
        <h4 className="color-scheme-wrap-title"><i className="far fa-palette"></i> Color Switcher</h4>
        <div className="color-switch-btns">
            <button data-color="#6240CF"><i className="fa-solid fa-droplet"></i></button>
            <button data-color="#FFBE18"><i className="fa-solid fa-droplet"></i></button>
            <button data-color="#24FFF2"><i className="fa-solid fa-droplet"></i></button>
            <button data-color="#45F882"><i className="fa-solid fa-droplet"></i></button>
            <button data-color="#FF7E02"><i className="fa-solid fa-droplet"></i></button>
        </div>
    </div>
    </>
  )
}

export default ColorSwitcher
