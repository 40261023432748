import React , { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Utils/Contexts/authContext';
import '../../App.css';
import $ from 'jquery'; // Import jQuery
import 'jquery-ui/ui/widgets/slider'; // Example widget
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Cursor from '../../Components/Common/Cursor';
import Preloader from '../../Components/Common/Preloader';
import SideMenu2 from '../../Components/Common/SideMenu2';
import ColorSwitcher from '../../Components/Common/ColorSwitcher';
import Footer2 from '../../Components/Common/Footer2';
import ScrollToTop from '../../Components/Common/ScrollToTop';
import GlobalLeaderboard from '../../Components/LeaderBoardPageComp/globalLeaderboard';
import JoinUs from '../../Components/LandingPageComp/joinUs';
import SimpleHeader2 from '../../Components/Common/SimpleHeader2';
import SimpleHeader1 from '../../Components/Common/SimpleHeader1';
import BottomNavbar from '../../Components/Common/BottomNavbar';
import MainHeader1 from '../../Components/Common/MainHeader1';



const LeaderBoardPage = () => {
  const { accessToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // useEffect(() => {

  //   if (accessToken) {
  //     // If token exists, navigate to /proleaderboard
  //     navigate('/proleaderboard');
  //   } else {
  //     navigate('/')
  //   }
  //   // If no token, stay on /gamespage (no need to do anything)
  // }, [accessToken, navigate]); // Add navigate as a dependency for useEffect

  return (
    <>
     <>
      <Cursor/>
      <Preloader/>
      <MainHeader1 toggleMenu={toggleMenu}  />
      {/* <SimpleHeader2 toggleMenu={toggleMenu}/> */}
      <SideMenu2 isMenuOpen={isMenuOpen} toggleMenu={toggleMenu}/>
      {/* <ColorSwitcher/> */}
      <GlobalLeaderboard/>
      <JoinUs/>
      <Footer2/>
      <ScrollToTop/>
    </> 
    </>
  )
}

export default LeaderBoardPage
