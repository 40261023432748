import React from 'react';
import '../../App.css';
import 'jquery-ui/ui/widgets/slider'; // Example widget
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Cursor from '../../Components/Common/Cursor';
import Preloader from '../../Components/Common/Preloader';
import SideMenu2 from '../../Components/Common/SideMenu2';
import Footer2 from '../../Components/Common/Footer2';
import ScrollToTop from '../../Components/Common/ScrollToTop';
import ProductsArea from '../../Components/ShopPageComp/productsArea';
import SimpleHeader3 from '../../Components/Common/SimpleHeader3';
import BottomNavbar from '../../Components/Common/BottomNavbar';

const ProShopPage = () => {
    return (
        <>
            <Cursor />
            <Preloader />
            <SimpleHeader3 />
            <SideMenu2 />
            <ProductsArea />
            <BottomNavbar />
            <ScrollToTop />
        </>
    );
};

export default ProShopPage;
