import React , { useEffect, useState } from 'react';
import '../../App.css';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Waypoint from 'waypoints/lib/noframework.waypoints';
import SmoothScroll from 'smooth-scroll';
import WOW from 'wowjs';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import { gsap } from 'gsap'; 

const Preloader = () => {

    const [isLoaded, setIsLoaded] = useState(false);
  
    // Function to fade out the preloader
    const fadeOutPreloader = () => {
      const preloader = document.querySelector('.preloader');
      if (preloader) {
        preloader.style.transition = 'opacity 0.5s ease-out';
        preloader.style.opacity = '0';
        setTimeout(() => {
          preloader.style.display = 'none';
          console.log('Preloader hidden');
          wowAnimation();
        }, 500); // Duration matches the CSS transition
      } else {
        console.error('Preloader element not found');
      }
    };
  
    useEffect(() => {
      const handleLoad = () => {
        console.log('Page has loaded');
        setIsLoaded(true);
        fadeOutPreloader();
      };
  
      // Check if the document is already loaded
      if (document.readyState === 'complete') {
        handleLoad();
      } else {
        // Add event listener for the load event
        window.addEventListener('load', handleLoad);
      }
  
      // Clean up the event listener on unmount
      return () => {
        window.removeEventListener('load', handleLoad);
      };
    }, []);
  
    // Function to handle preloader close on button click
    const handlePreloaderClose = (event) => {
      event.preventDefault();
      setIsLoaded(true);
      fadeOutPreloader();
    };
  
    // Return null when loaded to unmount the preloader from the DOM
    if (isLoaded) return null;
  
  
  
    return (
      <>
          <div className="preloader ">
              <button className="th-btn preloaderCls" onClick={handlePreloaderClose}>CANCEL PRELOADER </button>
              <div className="preloader-inner">
                  <span className="loader"></span>
              </div>
          </div> 
      </>
    )
  }
  
  const wowAnimation = () => {
    // Initialize WOW.js using the imported WOW class
    new WOW.WOW({
      boxClass: 'wow',      // Animated element css class (default is wow)
      animateClass: 'animated', // Animation css class (default is animated)
      offset: 0,           // Distance to the element when triggering the animation (default is 0)
      mobile: true,        // Trigger animations on mobile devices (default is true)
      live: true,          // Act on asynchronously loaded content (default is true)
    }).init();
    console.log('WOW.js initialized');
  };
  
  export default Preloader
