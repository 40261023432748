import React, { useEffect, useState, useContext } from "react";
import "../../CustomCss/customStyles.css";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import { fetchWithAuth } from "../../Utils/ApiServices/apiFunctions"; // Fetch function
import { AuthContext } from "../../Utils/Contexts/authContext"; // Context for Auth
import { BASE_URL } from "../../Utils/ApiServices/apiFunctions"; // API Base URL

const QuestDetail = ({ questId }) => {
  const [quest, setQuest] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { accessToken } = useContext(AuthContext);
  const [isJoined, setIsJoined] = useState(false); // Track join quest status
  const [expandedStep, setExpandedStep] = useState(1); // Track expanded step
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuestDetail = async () => {
      setIsLoading(true);
      try {
        const response = await fetchWithAuth(`${BASE_URL}/quests/${questId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
          },
        });

        const result = await response.json();

        if (response.ok) {
          setQuest(result || null);
          setIsJoined(result.isJoined || false); // Set join status from API
        } else {
          setError(result.message || "Failed to fetch quest details.");
        }
      } catch (e) {
        setError("An error occurred while fetching quest details.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchQuestDetail();
  }, [accessToken, questId]);

  const toggleStep = (stepId) => {
    setExpandedStep((prev) => (prev === stepId ? null : stepId));
  };

  const validateStep = async (stepId) => {
    if (!accessToken) {
      // Redirect to login if not logged in
      navigate("/login", { state: { from: window.location.pathname } });
      return;
    }

    try {
      const response = await fetchWithAuth(`${BASE_URL}/quests/${questId}/steps/${stepId}/validate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const result = await response.json();

      if (response.ok) {
        // Mark step as done on success
        setQuest((prevQuest) => ({
          ...prevQuest,
          steps: prevQuest.steps.map((step) =>
            step.id === stepId ? { ...step, completed: true } : step
          ),
        }));
      } else {
        alert(result.message || "Failed to validate the step.");
      }
    } catch (e) {
      alert("An error occurred while validating the step.");
    }
  };

  const joinQuest = async () => {
    if (!accessToken) {
      // Redirect to login if not logged in
      navigate("/login", { state: { from: window.location.pathname } });
      return;
    }

    try {
      const response = await fetchWithAuth(`${BASE_URL}/quests/${questId}/join`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const result = await response.json();
      if (response.ok) {
        setIsJoined(true); // Update join status on success
      } else {
        alert(result.message || "Failed to join the quest.");
      }
    } catch (e) {
      alert("An error occurred while joining the quest.");
    }
  };

  if (isLoading) return <div className="central-message">Loading quest details...</div>;
  if (error) return <div className="central-message error-message">{error}</div>;
  if (!quest) return <div className="central-message">No details available for this quest.</div>;

  return (
    <section className="quest-detail-container">
      {/* Back Button
      <a href="/quests" className="back-link">
        <i className="fas fa-arrow-left"></i> Back to Quests
      </a>
      */}
      {/* Quest Header */}
      <div className="quest-detail-header">
        <img
          src={quest.image || "https://placehold.co/600x400?text=Quest+Detail"}
          alt={quest.title}
          className="quest-detail-image"
        />
        <div className="quest-detail-info">
          <h1 className="quest-title">{quest.title}</h1>
          <p className="quest-meta">
            <i className="fas fa-users"></i> Slots: {quest.slots || "N/A"}
          </p>
          <p className="quest-meta">
            <i className="fas fa-clock"></i> Time Left: {quest.timeLeft || "N/A"}
          </p>
          <p className="quest-meta">
            <i className="fas fa-coins"></i> Reward: {quest.reward || "$0"}
          </p>
          <p className="quest-meta">
            <i className="fas fa-dollar-sign"></i> Earnings: {quest.earnings || "$0"}
          </p>
          <button
            className={`join-button ${isJoined ? "joined" : ""}`}
            onClick={!isJoined ? joinQuest : null}
            disabled={isJoined}
          >
            {isJoined ? "Joined" : "Join Quest"}
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="quest-detail-body">
        {/* Quest Steps */}
        <div className="quest-steps">
          <h2 className="section-title">Quest Steps</h2>
          {quest.steps?.map((step) => (
            <div
              key={step.id}
              className={`step-container ${
                expandedStep === step.id ? "expanded" : "collapsed"
              }`}
            >
              <div
                className="step-header"
                onClick={() => toggleStep(step.id)}
              >
                <h3>{step.title}</h3>
                <i
                  className={`fas ${
                    expandedStep === step.id ? "fa-chevron-down" : "fa-chevron-right"
                  }`}
                ></i>
              </div>
              {expandedStep === step.id && (
                <div className="step-content">
                  <p>{step.description}</p>
                  {!step.completed ? (
                    <button
                      className="validate-button"
                      onClick={() => validateStep(step.id)}
                    >
                      Validate Step
                    </button>
                  ) : (
                    <span className="step-done">Done</span>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Quest Rules */}
        <div className="quest-rules">
          <h2 className="section-title">Quest Rules</h2>
          <ul>
            {quest.rules?.map((rule, index) => (
              <li key={index}>
                <i className="fas fa-circle"></i> {rule}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default QuestDetail;
