
import React, { useState, useEffect, useContext } from 'react';
import {
    FaTrophy,
    FaChevronRight,
    FaUser,
    FaCog,
    FaBell,
    FaSignOutAlt,
    FaChartLine,
    FaGamepad,
    FaShoppingCart,
    FaEnvelope,
    FaShieldAlt,
    FaUsers,
    FaGift,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './SideNav.css';
import PropTypes from 'prop-types';
import { AuthContext } from '../../../Utils/Contexts/authContext';
import { BASE_URL, fetchWithAuth } from '../../../Utils/ApiServices/apiFunctions';
import item from 'isotope-layout/js/item';

const SideNavbar = ({ isOpen ,  handleMenuClick}) => {
    const { logout, accessToken } = useContext(AuthContext);
    const [profile, setProfile] = useState({
        username: '', // This will be set from display_name
        proficiency: 'Beginner',
        image: null,
    });
    const [error, setError] = useState(null);
    const [activeMenu, setActiveMenu] = useState(null);

    const navigate = useNavigate();

    // Fetch user profile from API
    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await fetchWithAuth(`${BASE_URL}/user/profile`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                const data = await response.json();

                if (data.code === 200) {
                    const { display_name } = data.user_data;
                    setProfile((prevProfile) => ({
                        ...prevProfile,
                        username: display_name,
                    }));
                } else {
                    throw new Error('Failed to fetch profile data');
                }
            } catch (e) {
                console.error(e);
                setError('Error fetching profile data');
            }
        };

        fetchProfile();
    }, []);

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            setProfile({
                ...profile,
                image: URL.createObjectURL(e.target.files[0]),
            });
        }
    };

    const handleEditProfile = () => {
        navigate(`/my-dashboard`);
    };
      

    const handleLogoutClick = () => {
        console.log("logout button is working ");
        const confirmed = window.confirm('Are you sure you want to logout?');
        if (confirmed) {
           
            logout();

        }
    };

    const menuOptions = [
        { icon: <FaGift className="menu-icon" />, label: 'My Rewards', route: '/my-rewards' },
        { icon: <FaTrophy className="menu-icon" />, label: 'My Tournaments', route: '/my-tournaments' },
        { icon: <i class="fa-solid fa-crown menu-icon"></i>, label: 'My Pass', route: '/my-tournaments' },
        { icon: <i class="fa-solid fa-wallet menu-icon"></i> , label: 'My Wallet', route: '/my-wallet' },
        { 
            icon: (
              <svg 
                fill='#fff'
                id="fi_2822537" 
                enableBackground="new 0 0 512 512" 
                height="1em" 
                viewBox="0 0 512 512" 
                width="1em" 
                xmlns="http://www.w3.org/2000/svg" 
                className="menu-icon"
              >
                <path d="m411 8.782v91.218h91.211z"></path>
                <path d="m396 130c-8.284 0-15-6.716-15-15v-115h-215c-24.813 0-45 20.187-45 45v167.689c4.942-.448 9.943-.689 15-.689 51.128 0 96.897 23.376 127.186 60h162.814c8.284 0 15 6.716 15 15s-6.716 15-15 15h-143.052c9.376 18.296 15.422 38.565 17.363 60h125.689c8.284 0 15 6.716 15 15s-6.716 15-15 15h-125.689c-4.486 49.539-30.954 92.826-69.553 120h235.242c24.813 0 45-20.187 45-45v-337zm30 82h-220c-8.284 0-15-6.716-15-15s6.716-15 15-15h220c8.284 0 15 6.716 15 15s-6.716 15-15 15z"></path>
                <path d="m136 242c-74.439 0-135 60.561-135 135s60.561 135 135 135 135-60.561 135-135-60.561-135-135-135zm40 150h-40c-8.284 0-15-6.716-15-15v-60c0-8.284 6.716-15 15-15s15 6.716 15 15v45h25c8.284 0 15 6.716 15 15s-6.716 15-15 15z"></path>
              </svg>
            ), 
            label: 'Order History' 
          },
          {
            icon: (
              <svg 
                fill='#fff'
                id="fi_4526832" 
                height="1em" 
                viewBox="0 0 100 100" 
                width="1em" 
                xmlns="http://www.w3.org/2000/svg" 
                className="menu-icon"
              >
                <g>
                  <path d="m84.234 78.581c-2.014-5.994-10.041-9.746-15.764-12.261-2.242-.982-8.449-2.648-9.195-5.471-.267-1.017-.231-1.976-.012-2.895-.345.066-.695.105-1.057.105h-3.729c-2.977 0-5.396-2.422-5.396-5.397 0-2.973 2.42-5.39 5.396-5.39h3.729c1.232 0 2.4.417 3.342 1.161 1.381-.184 2.713-.479 3.955-.866 1.631-3.417 2.903-7.503 3.188-11.02 1.217-15.048-8.008-23.852-21.235-22.33-9.617 1.107-15.362 8.278-15.983 17.51-.628 9.41 2.861 16.36 6.567 21.458 1.623 2.229 3.328 3.662 3.066 6.348-.304 3.176-3.7 4.061-6.129 5.037-2.878 1.156-5.978 2.91-7.442 3.721-5.043 2.785-10.578 6.139-11.822 10.727-2.755 10.168 6.549 13.248 14.23 14.67 6.592 1.216 14.025 1.312 20.139 1.312 11.059 0 30.945-.443 34.152-8.756.912-2.359.521-6.118 0-7.663z"></path>
                  <path d="m60.566 51.143c-.506-.771-1.371-1.283-2.358-1.283h-3.729c-1.556 0-2.811 1.257-2.811 2.805 0 1.554 1.255 2.813 2.811 2.813h3.729c1.089 0 2.013-.621 2.479-1.519 5.199-.409 9.721-1.997 12.895-4.342.729.47 1.591.745 2.521.745h.234c2.592 0 4.688-2.098 4.688-4.693v-9.368c0-1.866-1.094-3.476-2.672-4.224-.688-15.043-13.141-27.077-28.353-27.077s-27.667 12.034-28.352 27.077c-1.581.749-2.674 2.358-2.674 4.224v9.368c0 2.595 2.098 4.693 4.684 4.693h.237c2.588 0 4.687-2.098 4.687-4.693v-9.368c0-1.839-1.063-3.425-2.608-4.192.669-12.675 11.187-22.778 24.026-22.778 12.834 0 23.357 10.103 24.023 22.778-1.543.768-2.605 2.353-2.605 4.192v9.368c0 .622.121 1.201.334 1.742-2.732 1.955-6.709 3.348-11.186 3.732z"></path>
                </g>
              </svg>
            ), 
            label: 'Support'
          },
        //   { icon: <FaShoppingCart className="menu-icon" />, label: 'Support', route: '/Cart' },
        // { icon: <FaShoppingCart className="menu-icon" />, label: 'My Cart', route: '/Cart' },
        // { icon: <FaShoppingCart className="menu-icon" />, label: 'My Wishlist' },
        { icon: <FaSignOutAlt className="menu-icon" />, label: 'Logout', route: '/logout' },
    ];

    // const handleMenuClick = (item) => {
    //     if (activeMenu === item) {
    //         toggleSideNavbar(); // Close the sidebar if the same menu is clicked
    //     } else {
    //         setActiveMenu(item);
    //         navigate(item.route);
    //     }
    // };

    return (
        <div className="side-navbar-container" style={{ backgroundColor: 'black' }}>
            <div className={`side-navbar ${isOpen ? 'open' : 'closed'}`}>
                <div className="profile-section" style={{ cursor: 'pointer' }} onClick={handleEditProfile}>
                    <input
                        type="file"
                        id="upload"
                        // onChange={handleImageChange}
                        style={{ display: 'none' }}
                    />
                    <label htmlFor="upload" className="profile-picture2">
                        <img src={profile.image || 'https://via.placeholder.com/80'} alt="Profile" />
                    </label>
                    <div className="profile-info">
                        <h4>{profile.username || 'User'}</h4>
                        <p>{profile.proficiency}</p>
                    </div>
                    <FaChevronRight className="edit-icon" />
                </div>

                <div className="menu-options">
                    {menuOptions.map((item, index) => (
                        <div
                            className="menu-item"
                            key={index}
                            onClick={item.label === 'Logout' ? handleLogoutClick : () => handleMenuClick(item.route)}
                        >
                            {item.icon}
                            <span>{item.label} </span>
                        </div>
                    ))}
                </div>
                {error && <p className="error-message">{error}</p>}
            </div>
        </div>
    );
};

SideNavbar.propTypes = {
    gameId: PropTypes.string.isRequired,
};

export default SideNavbar;