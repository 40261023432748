import React , { useEffect, useState } from 'react';
// import '../../Assets/sass/scssImport.scss'
import '../../App.css';
import { Link } from 'react-router-dom';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Waypoint from 'waypoints/lib/noframework.waypoints';
import SmoothScroll from 'smooth-scroll';
import WOW from 'wowjs';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import { gsap } from 'gsap'; 
import counterUp from 'counterup2';

const StatsTimer = () => {

    useEffect(() => {
        // Apply counterUp after ensuring jQuery and DOM are ready
        $('.counter-number').each(function () {
          counterUp(this, {
            delay: 10,
            time: 1000,
          });
        });
      }, []);
  return (
    <>
      {/* <div className="space-bottom counter-area-1" style={{backgroundColor:"black" , display:"flex" , justifyContent:"center" , alignItems:"center", paddingTop:"100px" , height:"10px"}}> */}
      <div  style={{paddingTop:'40px', paddingBottom:"40px", backgroundColor: "black", background: "linear-gradient(var(--black-color2) , black)"}}>
        <div className="container">
            <div className="counter-card-wrap">
                <div className="counter-card">
                    <div className="media-body">
                        <h2 className="box-number"><span className="counter-number">1.5</span>K<span className="text-theme fw-medium">+</span></h2>
                        <p className="box-text">Tournaments Hosted</p>
                    </div>
                </div>
                <div className="counter-card">
                    <div className="media-body">
                        <h2 className="box-number"><span className="counter-number">10</span>L<span className="text-theme fw-medium">+</span></h2>
                        <p className="box-text">Reward Distributed</p>
                    </div>
                </div>
                <div className="counter-card">
                    <div className="media-body">
                        <h2 className="box-number"><span className="counter-number">50</span>K<span className="text-theme fw-medium">+</span></h2>
                        <p className="box-text">Registered Gamers</p>
                    </div>
                </div>
                <div className="counter-card">
                    <div className="media-body">
                        <h2 className="box-number"><span className="counter-number">41</span>%</h2>
                        <p className="box-text">Winning Users</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default StatsTimer
