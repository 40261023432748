import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../Utils/Contexts/authContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import useScreenSize from '../../Utils/Contexts/useScreenSize';
import '../../App.css';
import '../../Components/LoginPageComp/LoginForm.css';
import 'jquery-ui/ui/widgets/slider'; // Example widget
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Cursor from '../../Components/Common/Cursor';
import Preloader from '../../Components/Common/Preloader';
import SideMenu2 from '../../Components/Common/SideMenu2';
import HeroSection from '../../Components/LandingPageComp/heroSection';
import ProGameArea1 from '../../Components/ProtectedComponents/proGameArea1';
import AboutUs from '../../Components/LandingPageComp/aboutUs';
import JoinUs from '../../Components/LandingPageComp/joinUs';
import GamersShop from '../../Components/LandingPageComp/gamersShop';
import Footer1 from '../../Components/Common/Footer1';
import ScrollToTop from '../../Components/Common/ScrollToTop';
import ProTournamentsList2 from '../../Components/ProtectedComponents/proTournamentsList2';
import FirstLoginPopUp from '../../Components/LandingPageComp/productLightBox';
import SimpleHeader3 from '../../Components/Common/SimpleHeader3';

import { BASE_URL, fetchWithAuth } from '../../Utils/ApiServices/apiFunctions';
import TournamentListMobile from '../../Components/MobileViewComp/tournamentListMobile';
import BottomNavbar from '../../Components/Common/BottomNavbar';
import LoginPopUp from '../../Components/LandingPageComp/loginPopUp';







const ProLandingPage = () => {
    const { accessToken, logout } = useContext(AuthContext);
    const navigate = useNavigate(); // To navigate to the login page if not logged in
    const location = useLocation();
    const isMobile = useScreenSize();

    const [gameHandles, setGameHandles] = useState({});
    // Extract success message from location state if available

    const [showSuccessMessage, setShowSuccessMessage] = useState(Boolean(location.state?.successMessage));

    const [isFirstTimeUser, setIsFirstTimeUser] = useState(true);

    const [showPopup, setShowPopup] = useState(false);

    // Triggered once on mount if a success message exists in location state
    useEffect(() => {
        if (location.state?.successMessage) {
            setShowSuccessMessage(true);

            // Clear the message after a delay
            setTimeout(() => {
                setShowSuccessMessage(false);

                // Clear the success message from location state to prevent repeat
                navigate(location.pathname, { replace: true });
            }, 700000);
        }
    }, [location.pathname, location.state, navigate]);

    // useEffect(() => {
    //     setTimeout(() => {
    //         window.scrollTo(0, 0);
    //     }, 100);
    // }, []);

    useEffect(() => {
        setIsFirstTimeUser(localStorage.getItem('new_user') === 'true');
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowPopup(true);
        }, 5000); // 5 seconds delay

        // Clear the timer if the component unmounts
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const fetchGamerHandle = async () => {
            try {
                const response = await fetchWithAuth(`${BASE_URL}/profile/get_gamer_handle`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(`Failed to fetch gamer handle: ${response.statusText}`);
                }

                const data = await response.json();

                // Check if response is valid and set handles
                // console.log('downloaded handles', data.handles);
                if (data.handles && (Array.isArray(data.handles) || Object.keys(data.handles).length > 0)) {
                    const temp = {};
                    for (const key in data.handles) {
                        temp[data.handles[key]['game_id']] = data.handles[key]['gamer_handle'];
                        localStorage.setItem(`gamerHandle-${data.handles[key]['game_id']}`, data.handles[key]['gamer_handle'])
                    }
                    setGameHandles(temp);
                } else {
                    setGameHandles([]);
                }
            } catch (error) {
                console.error('Error fetching gamer handle:', error);
            }
        };

        fetchGamerHandle();
    }, [accessToken]);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const [isPopupOpen, setIsPopupOpen] = useState(true);

    const openPopup = () => setIsPopupOpen(true);
    const closePopup = () => setIsPopupOpen(false);


    return (
        <div>
             {showSuccessMessage && <LoginPopUp />}
             

            {accessToken ? (
                <>
                    {/* <CSSTransition in={showSuccessMessage} timeout={500} classNames="slide" unmountOnExit>
                        <div className="success-notification show">{location.state?.successMessage}</div>
                    </CSSTransition> */}
                    <Cursor />
                    <Preloader />
                    {/* <ColorSwitcher/> */}
                    {/* <ScrollToTop /> */}
                    {/* <MainHeader2 toggleMenu={toggleMenu}/> */}
                    <SimpleHeader3 />

                    {/* <ProductLightBox/> */}
                    {/* <SideMenu1/> */} 
                    <SideMenu2 isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
                    {/* <MobileMenu/> */}
                    <HeroSection />
                    {showPopup && isFirstTimeUser != undefined && isFirstTimeUser != null && isFirstTimeUser && (
                        <FirstLoginPopUp />
                    )}
                    {/* <Stats/> */}
                    {/* <ProGameArea1 /> */}
                   
                    {/* <div id="how-it-works">
          <HowItWorks /> 
        </div> */}
                    {/* <Marquee/> */}

                    <div id="tournament">
                        {/* <ProTournamentsList1/> */}
                        {/* <ProTournamentsList2
                            selectedGameId={''}
                            gameHandles={gameHandles}
                            landing={true}
                            pageType={'private'}
                        /> */}
                        {isMobile ? (
                            <TournamentListMobile
                                selectedGameId={''}
                                gameHandles={gameHandles}
                                landing={true}
                                pageType={'private'}
                                isScrollAvailable={false}
                            />
                        ) : (
                            <ProTournamentsList2
                                selectedGameId={''}
                                gameHandles={gameHandles}
                                landing={true}
                                pageType={'private'}
                                isScrollAvailable={false}
                            />
                        )}
                    </div>
                    {/* <AboutUs /> */}
                    <JoinUs />
                    <GamersShop />
                    <BottomNavbar />
                    <Footer1 />
                
                </>
            ) : (
                <p>You are not logged in. Redirecting to login page...</p>
            )}
        </div>
    );
};

export default ProLandingPage;

// import React , { useState , useEffect , useContext } from 'react';
// import { AuthContext } from '../../Utils/Contexts/authContext'; 
// import { useNavigate, useLocation } from 'react-router-dom';
// import { CSSTransition } from 'react-transition-group';
// import '../../App.css';
// import '../../Components/LoginPageComp/LoginForm.css';
// import $ from 'jquery'; // Import jQuery
// import 'jquery-ui/ui/widgets/slider'; // Example widget
// import 'bootstrap/dist/css/bootstrap.min.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';
// import 'magnific-popup/dist/magnific-popup.css';
// import 'magnific-popup';
// import Cursor from '../../Components/Common/Cursor';
// import Preloader from '../../Components/Common/Preloader';
// import ProductLightBox from '../../Components/LandingPageComp/productLightBox';
// import SideMenu1 from '../../Components/Common/SideMenu1';
// import SideMenu2 from '../../Components/Common/SideMenu2';
// import MobileMenu from '../../Components/LandingPageComp/mobileMenu';
// import StatsTimer from '../../Components/LandingPageComp/statsTimer';
// import ColorSwitcher from '../../Components/Common/ColorSwitcher';
// import HeroSection from '../../Components/LandingPageComp/heroSection';
// import ProGameArea1 from '../../Components/ProtectedComponents/proGameArea1';
// import HowItWorks from '../../Components/LandingPageComp/howItWorks';
// import TourVSOriginal from '../../Components/LandingPageComp/tournamentsList1';
// import AboutUs from '../../Components/LandingPageComp/aboutUs';
// import JoinUs from '../../Components/LandingPageComp/joinUs';
// import GamersShop from '../../Components/LandingPageComp/gamersShop';
// import Footer1 from '../../Components/Common/Footer1';
// import ScrollToTop from '../../Components/Common/ScrollToTop';
// import MainHeader2 from '../../Components/Common/MainHeader2';
// import ProTournamentsList1 from '../../Components/ProtectedComponents/proTournamentsList1';
// import { fetchWithAuth } from '../../Utils/ApiServices/apiFunctions';
// import { BASE_URL } from '../../Utils/ApiServices/apiFunctions';
// import LoginPopUp from '../../Components/LandingPageComp/loginPopUp';
// const ProLandingPage = () => {

//   const { accessToken, logout } = useContext(AuthContext);
//   const navigate = useNavigate(); // To navigate to the login page if not logged in
//   const location = useLocation();

//   // Extract success message from location state if available

//   const [showSuccessMessage, setShowSuccessMessage] = useState(Boolean(location.state?.successMessage));
  


//   // Redirect to login if not logged in
//   // useEffect(() => {
    
//   //   // if (!accessToken) {
//   //   //   window.history.forward();
//   //   //   navigate('/login'); // Redirect to the login page if user is not logged in
//   //   // }

//   //   // Automatically hide the success message after a delay
//   //   if (showSuccessMessage) {
//   //     setTimeout(() => setShowSuccessMessage(false), 3000);
//   //   }
//   // }, [showSuccessMessage]);

//   // Triggered once on mount if a success message exists in location state
//   useEffect(() => {
//     if (location.state?.successMessage) {
//       setShowSuccessMessage(true);

//       // Clear the message after a delay
//       setTimeout(() => {
//         setShowSuccessMessage(false);

//         // Clear the success message from location state to prevent repeat
//         navigate(location.pathname, { replace: true });
//       }, 7000);
//     }
//   }, [location.pathname, location.state, navigate]);

  
//   useEffect(() => {
//     setTimeout(() => {
//       window.scrollTo(0, 0);
//     }, 100);
//   }, []); 


//   useEffect(() => {
//     console.log('fetching gamer handles')
//     const fetchGamerHandle = async () => {
//       try {
//         const gamerHandleResponse = await fetch(
//           `${BASE_URL}/profile/get_gamer_handle`,
//           {
//             method: 'GET',
//             headers: {
//               'Content-Type': 'application/json',
//               'Authorization': `Bearer ${accessToken}`,
//             },
//           }
//         );
        
//         const gamerHandleResult = await gamerHandleResponse.json();
//         if (gamerHandleResult.code === 200 && Array.isArray(gamerHandleResult.handles)) {
//           gamerHandleResult.handles.forEach(handle => {
//             localStorage.setItem(`gamerHandle_${handle.game_id}`, handle.gamer_handle);
//          });
//         }
//         console.log('Gamer handle check result:', gamerHandleResult);
//       } catch (error) {
//         console.error('Error fetching gamer handle:', error);
//       }
//     };
  
//     fetchGamerHandle();
//   }, [accessToken]);
  

//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   return (
//     <div>
//        {showSuccessMessage && <LoginPopUp />}
//       {accessToken ? (
//       <> 
//         <CSSTransition in={showSuccessMessage} timeout={500} classNames="slide" unmountOnExit>
//           <div className="success-notification show">{location.state?.successMessage}</div>
//         </CSSTransition>
//         <Cursor/>
//         <Preloader/>
//         {/* {/ <ColorSwitcher/> /} */}
//         <ScrollToTop/>
//         <MainHeader2 toggleMenu={toggleMenu}/>
//         {/* {/ <ProductLightBox/> /} */}
//         {/* {/ <SideMenu1/> /} */}
//         <SideMenu2 isMenuOpen={isMenuOpen} toggleMenu={toggleMenu}/>
//         {/* {/ <MobileMenu/> /} */}
//         <HeroSection/>
//         {/* {/ <Stats/> /} */}
//         <ProGameArea1/>
//         {/* <div id="how-it-works">
//           <HowItWorks /> 
//         </div> */}
//         {/* {/ <Marquee/> /} */}
//         <div id='tournament'>
//           <ProTournamentsList1/>
//         </div>  
//         <AboutUs/>
//         <JoinUs/>
//         <GamersShop/>
//         <Footer1/>    
//       </>
//     ) : (
//       <p>You are not logged in. Redirecting to login page...</p>
//     )}
//     </div>
//   );
// }

// export default ProLandingPage