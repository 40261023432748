import React, { useState ,useContext , useEffect} from 'react';
import '../../App.css';
import RealCash from '../../Assets/img/wallet/real_cash.png';
import CharacterImage from '../../Assets/img/tournament/1-1.png';
import '../../CustomCss/customStyles.css';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Utils/Contexts/authContext';
import { BASE_URL, fetchWithAuth } from '../../Utils/ApiServices/apiFunctions';
import Timer from '../Common/Timer';
import GameHandlePopup from '../Common/GameHandlePopup';

const NextMatchCard = ({
  tournamentId,
  status,
  imageSrc,
  title,
  duration,
  progress,
  matchType,
  startTime,
  endTime,
  mode,
  prize,
  rank,
  players,
  currency,
  gameId,
  detailsLink,
  ctaText, // Prop for CTA button text
  ctaEnabled, // Prop for enabling/disabling the button
  // accessToken, // Access token for auth check
  // isRegistered, // User registration state
  gamerHandle, // Gamer handle state
  userRegistered,
}) => {
  const navigate = useNavigate();

  const [ctaTextComp, setCTATextComp] = useState(ctaText); // Manage CTA text state
  // const [ctaEnabledComp, setCTAEnabledComp] = useState(ctaEnabled); // Manage CTA enabled state
  const { accessToken } = useContext(AuthContext);
  const [ctaEnabledcomp, setCTAEnabledComp] = useState(ctaEnabled);
  const [showGamerHandlePrompt, setShowGamerHandlePrompt] = useState(false);
  const [isRegistered, setIsRegistered] = useState(userRegistered);
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState({
    status: false,
    gameId: gameId,
});


const closePopup = () => {
  setShowPopup({ status: false, gameId: gameId });
};
  const handleCardClick = () => {
    navigate(detailsLink);
  };

  console.log("isRegistered:", isRegistered,[isRegistered]);

  const registerForTournament = async () => {
    try {
        const response = await fetchWithAuth(`${BASE_URL}/tournaments/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ tournament_id: tournamentId }),
        });

        const data = await response.json();
        if (data.code === 200) {
            alert('Successfully registered!');
            setIsRegistered(true);
            setShowGamerHandlePrompt(false); // Close modal on success
            setCTATextComp(data.cta_text);
            setCTAEnabledComp(data.cta_enabled);
        } else {
            console.log('Failed adding handle for reason', data);
            alert(`Failed to register: ${data.message}`);
            
        }
    } catch (error) {
        console.error('Error registering for tournament:', error);
        setIsLoading(false);
        alert('Failed to register for the tournament.');
    }
};
  
  const handleEnterClick = async (e) => {
    e.stopPropagation();
   if (!accessToken) {
      navigate(`/login`, { state: { from: window.location.pathname } });
      return;
    }
     if (isRegistered) {
    console.log("button is clicked")
      navigate(detailsLink, { state: { gamerHandle } });
      return;
    }
    console.log ('current handle', localStorage.getItem(`gamerHandle-${gameId}`))
    // if (!userRegistrationAllowed || isLoading) return;

    // const existingGamerHandle = await fetchGamerHandle();
  
    // if (gamerHandle || localStorage.getItem(`gamerHandle-${gameId}`)!='') {
    //     await registerForTournament();
    // } else {
    //     setShowPopup({ status: true, gameId: gameId });
    // }
    const localGamerHandle = localStorage.getItem(`gamerHandle-${gameId}`);
    console.log ('current handle', localGamerHandle)

    if (gamerHandle || (localGamerHandle!==null && localGamerHandle!=='')) {
        await registerForTournament();
    } else {
        setShowPopup({ status: true, gameId: gameId });
        setShowGamerHandlePrompt(true); // Show the modal if no gamer handle exists
    }
    
    // Additional behavior can be added here if needed
  };

  return (
    <div
      className="widget-tournament-info gradient-border-wrapper next-match-card"
      onClick={handleCardClick}
      style={{ cursor: 'pointer' }}
    >
      {imageSrc && (
        <img
        style={{ width:80,
          height:100}}
           
          src={imageSrc || CharacterImage}
          alt={`${title} Character`}
          className="character-image"
          onError={(e) => {
            e.target.onerror = null; // Prevent infinite loop
            e.target.src = CharacterImage; // Set fallback on error
          }}
        />
      )}
      <div className="next-match-inner">
        <div className="match-status">
          <span className="status-label">{status}</span>
        </div>

        <h3 className="match-title">{title}</h3>

        <div className="match-details">
          <div className="match-details-one">
            <div className="detail-box gradient-border">
              <span className="detail-label">
                <i className="fa fa-clock"></i> {duration}
              </span>
            </div>
            <div className="detail-box gradient-border">
              <span className="detail-label"> <i class="fa-solid fa-users"></i>  {players}</span>
            </div>
          </div>
          <Timer startTime={startTime} endTime={endTime} />
          <div className="match-details-two">
            <div className="detail-box gradient-border">
              <span className="detail-label">{mode}</span>
            </div>
            <div className="detail-box gradient-border">
              <span className="detail-label">{matchType}</span>
            </div>
          </div>
        </div>

        <div className="prize">
          <span>
           Prize: {prize} <img
                        style={{
                          width:25,
                           height: 17,
                        //   paddingLeft: 5,
                            paddingBottom: 3,
                         }}
                         src={RealCash}
                                                        alt="real"
                                                    />
          </span>
        </div>

        <div className="match-stats">
        <button
                        className={`custom-button ${ctaEnabledcomp ? '' : 'disabled-btn'}`}
                        // style={{ backgroundColor: '#45F882' }} // Green when enabled
                        // onClick={() => navigate('/login')}
                        onClick={handleEnterClick}
                        disabled={!ctaEnabledcomp}
                    >
                        {ctaTextComp}
                         {/* <img
                        style={{
                           height: 17,
                        //   paddingLeft: 5,
                            paddingBottom: 3,
                         }}
                         src={RealCash}
                                                        alt="real"
                                                    />{' '} */}
                    </button>
        </div>
      </div>
      <GameHandlePopup isOpen={showPopup.status} onClose={closePopup} gameId={showPopup.gameId} />
    </div>
  );
};

export default NextMatchCard;
