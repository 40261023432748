import React from 'react'
import MainHeader2 from '../../Components/Common/MainHeader2'
import Dashboard from '../../Components/UserProfilePageComp/Dashboard/Dashboard'
import DetailedDash from '../../Components/UserProfilePageComp/DetailedDash/DetailedDash'
import Cursor from '../../Components/Common/Cursor'
import Preloader from '../../Components/Common/Preloader'
import Footer2 from '../../Components/Common/Footer2'
import ScrollToTop from '../../Components/Common/ScrollToTop'
import '../../CustomCss/customStyles.css'
import SimpleHeader3 from '../../Components/Common/SimpleHeader3'
import BottomNavbar from '../../Components/Common/BottomNavbar'

const ProDashboardPage = () => {
  return (
    <>
        <Cursor/>
        <Preloader/>
        <SimpleHeader3/>
        <div className='dashboard-styles' id='dashboard'>
          {/* <Dashboard/> */}
          <DetailedDash/>
        </div>
        <BottomNavbar /> 
        <Footer2/>
        <ScrollToTop/>
    </>
  )
}

export default ProDashboardPage
