import React, { useState, useEffect } from 'react';
import '../../App.css';
import '../../Assets/sass/style.scss';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'swiper/css/navigation'; // Import navigation CSS if used
import 'swiper/css/pagination'; // Import pagination CSS if used
import 'swiper/css';
import { BASE_URL } from '../../Utils/ApiServices/apiFunctions';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ConnectGameAccount from '../GamePageComp/connectGameAccount';

const ProGameCard6 = ({
    gameId,
    featureImage,
    gameTitle,
    gameDetailsLink,
    playNowLink,
    gameHandle,
    handlePlayNowClick,
    handleConnectGameAccount,
}) => {
    return (
        // <div
        //     // className="game-card gradient-border"
        //     style={{ backgroundColor: 'black', position: 'relative' }}
        // >
        <div className="swiper-slide">
             {/* <div className="game-card-img" style={{ position: 'relative' }} onClick={() => handlePlayNowClick(gameId)}> */}
            <div
                class="th-product custom-game-container product-grid"
                style={{ backgroundColor: 'black', cursor: 'pointer' }}
                onClick={() => handlePlayNowClick(gameId)}
            >
                <div
                    // className="game-card-img"
                    class="product-img custom-game-image"
                    style={{ position: 'relative', cursor: 'pointer' }}
                    // onClick={() => handlePlayNowClick(gameId)}
                >
                    {/* <Link to={gameDetailsLink}> */}
                    <img src={featureImage} alt="game-image" />
                    <div className="overlay gradient-border"></div>
                    {/* </Link> */}

                    {/* Blur mask with "CONNECT GAMER ACCOUNT" only on the game image */}
                    {!gameHandle && (
                        <div
                            className="blur-mask"
                            style={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                color: '#fff',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                zIndex: 2,
                            }}
                        >
                            <button
                                className="connect-game-account"
                                style={{
                                    backgroundColor: 'transparent',

                                    fontSize: '12px',
                                    color: '#45F882',
                                    border: '1px solid #45F882',
                                    cursor: 'pointer',
                                    borderRadius: '35px',
                                }}
                                onClick={() => handleConnectGameAccount(gameId)}
                            >
                                CONNECT GAME ACCOUNT
                            </button>
                        </div>
                    )}
                </div>
                <div className="game-card-details">
                    <h3 className="box-title" style={{ marginTop: '50px' }}>
                        {/* <Link onClick={() => handlePlayNowClick(gameId)}> */}
                        <span>{gameTitle}</span>
                    </h3>
                </div>
            </div>
        </div>
    );
};

export default ProGameCard6;
