import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './blogDetails_Api.css';
import { BASE_URL } from '../../Utils/ApiServices/apiFunctions';

const BlogDetailsArea = ({blogId}) => {
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/blog-details/${blogId}`);
        setBlog(response.data);
      } catch (err) {
        console.error('Error fetching blog details:', err);
        setError('Unable to fetch blog details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [blogId]);

  if (loading) return <div className="text-center mt-5">Loading...</div>;
  if (error) return <div className="text-center text-danger mt-5">{error}</div>;

  return (
    <section
      className="th-blog-wrapper blog-details space-top space-extra2-bottom"
      style={{ backgroundColor: 'black', marginTop: '100px' }}
    >
      <div className="container">
        <div className="row gx-40">
          <div className="col-xxl-8 col-lg-7">
            <div className="th-blog blog-single">
              <div className="blog-img">
                <img
                  src={blog?.image_url || 'https://via.placeholder.com/800x400'}
                  alt={blog?.title || 'Loading...'}
                  className="img-fluid"
                />
              </div>
              <div className="blog-content">
                <div className="blog-meta">
                  <a className="author" href="/blogs">
                    <i className="fa-light fa-user"></i> By {blog?.author || 'Unknown'}
                  </a>
                  <a href="/blogs">
                    <i className="fa-light fa-calendar"></i>{' '}
                    {blog?.published_date
                      ? new Date(blog.published_date).toLocaleDateString()
                      : 'Unknown Date'}
                  </a>
                  {/* <a href="#comments">
                    <i className="fa-light fa-comment"></i>{' '}
                    {blog?.comments ? blog.comments.length : 0} Comments
                  </a> */}
                </div>
                <h1 className="blog-title">{blog?.title || 'Loading...'}</h1>
                <p>{blog?.content || 'Loading content...'}</p>

                {/* Render sections dynamically */}
                {blog?.sections?.map((section, index) => (
                  <div key={index}>
                    <h2 className="section-title">{section.title}</h2>
                    <p>{section.content}</p>
                  </div>
                ))}

                <div className="share-links clearfix">
                  <div className="row justify-content-between">
                    <div className="col-md-auto">
                      <span className="share-links-title">Tags:</span>
                      <div className="tagcloud">
                        {blog?.tags?.map((tag, index) => (
                          <a key={index} href={`/blogs/tag/${tag}`}>
                            {tag}
                          </a>
                        ))}
                      </div>
                    </div>
                    <div className="icons text-xl-end">
                      <span className="share-links-title">Share:</span>
                      <a
                        className="facebook"
                        href="https://www.facebook.com/"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="https://www.twitter.com/">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="https://www.instagram.com/">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-4 col-lg-5">
            <aside className="sidebar-area">
              {/* <div className="widget widget_search">
                <form className="search-form">
                  <input type="text" placeholder="Search here..." />
                  <button type="submit">
                    <i className="far fa-search"></i>
                  </button>
                </form>
              </div> */}
              <div className="widget widget_tag_cloud">
                <h3 className="widget_title">Popular Tags</h3>
                <div className="tagcloud">
                  {blog?.tags?.map((tag, index) => (
                    <a key={index} href={`/blogs/tag/${tag}`}>
                      {tag}
                    </a>
                  ))}
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogDetailsArea;
