import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Utils/Contexts/authContext';
import '../../Assets/sass/scssImport.scss';
import '../../Assets/css/bootstrap.min.css';
import '../../Assets/css/fontawesome.min.css';
import '../../Assets/css/magnific-popup.min.css';
import '../../Assets/css/style.css.map';
import '../../Assets/css/swiper-bundle.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import '../../App.css';
import FooterBg from '../../Assets/img/bg/footer2-bg.png';
import JijiBg from '../../Assets/img/bg/jiji-bg.png';
import LogoSVG from '../../Assets/img/logo.svg';
import { Link } from 'react-router-dom';

const Footer1 = () => {
    const navigate = useNavigate();
    const { accessToken } = useContext(AuthContext);

    const handleRedirect = (e) => {
        e.preventDefault(); // Prevent default anchor behavior

        if (accessToken) {
            // Redirect to /home if the access token is available
            navigate('/home');
        } else {
            // Redirect to / if there's no access token
            navigate('/');
        }
    };

    const handleGameClick = (e) => {
        e.preventDefault();
        const accessToken = localStorage.getItem('accessToken');
        navigate(accessToken ? '/progames' : '/games');
    };

    const togglePWU = () => {
        navigate('/contact');
    };

    return (
        <>
            <footer className="footer-wrapper footer-layout2" data-bg-src={FooterBg}>
                <div className="container">
                    <div className="text-center">
                        <div className="footer-top-shape bg-repeat" data-bg-src={JijiBg}></div>
                    </div>
                </div>
                <div className="widget-area">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-md-6 col-xl-auto">
                                <div className="widget footer-widget">
                                    <div className="th-widget-about">
                                        <div className="about-logo">
                                            <a href="index.html">
                                                <span data-mask-src={LogoSVG} className="logo-mask"></span>
                                                <img src={LogoSVG} alt="Bame" />
                                            </a>
                                        </div>
                                        <p className="about-text">
                                            {' '}
                                            An ultimate esports platform where gamers can compete in tournaments, earn
                                            real cash rewards and connect with passionate gaming community
                                        </p>
                                        <h3 className="widget_title">
                                            Follow <span className="text-theme">With Us:</span>
                                        </h3>
                                        <div className="th-widget-contact">
                                            <div className="header-links">
                                                <ul className="th-social style-mask">
                                                    <li>
                                                            
                                                        <a className="discord" href="https://discord.gg/Mn9mVSVz" target="_blank" >
                                                            <i className="fab fa-discord"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="instagram"
                                                            href="https://www.instagram.com/agongamescom/" target="_blank"
                                                        >
                                                            <i className="fab fa-instagram"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="x-twitter" href="https://x.com/agongamescom" target="_blank">
                                                            <i className="fab fa-x-twitter"></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="youtube"
                                                            href="https://www.youtube.com/@Agongamescom" target="_blank"
                                                        >
                                                            <i className="fab fa-youtube"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-auto">
                                <div className="widget widget_nav_menu footer-widget">
                                    <h3 className="widget_title">Games</h3>
                                    <div className="menu-all-pages-container">
                                        <ul className="menu">
                                            <li>
                                                <a href="#" onClick={handleGameClick}>
                                                    Chess
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" onClick={handleGameClick}>
                                                    BGMI
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" onClick={handleGameClick}>
                                                    Free Fire Max
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" onClick={handleGameClick}>
                                                    Clash Of Clans
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" onClick={handleGameClick}>
                                                    Clash Royale
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" onClick={handleGameClick}>
                                                    Valorant
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" onClick={handleGameClick}>
                                                    League Of Legends
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-auto">
                                <div className="widget widget_nav_menu footer-widget">
                                    <h3 className="widget_title">Useful Links</h3>
                                    <div className="menu-all-pages-container">
                                        <ul className="menu">
                                            {/* <li>
                                                <Link to="/faq">FAQ</Link>
                                            </li>
                                            <li>
                                                <a href="contact.html">Blogs</a>
                                            </li>
                                            <li>
                                                <a onClick={togglePWU}>Contact Us</a>
                                            </li>
                                            <li>
                                                <a href="contact.html">Privacy Policy</a>
                                            </li>
                                            <li>
                                                <a href="contact.html">Terms & Conditions</a>
                                            </li>
                                            <li>
                                                <a href="contact.html">Guidelines</a>
                                            </li> */}

                                            <li>
                                                <Link to="/faq">FAQ</Link>
                                            </li>
                                            {/* <li><a href="contact.html">Blogs</a></li> */}
                                            <li>
                                                <Link to="/blog">Blogs</Link>
                                            </li>
                                            {/* <li><Link to="/blogDetailsArea">Blogs</Link></li> */}
                                            {/* <li><a href="contact.html">Contact Us</a></li> */}
                                            <li>
                                                <Link to="/contact">Contact Us</Link>
                                            </li>
                                            {/* <li><a href="contact.html">Privacy Policy</a></li> */}
                                            <li>
                                                <Link to="/privacy-policy">Privacy Policy</Link>
                                            </li>
                                            <li>
                                                <Link to="/terms-of-service">Terms & Conditions</Link>
                                            </li>
                                            <li>
                                                <Link to="/cookie-policy">Cookie Policy</Link>
                                            </li>

                                            {/* <li><a href="contact.html">Terms & Conditions</a></li> */}
                                            <li>
                                                <Link to="/acceptable-use-policy">Guidelines</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-auto">
                                <div className="widget newsletter-widget footer-widget">
                                    <h3 className="widget_title">Disclaimer</h3>
                                    <p className="footer-text">
                                        Credits : All images, logos, and trademarks displayed on this page are the
                                        property of their respective owners. AgonGames does not claim ownership,
                                        endorsement, or affiliation with any game or publisher.
                                    </p>
                                    {/* <form className="newsletter-form">
                                <div className="form-group">
                                    <input className="form-control" type="email" placeholder="Email Address" required=""/>
                                    <button type="submit" className="th-btn"><i className="fas fa-paper-plane"></i></button>
                                </div>
                                <div className="btn-wrap">
                                    <a href="#"><img src={FooterApple} alt="img"/></a>
                                    <a href="#"><img src={FooterPlayStore} alt="img"/></a>
                                </div>
                            </form> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-wrap text-center bg-repeat" data-bg-src={JijiBg}>
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6">
                                <p className="copyright-text bg-repeat" data-bg-src={JijiBg}>
                                    <i className="fal fa-copyright"></i> Copyright 2024{' '}
                                    <a href="#" onClick={handleRedirect}>
                                        Agon games
                                    </a>{' '}
                                    All Rights Reserved.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer1;
