import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import '../../App.css';
import 'jquery-ui/ui/widgets/slider'; // Example widget
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Cursor from '../../Components/Common/Cursor';
import Preloader from '../../Components/Common/Preloader';
import SideMenu2 from '../../Components/Common/SideMenu2';
import Footer2 from '../../Components/Common/Footer2';
import ScrollToTop from '../../Components/Common/ScrollToTop';
import ProTournamentsList2 from '../../Components/ProtectedComponents/proTournamentsList2';
import SimpleHeader3 from '../../Components/Common/SimpleHeader3';
import { BASE_URL } from '../../Utils/ApiServices/apiFunctions';
import { fetchWithAuth } from '../../Utils/ApiServices/apiFunctions';
import TournamentListMobile from '../../Components/MobileViewComp/tournamentListMobile';
import useScreenSize from '../../Utils/Contexts/useScreenSize';
import BottomNavbar from '../../Components/Common/BottomNavbar';
import GameArea from '../../Components/LandingPageComp/gameArea1';
import ProGameArea1 from '../../Components/ProtectedComponents/proGameArea1';

const ProGamesPage = () => {
    const location = useLocation(); // Get the location object
    const navigate = useNavigate(); // To navigate to the login page if not logged in
    // Access the state passed via navigate
    const message = location.state?.message;
    const isMobile = useScreenSize();
    const [gameHandles, setGameHandles] = useState({});
    // Add state to store selected game ID, starting with a default ID
    const defaultGameId = '75c0c41e-5d98-4d21-8e85-9c96ed240da1'; // Replace with actual default ID if needed
    const [selectedGameId, setSelectedGameId] = useState(location.state?.gameId || defaultGameId);

    // Handler for when a game is clicked
    const handleGameClick = (gameId) => {
        console.log('updating selected game ID here', gameId);
        setSelectedGameId(gameId); // Update the selected game ID
    };

    // Triggered once on mount if a success message exists in location state
    useEffect(() => {
        if (location.state?.successMessage) {
            // Clear the message after a delay
            setTimeout(() => {
                // Clear the success message from location state to prevent repeat
                navigate(location.pathname, { replace: true });
            }, 700000);
        }
    }, [location.pathname, location.state, navigate]);

    // Fetch Gamer Handle
    useEffect(() => {
        const fetchGamerHandle = async () => {
            try {
                const response = await fetchWithAuth(`${BASE_URL}/profile/get_gamer_handle`, { method: 'GET' });
                if (!response.ok) {
                    throw new Error(`Failed to fetch gamer handle: ${response.status} ${response.statusText}`);
                }
                const data = await response.json();
                console.log('Getting data using fetch with auth', data);
                if (data.handles && (Array.isArray(data.handles) || Object.keys(data.handles).length > 0)) {
                    const temp = {};
                    for (const key in data.handles) {
                        temp[data.handles[key]['game_id']] = data.handles[key]['gamer_handle'];
                        localStorage.setItem(
                            `gamerHandle-${data.handles[key]['game_id']}`,
                            data.handles[key]['gamer_handle'],
                        );
                    }
                    setGameHandles(temp);
                } else {
                    setGameHandles([]);
                }
            } catch (error) {
                console.error('Error fetching gamer handle:', error);
            }
        };
        fetchGamerHandle();
    }, []);

    return (
        <>
            {message && <div className="alert alert-success">{message}</div>}
            <Cursor />
            <Preloader />
            <SimpleHeader3 />
            <SideMenu2 />

            <div id="games">
                <ProGameArea1 gameHandles={gameHandles} callbackHandleClick={handleGameClick} />
            </div>

            <div id="tournament">
                {isMobile ? (
                    <TournamentListMobile
                        selectedGameId={selectedGameId}
                        gameHandles={gameHandles}
                        landing={false}
                        isScrollAvailable={true}
                    />
                ) : (
                    <ProTournamentsList2
                        selectedGameId={selectedGameId}
                        gameHandles={gameHandles}
                        landing={false}
                        isScrollAvailable={true}
                    />
                )}
            </div>
            <Footer2 />
            <BottomNavbar />
            <ScrollToTop />
            {/* <div>  {isMobile ? (null) : (<ScrollToTop />)}</div> */}
        </>
    );
};

export default ProGamesPage;
