import React from 'react';
import '../../App.css';
import $ from 'jquery'; // Import jQuery
import 'jquery-ui/ui/widgets/slider'; // Example widget
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Cursor from '../../Components/Common/Cursor';
import Preloader from '../../Components/Common/Preloader';
import SideMenu2 from '../../Components/Common/SideMenu2';
import ColorSwitcher from '../../Components/Common/ColorSwitcher';
import Footer2 from '../../Components/Common/Footer2';
import ScrollToTop from '../../Components/Common/ScrollToTop';
import FAQ from '../../Components/FAQPageComp/faq';
import SimpleHeader2 from '../../Components/Common/SimpleHeader2';
import MainHeader2 from '../../Components/Common/MainHeader2';
import SimpleHeader3 from '../../Components/Common/SimpleHeader3';
import BottomNavbar from '../../Components/Common/BottomNavbar';


const ProFAQPage = () => {
  return (
    <>
      <Cursor/>
      <Preloader/>
      <SimpleHeader3/>
      <SideMenu2/>
      {/* <ColorSwitcher/> */}
      <FAQ/>
      <Footer2/>
      <ScrollToTop/>
      <BottomNavbar />
    </>
  )
}

export default ProFAQPage
