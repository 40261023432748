import React, { useContext } from 'react';
import '../../App.css'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Utils/Contexts/authContext';

const LogoutComponent = () => {
  const { logout, accessToken } = useContext(AuthContext);
  const navigate = useNavigate();

  console.log('logout', logout);
  // Function to handle logout and redirect
  const handleLogout = () => {
    logout(); // Clear accessToken
    navigate('/'); // Redirect to login page
  };

  return (
    accessToken && (
      <>
        <div className='logout-container'>
          <div className='logout-card'>
            <button className='logout-btn' onClick={handleLogout}>
              Logout
            </button>
          </div>
        </div>
      </>
      
    )
  );
};

export default LogoutComponent;
