import React, { useState, useEffect } from 'react';
import '../../App.css';
import '../../CustomCss/customStyles.css';
import PremiumIcon from '../../Assets/img/icon/agon_premium_icon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faRankingStar,
    faTrophy,
    faShop,
    faBars,
    faUsers,
    faHandshake,
    faHouse,
} from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import LogoSVG from '../../Assets/img/logo.svg';

const BottomNavbar = () => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [isMobileView, setIsMobileView] = useState(false);
    const location = useLocation();

    const toggleDropdown = () => {
        setShowDropdown((prev) => !prev);
    };

    // Track screen size
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 1024); // Adjust breakpoint as needed
        };

        // Initial check
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.lobby')) {
                setShowDropdown(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    // Render only for mobile view
    if (!isMobileView) {
        return null;
    }
    // Function to check if the current link is active
    const getNavItemClass = (path) => {
        return location.pathname === path ? 'nav-item active' : 'nav-item';
    };
    const getLobbyClass = () => {
        return showDropdown ? 'nav-item active' : 'nav-item';
    };

    return (
        <div className="bottom-navbar">
            <a href="/proshop" className={getNavItemClass('/proshop')}>
                <FontAwesomeIcon icon={faShop} className="fa-icon" />
                <span>Shop</span>
            </a>
            <a href="/premium-account" className={getNavItemClass('/premium-account')}>
                <img src={PremiumIcon} alt="Agon Premium" style={{ height: '25px', verticalAlign: 'middle' }} />
                <span>Premium</span>
            </a>

            <a href="/progames" className={getNavItemClass('/progames')}>
                <FontAwesomeIcon icon={faTrophy} className="fa-icon" />
                <span>Tournaments</span>
            </a>

            <a href="/proleaderboard" className={getNavItemClass('/proleaderboard')}>
                <FontAwesomeIcon icon={faRankingStar} className="fa-icon" />
                <span>Leaderboard</span>
            </a>
            <div className="nav-item lobby" onClick={toggleDropdown}>
                <FontAwesomeIcon icon={faBars} className="fa-icon" />
                <span>Lobby</span>

                <div
                    className={`dropdown ${showDropdown ? 'show' : ''}`}
                    style={{
                        transform: showDropdown ? 'translateX(0)' : 'translateX(100%)', // Slide from right
                        transition: 'all ease 0.8s',
                        position: 'fixed',
                        color: '#fff',
                        // top: 0,
                        bottom: 0,
                        right: 0, // Fixed to the right side
                        height: '90%',
                        width: '60%', // Set your desired width
                        zIndex: 99999, // Ensure it stays above other elements
                        boxShadow: showDropdown ? '-2px 0 5px rgba(0,0,0,0.3)' : 'none',
                        backgroundColor: 'rgba(0, 0, 0, 0.8)', // Ensures correct RGBA usage
                    }}
                >
                    <div className="about-logo" style={{ margin: '20px' }}>
                        <Link to="/">
                            <span data-mask-src={LogoSVG} className="logo-mask"></span>
                            <img src={LogoSVG} alt="Bame" />
                        </Link>
                    </div>
                    <a
                        href="/agon-partners"
                        className="dropdown-item"
                        style={{
                            color: '#fff',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: '20px',
                            fontSize: '16px',
                        }}
                    >
                        <FontAwesomeIcon icon={faUsers} />
                        <span style={{ fontSize: '16px' }}>Agon Partners</span>
                    </a>
                    <a
                        href="/partner-with-us"
                        className="dropdown-item"
                        style={{
                            color: '#fff',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: '20px',
                            fontSize: '16px',
                        }}
                    >
                        <FontAwesomeIcon icon={faHandshake} />
                        <span style={{ fontSize: '16px' }}>Partner</span>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default BottomNavbar;
