import React, { useEffect } from 'react';
import '../../App.css';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup';
import 'magnific-popup/dist/magnific-popup.css';
import { Link } from 'react-router-dom';

const FirstLoginPopUp = ({ onClose }) => {
    useEffect(() => {
        if (true) return;
        // Open the popup manually when the component mounts
        $.magnificPopup.open({
            items: {
                src: '#QuickView', // Ensure this element exists in the DOM
            },
            type: 'inline',
        });

        // Cleanup function to close the popup and unbind events on component unmount
        return () => {
            localStorage.removeItem('new_user');
            if ($.magnificPopup.instance.isOpen) {
                $.magnificPopup.close(); // Close the popup if it's open
            }
        };
    }, []);

    return (
        <>
            <div id="QuickView" className="white-popup mfp-hide">
                <div className="container bg-black3 rounded-10" style={{ maxWidth: '64%' }}>
                    <div className="row gx-10">
                        <div className="col-lg-10 align-self-center">
                            <div className="popup-padding">
                                <h2
                                    className="product-title"
                                    style={{
                                        fontWeight: '800',
                                        fontSize: '50px',
                                        marginLeft: '17%',
                                    }}
                                >
                                    Welcome to AgonGames
                                </h2>
                                <p
                                    className="text"
                                    style={{
                                        fontWeight: '400',
                                        fontSize: '18px',
                                        marginLeft: '22%',
                                    }}
                                >
                                    Start earning for your in game skills. Kickstart with 50 Acoins from us
                                </p>
                                <div className="hiw-section">
                                    <div className="hiw-top-row" style={{ display: 'flex' }}>
                                        <div className="hiw-card">
                                            <div className="hiw-card-icon">
                                                <i className="fa fa-swords"></i>
                                            </div>
                                            <div className="hiw-content">
                                                <h3>Join a tournament</h3>
                                                <p>
                                                    Start earning by winning tournaments.First 5 tournaments are free to
                                                    join
                                                </p>
                                            </div>
                                        </div>

                                        <div className="hiw-card">
                                            <div className="hiw-card-icon">
                                                <i className="fa fa-dollar-sign"></i>
                                            </div>
                                            <div className="hiw-content">
                                                <h3>Great Rewards</h3>
                                                <p>
                                                    Complete simple goals to earn ACoins which can be used to join
                                                    tournaments
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="hiw-card" style={{ marginLeft: '23%' }}>
                                        <div className="hiw-card-icon">
                                            <i className="fa fa-user"></i>
                                        </div>
                                        <div className="hiw-content">
                                            <h3>Complete Profile</h3>
                                            <p>
                                                Complete your profile so that other gamers can connect with you easily
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="actions"
                                    style={{
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                        marginLeft: '48%',
                                    }}
                                >
                                    <button className="th-btn">
                                        <Link
                                            className="tournaments-link"
                                            style={{
                                                color: '#0B0E13',
                                                marginRight: '-2%',
                                            }}
                                            to="/progames"
                                        >
                                            Let's Go
                                        </Link>
                                        <span className="icon">
                                            <i className="fa-solid fa-arrow-right ms-3"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button title="Close (Esc)" type="button" className="mfp-close text-white" onClick={onClose}>
                        ×
                    </button>
                </div>
            </div>
        </>
    );
};

export default FirstLoginPopUp;
