import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Waypoint from 'waypoints/lib/noframework.waypoints';
import SmoothScroll from 'smooth-scroll';
import WOW from 'wowjs';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import { gsap } from 'gsap';
import Swiper from 'swiper';
import 'swiper/css'; // Import base CSS
import 'swiper/css/navigation'; // Import navigation CSS if used
import 'swiper/css/pagination'; // Import pagination CSS if used
import './DetailedDash.css'; // Import the CSS file
import '../../../Assets/sass/style.scss';
import FeatureCardBg from '../../../Assets/img/feature/feature-card-bg.png';
import Feature1_1 from '../../../Assets/img/feature/feature1-1.png'; //PRL - This is image is for testing.. Replace it as soon as original image is received
import Feature1_2 from '../../../Assets/img/feature/feature1-2.png';
import Feature1_3 from '../../../Assets/img/feature/feature1-3.png';
import ProGameCard3 from '../../ProtectedComponents/proGameCard3';
import GameSec1Bg from '../../../Assets/img/hero/hero-bg1-1.jpg';
import { AuthContext } from '../../../Utils/Contexts/authContext';
import ProGameCard4 from '../../ProtectedComponents/ProGameCard4';
import { fetchWithAuth } from '../../../Utils/ApiServices/apiFunctions';
import Dashboard from '../Dashboard/Dashboard';
// import '../../../App.css';
import { BASE_URL } from '../../../Utils/ApiServices/apiFunctions';
import GameArea2 from '../../GamePageComp/gameArea2';
import GameArea from '../../LandingPageComp/gameArea1';

const defaultGameTab = '75c0c41e-5d98-4d21-8e85-9c96ed240da1';

const DetailedDash = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [games, setGames] = useState([]);
    const [selectedGameId, setSelectedGameId] = useState(defaultGameTab);
    const [gameStats, setGameStats] = useState({});
    const navigate = useNavigate();
    const { accessToken } = useContext(AuthContext);

    useEffect(() => {
        const fetchGames = async () => {
            setIsLoading(true);
            try {
                const response = await fetchWithAuth(`${BASE_URL}/games`);
                const data = await response.json();
                setGames([...data.games]); // Remove duplicate logic later
            } catch (e) {
                setError(e.message);
            } finally {
                setIsLoading(false);
            }
        };
        fetchGames();
    }, []);

    // Fetch statistics for the selected game
    useEffect(() => {
        const fetchGameStats = async () => {
            try {
                const response = await fetchWithAuth(`${BASE_URL}/user/game_stats/${selectedGameId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                const data = await response.json();
                console.log('DetailedDash response : ', data);
                if (data.code === 200) {
                    setGameStats({
                        tournamentsPlayed: data.user_game_stats.total_tournaments_played,
                        percentages: `${data.user_game_stats.win_percentage ?? 'N/A'}% | ${
                            data.user_game_stats.top_5_percentage ?? 'N/A'
                        }%`, // Display percentages in the correct format
                        totalEarnings: data.user_game_stats.total_earnings ?? 'N/A', // Handle null value
                    });
                } else {
                    throw new Error('Failed to fetch game statistics');
                }
            } catch (e) {
                console.error('Error fetching game statistics:', e.message);
                setError(e.message);
            }
        };
        fetchGameStats();
    }, [selectedGameId, accessToken]);

    // Initialize Swiper on games data change
    useEffect(() => {
        // Initialize Swiper slider
        const gameSwiper = new Swiper('.game-slider', {
            slidesPerView: '1',
            spaceBetween: 24,
            loop: true,
            speed: 1000,
            autoplay: { delay: 6000, disableOnInteraction: false },
            navigation: {
                nextEl: '.game-slider-next',
                prevEl: '.game-slider-prev',
            },
            pagination: {
                el: '.game-slider-pagination',
                clickable: true,
                renderBullet: function (index, className) {
                    return `<span class="${className}" aria-label="Go to Slide ${index + 1}"></span>`;
                },
            },
            breakpoints: {
                576: { slidesPerView: 1 },
                768: { slidesPerView: 2 },
                992: { slidesPerView: 3 },
                1200: { slidesPerView: 3 },
            },
        });
    
        // Add animations
        $('[data-ani]').each(function () {
            const animationName = $(this).data('ani');
            $(this).addClass(animationName);
        });
    
        $('[data-ani-delay]').each(function () {
            const delayTime = $(this).data('ani-delay');
            $(this).css('animation-delay', delayTime);
        });
    
        // External navigation handlers
        $('[data-slider-prev], [data-slider-next]').on('click', function () {
            const sliderSelector = $(this).data('slider-prev') || $(this).data('slider-next');
            const targetSlider = $(sliderSelector);
    
            if (targetSlider.length) {
                const swiperInstance = targetSlider[0].swiper;
                if (swiperInstance) {
                    if ($(this).data('slider-prev')) {
                        swiperInstance.slidePrev();
                    } else {
                        swiperInstance.slideNext();
                    }
                }
            }
        });
    
        // return () => {
        //   // Cleanup Swiper instance if necessary
        //   if (swiper) swiper.destroy();
        // };
    }, [games]); // Re-run slider initialization when games change

    const handleGameClick = (gameId) => {
        // console.log(gameId);
        setSelectedGameId(gameId);
    };

    // Variations for featureImage and gameDescription
    const featureImages = [Feature1_1, Feature1_3, Feature1_2];
    const gameDescriptions = [
        'Successful esports teams exhibit strong communication, strategic.',
        'The rise of mobile gaming has significantly expanded the gaming.',
        'It has also influenced game design reaching a broader demographic.',
    ];

    const stats = [
        // { icon: 'fa-trophy', label: 'Tournaments Entered', value: 0 },
        // { icon: 'fa-trophy-alt', label: 'Trophies Per Game (Average)', value: 0 },
        // { icon: 'fa-gamepad', label: 'Games Played Brawl Stars', value: 0 },
        // { icon: 'fa-coins', label: 'Earnings (Per tournament)', value: 0 },
        {
            icon: 'fa-gamepad',
            label: 'Tournaments Played',
            value: gameStats.tournamentsPlayed ?? 0,
        },
        {
            icon: 'fa-trophy',
            label: 'Win % | Top 5 %',
            value: gameStats.percentages ?? 'N/A',
        },
        {
            icon: 'fa-medal',
            label: 'Total Earnings',
            value: gameStats.totalEarnings ?? 'N/A',
        },
    ];

    if (isLoading) {
        return <div>Loading.....</div>;
    }

    if (error) {
        return <div>Something went wrong! Please try again later..</div>;
    }

    return (
        <div className="dashboard-page-container" style={{ backgroundColor: 'black' }}>
            <Dashboard />
            <div className="detailedDash-container">
                <div style={{ marginTop: '0px' }}>
                    <div
                        className="title-area text-center custom-anim-top wow animated"
                        data-wow-duration="1.5s"
                        data-wow-delay="0.1s"
                    >
                        <span className="sub-title"># Epic Clash</span>
                        <h2 className="sec-title">
                            Game On, Level Up, Win Big <span className="text-theme">!</span>
                        </h2>
                    </div>
                    <div className="game-area-container ">
                        <div className="">
                            <div className="game-slider-1 custom-game-card-container">
                                <div className="swiper-wrapper">
                                    {games.map((game, index) => (
                                        <div className="" key={game.game_id}>
                                            <ProGameCard4
                                                gameId={game.game_id}
                                                featureCardBg={FeatureCardBg}
                                                featureImage={featureImages[index % featureImages.length]}
                                                gameTitle={game.name}
                                                gameDescription={gameDescriptions[index % gameDescriptions.length]}
                                                isAccountConnected={false}
                                                handleGameClick={handleGameClick}
                                            />
                                        </div>
                                    ))}
                                </div>
                                <div className="swiper-pagination slider-pagination"></div>
                            </div>
                            <button data-slider-prev="#featureGameSlider1" className="slider-arrow style3 slider-prev" style={{display:'flex', alignItems:'center',
                        justifyContent:'center'
                    }}>
                        <i className="fas fa-angle-left"></i>
                    </button>
                    <button data-slider-next="#featureGameSlider1" className="slider-arrow style3 slider-next" style={{display:'flex', alignItems:'center',
                        justifyContent:'center'
                    }}>
                        <i className="fas fa-angle-right"></i>
                    </button>
                        </div>
                    </div>
                 </div> 
            {/* <GameArea /> */}
                {/* Statistics Section */}
                <div className="stats-container">
                    {stats.map((stat, index) => (
                        <div key={index} className="stat-card">
                            <i className={`fa ${stat.icon} stat-icon`}></i>
                            <p>{stat.label}</p>
                            <h2>{stat.value}</h2>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DetailedDash;
