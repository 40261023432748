import React, { useEffect, useRef } from 'react';
import '../../App.css';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Waypoint from 'waypoints/lib/noframework.waypoints';
import SmoothScroll from 'smooth-scroll';
import WOW from 'wowjs';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import { gsap } from 'gsap';

const Cursor = () => {
  // const cursorRef = useRef(null);
  // const followerRef = useRef(null);

  // useEffect(() => {
  //   // Initialize WOW.js for scroll animations
  //   const wow = new WOW.WOW({
  //     boxClass: 'wow',
  //     animateClass: 'animated',
  //     offset: 0,
  //     mobile: false,
  //     live: true,
  //   });
  //   wow.init();

  //   let posX = 0,
  //     posY = 0;
  //   let mouseX = 0,
  //     mouseY = 0;

  //   // Ensure refs are not null before applying GSAP animations
  //   if (followerRef.current && cursorRef.current) {
  //     // GSAP animation for cursor follower
  //     gsap.to({}, 0.016, {
  //       repeat: -1,
  //       onRepeat: () => {
  //         posX += (mouseX - posX) / 45;
  //         posY += (mouseY - posY) / 45;

  //         gsap.set(followerRef.current, {
  //           css: {
  //             left: posX - 75,
  //             top: posY - 75,
  //           },
  //         });

  //         gsap.set(cursorRef.current, {
  //           css: {
  //             left: mouseX - 5,
  //             top: mouseY - 5,
  //           },
  //         });
  //       },
  //     });
  //   }

  //   // Mousemove event to update cursor position
  //   const handleMouseMove = (e) => {
  //     mouseX = e.clientX;
  //     mouseY = e.clientY;
  //   };

  //   // Hover effects for interactive elements
  //   const handleMouseEnter = () => {
  //     if (cursorRef.current && followerRef.current) {
  //       cursorRef.current.classList.add('active');
  //       followerRef.current.classList.add('active');
  //     }
  //   };

  //   const handleMouseLeave = () => {
  //     if (cursorRef.current && followerRef.current) {
  //       cursorRef.current.classList.remove('active');
  //       followerRef.current.classList.remove('active');
  //     }
  //   };

  //   document.addEventListener('mousemove', handleMouseMove);
  //   const buttons = document.querySelectorAll('.btn, a, .interactive');
  //   buttons.forEach((button) => {
  //     button.addEventListener('mouseenter', handleMouseEnter);
  //     button.addEventListener('mouseleave', handleMouseLeave);
  //   });

  //   // Cleanup event listeners on component unmount
  //   return () => {
  //     document.removeEventListener('mousemove', handleMouseMove);
  //     buttons.forEach((button) => {
  //       button.removeEventListener('mouseenter', handleMouseEnter);
  //       button.removeEventListener('mouseleave', handleMouseLeave);
  //     });
  //   };
  // }, []);

  return (
    <>
      {/* <div ref={cursorRef} className="cursor-animation cursor-image"></div>
      <div ref={followerRef} className="cursor-follower"></div> */}
    </>
  );
};

export default Cursor;
