// FindGameAccount.js
import React from 'react';
import '../../CustomCss/findGameAccount.css';
import { FaChessKing, FaFireAlt, FaUserFriends, FaUserCircle } from 'react-icons/fa';
import { MdPerson } from 'react-icons/md'; // For hand pointing icon

const FindGameAccount = () => {
  return (
    <div className="find-game-account-container">
      <div className="left-side">
        <div className="recommended-match">
          <div className="user-info">
            <FaUserCircle size={40} className="avatar" />
            <div className="match-info">
              <p className="title">Recommended Match</p>
              <p className="friend">Friend</p>
              <p className="name">Username (1239)</p>
              <p className="score">OW / OD / OL</p>
            </div>
          </div>
          <div className="chessboard">
            {/* Use an image of the chessboard or any chessboard component */}
            <div className="board">[ Chessboard here ]</div>
          </div>
        </div>

        <div className="play-online">
          <div className="game-info">
            <p>Play Online</p>
            <p>5 min vs Random</p>
            <p>#15</p>
          </div>
          <div className="chessboard">
            <div className="board">[ Another Chessboard ]</div>
          </div>
        </div>
      </div>

      <div className="right-side">
        <div className="profile-header">
          <FaUserCircle size={80} className="avatar-large" />
          <div className="profile-details">
            <p className="username">bi________</p>
            <p>India <span role="img" aria-label="flag">🇮🇳</span></p>
            <p>Joined 24-Feb-2012</p>
          </div>
        </div>
        <div className="stats">
          <div className="stat-item">
            <p>Blitz</p>
            <p>1717</p>
          </div>
          <div className="stat-item">
            <p>Rapid</p>
            <p>1750</p>
          </div>
          <div className="stat-item">
            <p>Bullet</p>
            <p>1569</p>
          </div>
          <div className="stat-item">
            <p>Daily</p>
            <p>727</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindGameAccount;
