import React, { createContext, useContext, useMemo, useState, useEffect } from 'react';
import { BASE_URL } from '../ApiServices/apiFunctions';
import posthog from 'posthog-js';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [userProfile, setUserProfile] = useState(null);
    const [productDetails, setProductDetails] = useState([]);

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const response = await fetch(`${BASE_URL}/marketplace/rewards`);
                if (!response.ok) {
                    throw new Error(`Failed to fetch rewards: ${response.status} ${response.statusText}`);
                }
                const data = await response.json();
                const vouchersWithSplitDenominations = data.rewards.data.getVouchers.data.flatMap((voucher) => {
                    const denominations = voucher.valueDenominations.split(',');
                    return denominations.map((denomination) => ({
                        ...voucher,
                        valueDenominations: denomination.trim(),
                    }));
                });
                setProductDetails(vouchersWithSplitDenominations);
            } catch (e) {
                console.error('Error fetching product details:', e);
            }
        };
        fetchProductDetails();
    }, []);

    useEffect(() => {
        if (userProfile && posthog) {
            posthog.identify(
                userProfile.user_id, // Replace 'distinct_id' with your user's unique identifier
                { email: userProfile.email, phone: userProfile.phone }, // optional: set additional person properties
            );
        }
    }, [userProfile]);

    useEffect(() => {
        try {
            // Only process URL parameters if we're not already on the login page
            const url = new URL(window.location.href);
            const params = Array.from(url.searchParams.entries())
                .map(([key, value]) => `${key}: ${value}`)
                .join(', ');
            console.log('URL Parameters:', params);

            const referralCode = url.searchParams.get('r');
            if (referralCode) {
                // Check if we already have this referral code to prevent unnecessary updates
                const existingCode = localStorage.getItem('referralCode');
                if (existingCode !== referralCode) {
                    localStorage.setItem('referralCode', referralCode);
                    console.log('New referral code stored:', referralCode);
                } else {
                    console.log('Referral code already exists:', referralCode);
                }
            }
        } catch (error) {
            console.error('Error processing URL parameters:', error);
        }
    });

    // Memoize the context value to prevent unnecessary re-renders
    const value = useMemo(
        () => ({
            userProfile,
            setUserProfile,
            productDetails,
            setProductDetails,
        }),
        [userProfile, productDetails],
    );

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

// Custom hook to use the app context
export const useAppContext = () => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};
