import React, { useEffect, useState } from 'react';
import '../../App.css';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Waypoint from 'waypoints/lib/noframework.waypoints';
import SmoothScroll from 'smooth-scroll';
import WOW from 'wowjs';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import { gsap } from 'gsap';
import { Link } from 'react-router-dom';
import LogoSVG from '../../Assets/img/logo.svg';
import TwitterSVG from '../../Assets/img/icon/x-twitter-icon.svg';
import InstagramSVG from '../../Assets/img/icon/instagram-icon.svg';
import Googleplay from '../../Assets/img/icon/google-playstore-icon.svg';

const SideMenu2 = ({ isMenuOpen, toggleMenu }) => {
    //     const [isMenuOpen, setIsMenuOpen] = useState(false);

    //   const toggleMenu = (e) => {
    //     e.preventDefault();
    //     setIsMenuOpen(!isMenuOpen);
    //   };

    //   const closeMenu = (e) => {
    //     e.stopPropagation();
    //     setIsMenuOpen(false);
    //   };

    return (
        <>
            <div
                className={`sidemenu-wrapper sidemenu-info ${isMenuOpen ? 'show' : ''}`}
                // onClick={toggleMenu}
                onClick={(e) => e.stopPropagation()}
                style={{
                    transform: isMenuOpen ? 'translateX(0)' : 'translateX(100%)', // Slide from right
                    transition: 'all ease 0.8s',
                    position: 'fixed',
                    top: 0,
                    right: 0, // Fixed to the right side
                    height: '100%',
                    width: '100%', // Set your desired width
                    zIndex: 99999, // Ensure it stays above other elements
                    boxShadow: isMenuOpen ? '-2px 0 5px rgba(0,0,0,0.3)' : 'none',
                }}
            >
                <div className="sidemenu-content">
                    <button className="closeButton sideMenuCls" onClick={toggleMenu}>
                        <i className="far fa-times"></i>
                    </button>
                    <div className="widget footer-widget">
                        <div className="th-widget-about">
                            <div className="about-logo">
                                <Link to="/">
                                    <span data-mask-src={LogoSVG} className="logo-mask"></span>
                                    <img src={LogoSVG} alt="Bame" />
                                </Link>
                            </div>
                            <p className="about-text">
                                {' '}
                                An ultimate esports platform where gamers can compete in tournaments, earn real cash
                                rewards and connect with passionate gaming community
                            </p>
                            <h3 className="widget_title">
                                Follow <span className="text-theme">With Us:</span>
                            </h3>
                            <div className="th-widget-contact">
                                <div className="th-social style-mask">
                                    <a className="facebook" href="https://www.facebook.com/">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                    <a className="twitter" href="https://www.twitter.com/">
                                        <img src={TwitterSVG} alt="icon" />
                                    </a>
                                    <a className="instagram" href="https://www.instagram.com/">
                                        <img src={InstagramSVG} alt="icon" />
                                    </a>
                                    <a className="linkedin" href="https://www.linkedin.com/">
                                        <i className="fab fa-linkedin"></i>
                                    </a>
                                    <a className="google-play" href="https://www.google.com/">
                                        <img src={Googleplay} alt="icon" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="widget  ">
                        <h3 className="widget_title">Recent Posts</h3>
                        <div className="recent-post-wrap">
                            <div className="recent-post">
                                <div className="media-img">
                                    <Link to="/blog-details">
                                        <img src="assets/img/blog/recent-post-1-1.jpg" alt="Blog-Image" />
                                    </Link>
                                </div>
                                <div className="media-body">
                                    <h4 className="post-title">
                                        <Link className="text-inherit" to="/blog-details">
                                            A Day in the Life of an Esports Event & Enjoy
                                        </Link>
                                    </h4>
                                    <div className="recent-post-meta">
                                        <Link to="/blog">
                                            <i className="fa-light fa-calendar"></i>30 Nov, 2024
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="recent-post">
                                <div className="media-img">
                                    <Link to="/blog-details">
                                        <img src="assets/img/blog/recent-post-1-2.jpg" alt="Blog-Image" />
                                    </Link>
                                </div>
                                <div className="media-body">
                                    <h4 className="post-title">
                                        <Link className="text-inherit" to="/blog-details">
                                            Strategies for Dominating Your Favorite Game
                                        </Link>
                                    </h4>
                                    <div className="recent-post-meta">
                                        <Link to="/blog">
                                            <i className="fa-light fa-calendar"></i>05 Dec, 2024
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="recent-post">
                                <div className="media-img">
                                    <Link to="/blog-details">
                                        <img src="assets/img/blog/recent-post-1-3.jpg" alt="Blog-Image" />
                                    </Link>
                                </div>
                                <div className="media-body">
                                    <h4 className="post-title">
                                        <Link className="text-inherit" to="/blog-details">
                                            Behind the Scenes of Your Favorite Game
                                        </Link>
                                    </h4>
                                    <div className="recent-post-meta">
                                        <Link to="/blog">
                                            <i className="fa-light fa-calendar"></i>09 Sep, 2024
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="widget newsletter-widget  ">
                <h3 className="widget_title">Newsletter</h3>
                <p className="footer-text">Download our app! <br/>
                    For seamless access to tournaments, real-time progress tracking, and instant updates. Play, win, and earn rewards on the go!
                    Download Android</p>
                <form className="newsletter-form">
                    <div className="form-group">
                        <input className="form-control" type="email" placeholder="Email Address" required=""/>
                        <button type="submit" className="th-btn"><i className="far fa-paper-plane"></i></button>
                    </div>
                </form>
            </div> */}
                </div>
            </div>
            <div className="popup-search-box d-none d-lg-block">
                <button className="searchClose">
                    <i className="fal fa-times"></i>
                </button>
                <form action="#">
                    <input type="text" placeholder="What are you looking for?" />
                    <button type="submit">
                        <i className="fal fa-search"></i>
                    </button>
                </form>
            </div>
        </>
    );
};

export default SideMenu2;
