import React , { useEffect, useState } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import $ from 'jquery'; // Import jQuery
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'magnific-popup/dist/magnific-popup.css';
import 'magnific-popup';
import Waypoint from 'waypoints/lib/noframework.waypoints';
import SmoothScroll from 'smooth-scroll';
import WOW from 'wowjs';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import { gsap } from 'gsap'; 

const ProductDetails = () => {
  return (
    <>
      <section className="product-details space-top space-extra-bottom" style={{backgroundColor:"black" , marginTop:"100px"}}>
        <div className="container">
            <div className="row gx-60">
                <div className="col-lg-6">
                    <div className="product-big-img">
                        <div className="img"><img src="assets/img/product/product_details_1_1.png" alt="Product-Image"/></div>
                    </div>
                </div>
                <div className="col-lg-6 align-self-center">
                    <div className="product-about">
                        <p className="price">$120.85<del>$150.99</del></p>
                        <h2 className="product-title">Microphone G9000</h2>
                        <div className="product-rating">
                            <div className="star-rating" role="img" aria-label="Rated 4.00 out of 5">
                                <span style={{width:"100%"}}>Rated <strong className="rating">4.00</strong> out of 5 based on <span className="rating">1</span> customer rating</span></div>
                            <a to="/shop-details" className="woocommerce-review-link">(<span className="count">4</span> customer reviews)</a>
                        </div>
                        <p className="text">Prepare to embark on a sensory journey with the Bosco Apple, a fruit that transcends the ordinary and promises an unparalleled taste experience. These apples are nothing short of nature’s masterpiece, celebrated for their distinctive blend of flavors and their captivating visual allure.</p>
                        <div className="mt-2 link-inherit">
                            <p>
                                <strong className="text-white me-3">Availability:</strong>
                                <span className="stock in-stock"><i className="far fa-check-square me-2 ms-1"></i>In Stock</span>
                            </p>
                        </div>
                        <div className="actions">
                            <div className="quantity">
                                <input type="number" className="qty-input" step="1" min="1" max="100" name="quantity" value="1" title="Qty"/>
                                <button className="quantity-plus qty-btn"><i className="far fa-chevron-up"></i></button>
                                <button className="quantity-minus qty-btn"><i className="far fa-chevron-down"></i></button>
                            </div>
                            <button className="th-btn">Add to Cart <span className="icon"><i className="fa-solid fa-arrow-right ms-2"></i></span></button>
                            <Link to="/wishlist" className="icon-btn"><i className="far fa-heart"></i></Link>
                        </div>
                        <div className="product_meta">
                            <span className="sku_wrapper">SKU: <span className="sku">Wheel-fits-chevy-camaro</span></span>
                            <span className="posted_in">Category: <Link to="/shop">Dresses & Bags</Link></span>
                            <span>Tags: <Link to="/shop">Product</Link><Link to="/shop">Bags</Link></span>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="nav product-tab-style1" id="productTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <a className="nav-link th-btn" id="description-tab" data-bs-toggle="tab" href="#description" role="tab" aria-controls="description" aria-selected="false">Product Description</a>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link th-btn active" id="reviews-tab" data-bs-toggle="tab" href="#reviews" role="tab" aria-controls="reviews" aria-selected="true">Customer Reviews</a>
                </li>
            </ul>
            <div className="tab-content" id="productTabContent">
                <div className="tab-pane fade" id="description" role="tabpanel" aria-labelledby="description-tab">
                    <p className="mt-n2">Prepare to embark on a sensory journey with the Bosco Apple, a fruit that transcends the ordinary and promises an unparalleled taste experience. These apples are nothing short of nature’s masterpiece, celebrated for their distinctive blend of flavors and their captivating visual allure. Bosco Apples are a revelation for your taste buds. Their impeccable balance of sweetness and tartness creates a complex, layered taste profile that keeps you coming back for more. Each bite is a symphony of sweet and tangy notes, making these apples a gastronomic delight whether enjoyed fresh or as a key ingredient in your favorite culinary creations.</p>
                    <p>The Bosco Apple is not just a fruit; it’s a work of art. Its striking, deep red skin is adorned with intricate speckles, making it a showstopper in the world of fruit aesthetics. Display these apples with pride in your kitchen, and you’ll undoubtedly draw admiration from all who see them. We take our commitment to the environment seriously. Bosco Apples are cultivated on eco-friendly orchards that prioritize sustainable farming practices. You can savor these apples knowing they are grown with respect for the planet, aligning with your values of environmental responsibility. Whether you’re a culinary aficionado or a casual home chef, Bosco Apples offer limitless possibilities in the kitchen. Slice them into a vibrant salad, transform them into mouthwatering pies, or blend them into wholesome applesauce. The culinary canvas is yours to paint with these versatile apples.</p>
                </div>
                <div className="tab-pane fade show active" id="reviews" role="tabpanel" aria-labelledby="reviews-tab">
                    <div className="woocommerce-Reviews">
                        <div className="th-comments-wrap ">
                            <ul className="comment-list">
                                <li className="review th-comment-item">
                                    <div className="th-post-comment">
                                        <div className="comment-avater">
                                            <img src="assets/img/blog/comment-author-1.jpg" alt="Comment-Author"/>
                                        </div>
                                        <div className="comment-content">
                                            <h4 className="name">Adam Jhon</h4>
                                            <span className="commented-on"><i className="far fa-calendar"></i>22 April, 2024</span>
                                            <div className="star-rating" role="img" aria-label="Rated 5.00 out of 5">
                                                <span style={{width:"100%"}}>Rated <strong className="rating">5.00</strong> out of 5 based on <span className="rating">1</span> customer rating</span>
                                            </div>
                                            <p className="text">This product is very much qualityful and I love this working system and speed.</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="review th-comment-item">
                                    <div className="th-post-comment">
                                        <div className="comment-avater">
                                            <img src="assets/img/blog/comment-author-2.jpg" alt="Comment-Author"/>
                                        </div>
                                        <div className="comment-content">
                                            <h4 className="name">Jusctin Dacon</h4>
                                            <span className="commented-on"><i className="far fa-calendar"></i>26 April, 2024</span>
                                            <div className="star-rating" role="img" aria-label="Rated 5.00 out of 5">
                                                <span style={{width:"100%"}}>Rated <strong className="rating">5.00</strong> out of 5 based on <span className="rating">1</span> customer rating</span>
                                            </div>
                                            <p className="text">They delivered the product in a few time. Product quality is also very good.</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="review th-comment-item">
                                    <div className="th-post-comment">
                                        <div className="comment-avater">
                                            <img src="assets/img/blog/comment-author-3.jpg" alt="Comment-Author"/>
                                        </div>
                                        <div className="comment-content">
                                            <h4 className="name">Jacklin July</h4>
                                            <span className="commented-on"><i className="far fa-calendar"></i>26 April, 2024</span>
                                            <div className="star-rating" role="img" aria-label="Rated 5.00 out of 5">
                                                <span style={{width:"100%"}}>Rated <strong className="rating">5.00</strong> out of 5 based on <span className="rating">1</span> customer rating</span>
                                            </div>
                                            <p className="text">Their product and service is very satisfying. I highly recommend their services.</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="review th-comment-item">
                                    <div className="th-post-comment">
                                        <div className="comment-avater">
                                            <img src="assets/img/blog/comment-author-4.jpg" alt="Comment-Author"/>
                                        </div>
                                        <div className="comment-content">
                                            <h4 className="name">Adison Smith</h4>
                                            <span className="commented-on"><i className="far fa-calendar"></i>26 April, 2024</span>
                                            <div className="star-rating" role="img" aria-label="Rated 5.00 out of 5">
                                                <span style={{width:"100%"}}>Rated <strong className="rating">5.00</strong> out of 5 based on <span className="rating">1</span> customer rating</span>
                                            </div>
                                            <p className="text">I am just in love with this product. Their service is also very good you can also try.</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                         </div> {/*<!-- Comment Form --> */}
                        <div className="th-comment-form ">
                            <div className="form-title">
                                <h3 className="blog-inner-title ">Add a review</h3>
                            </div>
                            <div className="row">
                                <div className="form-group rating-select d-flex align-items-center">
                                    <label>Your Rating</label>
                                    <p className="stars">
                                        <span>
                                            <a className="star-1" href="#">1</a>
                                            <a className="star-2" href="#">2</a>
                                            <a className="star-3" href="#">3</a>
                                            <a className="star-4" href="#">4</a>
                                            <a className="star-5" href="#">5</a>
                                        </span>
                                    </p>
                                </div>
                                <div className="col-12 form-group style-border">
                                    <textarea placeholder="Write a Message" className="form-control"></textarea>
                                    <i className="far fa-pencil-alt"></i>
                                </div>
                                <div className="col-md-6 form-group style-border">
                                    <input type="text" placeholder="Your Name" className="form-control"/>
                                    <i className="far fa-user"></i>
                                </div>
                                <div className="col-md-6 form-group style-border">
                                    <input type="text" placeholder="Your Email" className="form-control"/>
                                    <i className="far fa-envelope"></i>
                                </div>
                                <div className="col-12 form-group">
                                    <input id="reviewcheck" name="reviewcheck" type="checkbox"/>
                                    <label for="reviewcheck">Save my name, email, and website in this browser for the next time I comment.<span className="checkmark"></span></label>
                                </div>
                                <div className="col-12 form-group mb-0">
                                    <button className="th-btn">Post Review <span className="icon"><i className="fa-solid fa-arrow-right ms-2"></i></span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!--==============================
		Related Product  
		==============================--> */}
            <div className="space-extra-top mb-30">
                <div className="row justify-content-between align-items-center">
                    <div className="col-md-auto">
                        <h2 className="sec-title text-center">Related Products</h2>
                    </div>
                    <div className="col-md d-none d-sm-block">
                        <hr className="title-line"/>
                    </div>
                    <div className="col-md-auto d-none d-md-block">
                        <div className="sec-btn">
                            <div className="icon-box">
                                <button data-slider-prev="#productSlider1" className="slider-arrow default"><i className="far fa-arrow-left"></i></button>
                                <button data-slider-next="#productSlider1" className="slider-arrow default"><i className="far fa-arrow-right"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="swiper th-slider has-shadow" id="productSlider1" data-slider-options='{"breakpoints":{"0":{"slidesPerView":1},"576":{"slidesPerView":"2"},"768":{"slidesPerView":"2"},"992":{"slidesPerView":"3"},"1200":{"slidesPerView":"4"}}}'>
                    <div className="swiper-wrapper">

                        <div className="swiper-slide">
                            <div className="th-product product-grid">
                                <div className="product-img">
                                    <img src="assets/img/product/product_1_1.png" alt="Product-Image"/>
                                    <div className="overlay gradient-border"></div>
                                    <div className="actions">
                                        <Link to="/cart" className="icon-btn"><i className="far fa-cart-plus"></i></Link>
                                        <Link to="/wishlist" className="icon-btn"><i className="fas fa-heart"></i></Link>
                                        <a href="#QuickView" className="icon-btn popup-content"><i className="fas fa-eye"></i></a>
                                    </div>
                                </div>
                                <div className="product-content">
                                    <h3 className="product-title"><Link to="/shop-details">Gaming Headphone</Link></h3>
                                    <span className="price">$177.85</span>
                                    {/* <!-- <div className="woocommerce-product-rating">
                        <span className="count">(120 Reviews)</span>
                        <div className="star-rating" role="img" aria-label="Rated 5.00 out of 5">
                            <span>Rated <strong className="rating">5.00</strong> out of 5 based on <span className="rating">1</span> customer rating</span>
                        </div>
                    </div> --> */}
                                </div>
                            </div>
                        </div>


                        <div className="swiper-slide">
                            <div className="th-product product-grid">
                                <div className="product-img">
                                    <img src="assets/img/product/product_1_2.png" alt="Product-Image"/>
                                    <div className="overlay gradient-border"></div>
                                    <div className="actions">
                                        <Link to="/cart" className="icon-btn"><i className="far fa-cart-plus"></i></Link>
                                        <Link to="/wishlist" className="icon-btn"><i className="fas fa-heart"></i></Link>
                                        <a href="#QuickView" className="icon-btn popup-content"><i className="fas fa-eye"></i></a>
                                    </div>
                                </div>
                                <div className="product-content">
                                    <h3 className="product-title"><Link to="/shop-details">Gaming Mouse</Link></h3>
                                    <span className="price">$120.00</span>
                                    {/* <!-- <div className="woocommerce-product-rating">
                        <span className="count">(120 Reviews)</span>
                        <div className="star-rating" role="img" aria-label="Rated 5.00 out of 5">
                            <span>Rated <strong className="rating">5.00</strong> out of 5 based on <span className="rating">1</span> customer rating</span>
                        </div>
                    </div> --> */}
                                </div>
                            </div>
                        </div>


                        <div className="swiper-slide">
                            <div className="th-product product-grid">
                                <div className="product-img">
                                    <img src="assets/img/product/product_1_3.png" alt="Product-Image"/>
                                    <div className="overlay gradient-border"></div>
                                    <div className="actions">
                                        <Link to="/cart" className="icon-btn"><i className="far fa-cart-plus"></i></Link>
                                        <Link to="/wishlist" className="icon-btn"><i className="fas fa-heart"></i></Link>
                                        <a href="#QuickView" className="icon-btn popup-content"><i className="fas fa-eye"></i></a>
                                    </div>
                                </div>
                                <div className="product-content">
                                    <h3 className="product-title"><Link to="/shop-details">Gaming Keyboard</Link></h3>
                                    <span className="price">$96.85</span>
                                    {/* <!-- <div className="woocommerce-product-rating">
                        <span className="count">(120 Reviews)</span>
                        <div className="star-rating" role="img" aria-label="Rated 5.00 out of 5">
                            <span>Rated <strong className="rating">5.00</strong> out of 5 based on <span className="rating">1</span> customer rating</span>
                        </div>
                    </div> --> */}
                                </div>
                            </div>
                        </div>


                        <div className="swiper-slide">
                            <div className="th-product product-grid">
                                <div className="product-img">
                                    <img src="assets/img/product/product_1_4.png" alt="Product-Image"/>
                                    <div className="overlay gradient-border"></div>
                                    <div className="actions">
                                        <Link to="/cart" className="icon-btn"><i className="far fa-cart-plus"></i></Link>
                                        <Link to="/wishlist" className="icon-btn"><i className="fas fa-heart"></i></Link>
                                        <a href="#QuickView" className="icon-btn popup-content"><i className="fas fa-eye"></i></a>
                                    </div>
                                </div>
                                <div className="product-content">
                                    <h3 className="product-title"><Link to="/shop-details">Gaming Chair</Link></h3>
                                    <span className="price">$08.85<del>$06.99</del></span>
                                    {/* <!-- <div className="woocommerce-product-rating">
                        <span className="count">(120 Reviews)</span>
                        <div className="star-rating" role="img" aria-label="Rated 5.00 out of 5">
                            <span>Rated <strong className="rating">5.00</strong> out of 5 based on <span className="rating">1</span> customer rating</span>
                        </div>
                    </div> --> */}
                                </div>
                            </div>
                        </div>


                        <div className="swiper-slide">
                            <div className="th-product product-grid">
                                <div className="product-img">
                                    <img src="assets/img/product/product_1_5.png" alt="Product-Image"/>
                                    <div className="overlay gradient-border"></div>
                                    <div className="actions">
                                        <Link to="/cart" className="icon-btn"><i className="far fa-cart-plus"></i></Link>
                                        <Link to="/wishlist" className="icon-btn"><i className="fas fa-heart"></i></Link>
                                        <a href="#QuickView" className="icon-btn popup-content"><i className="fas fa-eye"></i></a>
                                    </div>
                                </div>
                                <div className="product-content">
                                    <h3 className="product-title"><Link to="/shop-details">Microphone G9000</Link></h3>
                                    <span className="price">$32.85</span>
                                    {/* <!-- <div className="woocommerce-product-rating">
                        <span className="count">(120 Reviews)</span>
                        <div className="star-rating" role="img" aria-label="Rated 5.00 out of 5">
                            <span>Rated <strong className="rating">5.00</strong> out of 5 based on <span className="rating">1</span> customer rating</span>
                        </div>
                    </div> --> */}
                                </div>
                            </div>
                        </div>


                        <div className="swiper-slide">
                            <div className="th-product product-grid">
                                <div className="product-img">
                                    <img src="assets/img/product/product_1_6.png" alt="Product-Image"/>
                                    <div className="overlay gradient-border"></div>
                                    <div className="actions">
                                        <Link to="/cart" className="icon-btn"><i className="far fa-cart-plus"></i></Link>
                                        <Link to="/wishlist" className="icon-btn"><i className="fas fa-heart"></i></Link>
                                        <a href="#QuickView" className="icon-btn popup-content"><i className="fas fa-eye"></i></a>
                                    </div>
                                </div>
                                <div className="product-content">
                                    <h3 className="product-title"><Link to="/shop-details">Play Station Controller</Link></h3>
                                    <span className="price">$30.85</span>
                                    {/* <!-- <div className="woocommerce-product-rating">
                        <span className="count">(120 Reviews)</span>
                        <div className="star-rating" role="img" aria-label="Rated 5.00 out of 5">
                            <span>Rated <strong className="rating">5.00</strong> out of 5 based on <span className="rating">1</span> customer rating</span>
                        </div>
                    </div> --> */}
                                </div>
                            </div>
                        </div>


                        <div className="swiper-slide">
                            <div className="th-product product-grid">
                                <div className="product-img">
                                    <img src="assets/img/product/product_1_7.png" alt="Product-Image"/>
                                    <div className="overlay gradient-border"></div>
                                    <div className="actions">
                                        <Link to="/cart" className="icon-btn"><i className="far fa-cart-plus"></i></Link>
                                        <Link to="/wishlist" className="icon-btn"><i className="fas fa-heart"></i></Link>
                                        <a href="#QuickView" className="icon-btn popup-content"><i className="fas fa-eye"></i></a>
                                    </div>
                                </div>
                                <div className="product-content">
                                    <h3 className="product-title"><Link to="/shop-details">PlayStation VR</Link></h3>
                                    <span className="price">$232.85</span>
                                    {/* <!-- <div className="woocommerce-product-rating">
                        <span className="count">(120 Reviews)</span>
                        <div className="star-rating" role="img" aria-label="Rated 5.00 out of 5">
                            <span>Rated <strong className="rating">5.00</strong> out of 5 based on <span className="rating">1</span> customer rating</span>
                        </div>
                    </div> --> */}
                                </div>
                            </div>
                        </div>


                        <div className="swiper-slide">
                            <div className="th-product product-grid">
                                <div className="product-img">
                                    <img src="assets/img/product/product_1_8.png" alt="Product-Image"/>
                                    <div className="overlay gradient-border"></div>
                                    <div className="actions">
                                        <Link to="/cart" className="icon-btn"><i className="far fa-cart-plus"></i></Link>
                                        <Link to="/wishlist" className="icon-btn"><i className="fas fa-heart"></i></Link>
                                        <a href="#QuickView" className="icon-btn popup-content"><i className="fas fa-eye"></i></a>
                                    </div>
                                </div>
                                <div className="product-content">
                                    <h3 className="product-title"><Link to="/shop-details">Wireless speaker</Link></h3>
                                    <span className="price">$30.85</span>
                                    {/* <!-- <div className="woocommerce-product-rating">
                        <span className="count">(120 Reviews)</span>
                        <div className="star-rating" role="img" aria-label="Rated 5.00 out of 5">
                            <span>Rated <strong className="rating">5.00</strong> out of 5 based on <span className="rating">1</span> customer rating</span>
                        </div>
                    </div> --> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="d-block d-md-none mt-40 text-center">
                    <div className="icon-box">
                        <button data-slider-prev="#productSlider1" className="slider-arrow default"><i className="far fa-arrow-left"></i></button>
                        <button data-slider-next="#productSlider1" className="slider-arrow default"><i className="far fa-arrow-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default ProductDetails
