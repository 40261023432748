import React from 'react';
import ProUserWallet from '../../Components/ProtectedComponents/ProUserWallet';
import ScrollToTop from '../../Components/Common/ScrollToTop';
import Footer2 from '../../Components/Common/Footer2';
import ColorSwitcher from '../../Components/Common/ColorSwitcher';
import SideMenu2 from '../../Components/Common/SideMenu2';
import Preloader from '../../Components/Common/Preloader';
import Cursor from '../../Components/Common/Cursor';
import SimpleHeader3 from '../../Components/Common/SimpleHeader3';
import BottomNavbar from '../../Components/Common/BottomNavbar';

const ProUserWalletPage = () => {
  return (
    <>
      <Cursor/>
      <Preloader/>
      <SimpleHeader3/>
      <SideMenu2/>
      {/* <ColorSwitcher/> */}
      <ProUserWallet/>
      <BottomNavbar />
      <Footer2/>
      <ScrollToTop/>
    </>
  )
}

export default ProUserWalletPage
