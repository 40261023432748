import React from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom'; // For navigation
import './AlertPopup.css';

function AlertPopup({ title, message, type, action, onClose }) {
    const navigate = useNavigate();

    // if (!isOpen) return null;

    const handleAction = () => {
        // You can add logic for the action here. For example:
        // if (action === 'Go to Login') {
        //     navigate('/login');
        // }
        // Trigger the onClose callback if provided
        
        if (onClose) onClose();
        
    };

    return (
        <div className="alert-popup-overlay" onClick={onClose}>
            <div className="alert-content" onClick={(e) => e.stopPropagation()}>
                <h3 className={type == 'success' ? 'titleGreen' : 'titleWarning'}>{title}</h3>
                <p>{message}</p>
                <div className="popup-actions">
                    <button
                        className={` alertBtn tab-btn th-btn style-border3  ${
                            type === 'success' ? 'greenBox' : 'warningBox'
                        }`}
                        onClick={handleAction}
                    >
                        {/* {action}
                        <i className="fa-solid fa-arrow-right ms-3"></i> */}

                        <span className={`alertBorder btn-border ${
                            type === 'success' ? 'greenBox' : 'warningBox'
                        }`}>
                            {action} <i className="fa-solid fa-arrow-right ms-2"></i>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AlertPopup;